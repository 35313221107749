import React from 'react';
import './placement-card.css';

const PlacementCard = ({ company, role, packageDetails, image }) => {
    return (
        <div className="w-[350px] p-5 bg-white flex flex-col items-center gap-5 rounded-3xl placement_card_main shadow-lg">
            <div className="w-[100px] h-[100px]">
                <img src={image} className="w-full h-full rounded-xl object-cover" alt={`${company} logo`} />
            </div>
            <p className="text-lg font-semibold">{company}</p>
            <p className="text-md text-gray-600">{role}</p>
            <p className="text-md font-semibold text-green-600">{packageDetails}</p>
        </div>
    );
};

export default PlacementCard;
