// import React,{useEffect,useState} from 'react'
// import './header.css'
// import Modal from "react-modal";
// import Authentication from '../Authentication/Authentication';
// import '../Authentication/Authentication.css'
// const Header = () =>{

//     const [isScrolled, setIsScrolled] = useState(false);
//     const [modalIsOpen, setModalIsOpen] = useState(false);

//   const openModal = () => setModalIsOpen(true);
//   const closeModal = () => setModalIsOpen(false);
  
//     useEffect(() => {
//         const handleScroll = () => {
//             if (window.scrollY > 0) {
//             setIsScrolled(true);
//             } else {
//             setIsScrolled(false);
//             }
//         };

//         window.addEventListener('scroll', handleScroll);

//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     return(
//         <header className={`header_main ${isScrolled ? 'scrolled' : ''}`}>
//             {/* Logo */}
//             <div className="bg-black h-[70px] w-[70px]"></div>


//             <Modal
//             isOpen={modalIsOpen}
//             onRequestClose={closeModal}
//             contentLabel="Authentication Modal"
//             className="modal"
//             overlayClassName="overlay"
//           >
            
//  <Authentication />
//             </Modal>
            

//             {/* Header right */}
//             <div className="flex justify-between gap-12 items-center">
//                 {/* Header options */}
//                 <div className="flex justify-between gap-8">
//                     <div className="flex items-center gap-1 cursor-pointer">
//                         <p className="text-base header_link">Colleges</p>
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-4 font-bold">
//                             <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
//                         </svg>
//                     </div>
//                     <div className="flex items-center gap-1 cursor-pointer">
//                         <p className="text-base header_link">Exam</p>
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-4 font-bold">
//                             <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
//                         </svg>
//                     </div>
//                     <div className="flex items-center gap-1 cursor-pointer">
//                         <p className="text-base header_link">Courses</p>
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-4 font-bold">
//                             <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
//                         </svg>
//                     </div>
//                     <div className="flex items-center gap-1 cursor-pointer">
//                         <p className="text-base header_link">Careers</p>
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-4 font-bold">
//                             <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
//                         </svg>
//                     </div>
//                     <div className="flex items-center gap-1 cursor-pointer">
//                         <p className="text-base header_link">Latest Updates</p>
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-4 font-bold">
//                             <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
//                         </svg>
//                     </div>
//                     <div className="flex items-center gap-1 cursor-pointer">
//                         <p className="text-base header_link">More</p>
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-4 font-bold">
//                             <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
//                         </svg>
//                     </div>
//                 </div>

//                 {/* Header Profile */}
//                 <div className="flex items-center justify-center  h-[40px] w-[40px] shadow-lg"  onClick={openModal}   style={{borderRadius: '25px',backgroundColor: isScrolled?'#808080':'#fff'}}>
//                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="black" class="w-6 h-10">
//                         <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
//                     </svg>
//                 </div>
//             </div>
//         </header>
//     )
// }

// export default Header;
import React, { useEffect, useState, useRef } from 'react';
import './header.css';
import Modal from 'react-modal';
import { ReactComponent as Logo } from '../../assets/landing/Logo_Green.svg';
import Authentication from '../Authentication/Authentication';
import '../Authentication/Authentication.css';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef();
  const toggleRef = useRef();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const toggleNav = () => setIsNavOpen(prev => !prev);
  const closeNav = () => setIsNavOpen(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    const handleClickOutside = (event) => {
      if (
        navRef.current &&
        !navRef.current.contains(event.target) &&
        toggleRef.current &&
        !toggleRef.current.contains(event.target)
      ) {
        closeNav();
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header
      className={`header_main ${isScrolled ? 'scrolled' : ''}`}
      style={{
        backgroundColor: isScrolled ? 'white' : 'transparent',
        color: isScrolled ? '#000' : '#fff',
        boxShadow: isScrolled ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
      }}
    >
      <div className="container mx-auto flex items-center justify-between p-4">
        {/* Logo */}
        <div>
          <Logo className="w-24 h-24 md:w-32 md:h-32 lg:w-36 lg:h-36" />
        </div>

        {/* Toggle Button for Mobile Navigation */}
        <button
          ref={toggleRef}
          className={`md:hidden p-2 rounded-md focus:outline-none ${isScrolled ? 'text-customgreen bg-white' : 'text-white bg-customgreen'}`}
          onClick={toggleNav}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>

        {/* Modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Authentication Modal"
          className="modal"
          overlayClassName="overlay"
        >
          <Authentication />
        </Modal>

        {/* Desktop Navigation and Profile */}
        <div className="hidden md:flex items-center flex-wrap gap-6 md:gap-8">
          <div className="flex flex-wrap gap-4 md:gap-8 items-center">
            {['Colleges', 'Exam', 'Courses', 'Careers', 'Latest Updates', 'More'].map((item, index) => (
              <div
                key={index}
                className={`flex items-center gap-1 cursor-pointer text-base header_link ${isScrolled ? 'text-black' : 'text-white'}`}
              >
                <p>{item}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke={isScrolled ? '#4caf50' : 'currentColor'}
                  className="w-4 h-4 font-bold"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
              </div>
            ))}
          </div>

          {/* Profile Icon */}
          <div
            className="profile-icon flex items-center justify-center shadow-lg cursor-pointer rounded-full"
            style={{
              width: '48px',
              height: '48px',
              backgroundColor: isScrolled ? 'white' : 'rgb(58, 202, 46)',
              
            }}
            onClick={openModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke={isScrolled ? 'black' : 'white'}
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
            </svg>
          </div>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      <div
        ref={navRef}
        className={`fixed top-0 right-0 h-screen w-64 bg-white z-50 transform ${
          isNavOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out shadow-lg`}
      >
        <div className="flex flex-col h-full p-4">
          <button className="self-end text-gray-500 p-2" onClick={closeNav}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="flex flex-col mt-4">
            {['Colleges', 'Exam', 'Courses', 'Careers', 'Latest Updates', 'More'].map((item, index) => (
              <div
                key={index}
                className="flex items-center gap-2 p-4 border-b border-gray-200 text-black cursor-pointer"
                onClick={closeNav}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="green"
                  className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
                <p>{item}</p>
              </div>
            ))}
            <div
              className="flex items-center justify-center mt-4 shadow-lg cursor-pointer rounded-full"
              style={{ width: '48px', height: '48px', backgroundColor: 'white' }}
              onClick={openModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="black"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
