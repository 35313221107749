// import React from 'react'

// const CollegeBy = ()=>{
//     return(
//         <div className="bg-[#F2FCEB] py-8 px-5 flex items-center justify-center">
//             {/* Left Section */}
//             <div className="px-5">
//                 <p className="text-[#585858] font-medium text-xl mb-5">Lorem ipsum </p>
//                 <p className="text-3xl font-semibold mb-10">Lorem ipsum odor amet, consectetuer adipiscing </p>
//                 <div className="flex gap-5 mb-10">
//                     <div className="h-[50px] w-[50px] rounded-[25px] bg-white flex items-center justify-center">
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3ACA2E" className="h-[24px] ml-1">
//                             <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
//                         </svg>
//                     </div>
//                     <p className="text-lg text-[#404040] font-medium py-3">Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus neque felis a tristique velit metus.</p>
//                 </div>
//                 <div className="flex gap-5 mb-8">
//                     <div className="h-[50px] w-[50px] rounded-[25px] bg-white flex items-center justify-center">
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3ACA2E" className="h-[24px] ml-1">
//                             <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
//                         </svg>
//                     </div>
//                     <p className="text-lg text-[#404040] font-medium py-3">Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus neque felis a tristique velit metus.</p>
//                 </div>
//                 <button className="text-white font-medium px-5 py-1 rounded-2xl bg-[#3ACA2E]">More on Admissions</button>
//             </div>

//             {/* Right Section */}
//             <div className="flex items-center gap-5 px-5">
//                 <div className="w-[250px] rounded-[20px] overflow-hidden">
//                     <div className="w-[250px] h-[150px] bg-gray-200"></div>
//                     <div className="w-full p-3 bg-white h-[80px] flex flex-col justify-between">
//                         <p className="text-lg font-semibold">College by Degree</p>
//                         <button className="flex items-center gap-1 text-[#3ACA2E]">
//                             <p className="text-sm font-semibold">View</p>
//                             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" className="h-[9px]">
//                                 <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
//                             </svg>
//                         </button>
//                     </div>
//                 </div>

//                 <div className="flex flex-col gap-20">
//                     <div className="w-[250px] rounded-[20px] overflow-hidden">
//                         <div className="w-[250px] h-[150px] bg-gray-200"></div>
//                         <div className="w-full p-3 bg-white h-[80px] flex flex-col justify-between">
//                             <p className="text-lg font-semibold">College by Degree</p>
//                             <button className="flex items-center gap-1 text-[#3ACA2E]">
//                                 <p className="text-sm font-semibold">View</p>
//                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" className="h-[9px]">
//                                     <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
//                                 </svg>
//                             </button>
//                         </div>
//                     </div>
//                     <div className="w-[250px] rounded-[20px] overflow-hidden">
//                         <div className="w-[250px] h-[150px] bg-gray-200"></div>
//                         <div className="w-full p-3 bg-white h-[80px] flex flex-col justify-between">
//                             <p className="text-lg font-semibold">College by Degree</p>
//                             <button className="flex items-center gap-1 text-[#3ACA2E]">
//                                 <p className="text-sm font-semibold">View</p>
//                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" className="h-[9px]">
//                                     <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
//                                 </svg>
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default CollegeBy;

import React from 'react';
import Tula from '../../../assets/landing/Tula.jpg';
import GE from '../../../assets/landing/GE.png';
import CU from '../../../assets/landing/CU.jpg';


const CollegeBy = () => {
    return (
        <div className="bg-[#F2FCEB] py-8 px-5 grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10">
            {/* Left Section */}
            <div className="flex flex-col items-start justify-between">
                <p className="text-[#585858] font-medium text-xl mb-5">Discover Top Colleges</p>
                <p className="text-2xl md:text-3xl font-semibold mb-6 md:mb-10">Explore the best colleges and universities tailored to your interests and goals.</p>
                <div className="flex flex-row gap-5 mb-6 md:mb-10">
                    <div className="h-[50px] w-[50px] rounded-full bg-white flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3ACA2E" className="h-[24px]">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                        </svg>
                    </div>
                    <p className="text-base md:text-lg text-[#404040] font-medium py-3">Find colleges that fit your career aspirations and academic interests with ease.</p>
                </div>
                <div className="flex flex-row gap-5 mb-6 md:mb-8">
                    <div className="h-[50px] w-[50px] rounded-full bg-white flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3ACA2E" className="h-[24px]">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                        </svg>
                    </div>
                    <p className="text-base md:text-lg text-[#404040] font-medium py-3">Get expert insights and tailored recommendations to make your college search more effective.</p>
                </div>
                <button className="text-white font-medium px-5 py-2 rounded-[20px] bg-[#3ACA2E] w-full md:w-[240px] mx-auto md:mx-0">
                    More on Admission
                </button>
            </div>
            {/* Right section */}
            {/* <div className="flex flex-col justify-center h-full md:py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10">
                    <div className="md:w-full md:h-full w-[180px]   flex flex-col justify-center rounded-[20px] overflow-hidden ">
                        <div className="w-full h-[150px] bg-gray-200 flex items-center justify-center rounded-t-[20px]">
                            <img src={Tula} alt="Colleges by Major" className="object-cover w-full h-full rounded-t-[20px]" />
                        </div>
                        <div className="w-full p-3 bg-white h-[80px] flex flex-col justify rounded-b-[20px]">
                            <p className="text-base md:text-lg font-semibold">Colleges by Major</p>
                            <button className="flex items-center gap-1 text-[#3ACA2E] text-sm md:text-base mt-2">
                                <p className="font-semibold">View</p>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" className="h-[9px]">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-6 md:gap-10">
                        <div className=" rounded-[20px] overflow-hidden">
                            <div className=" bg-gray-200 flex items-center justify-center">
                                <img src={GE} alt="Colleges by Location" className="object-cover w-full h-full" />
                            </div>
                            <div className="w-full p-3 bg-white h-[80px] flex flex-col justify-between">
                                <p className="text-base md:text-lg font-semibold">Colleges by Location</p>
                                <button className="flex items-center gap-1 text-[#3ACA2E] text-sm md:text-base">
                                    <p className="font-semibold">View</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" className="h-[9px]">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="md:w-full md:h-full w-[180px] rounded-[20px] overflow-hidden">
                            <div className="w-full  bg-gray-200 flex items-center justify-center">
                                <img src={CU} alt="Colleges by Ranking" className="object-cover w-full h-full" />
                            </div>
                            <div className="w-full p-3 bg-white h-[80px] flex flex-col justify-between">
                                <p className="text-base md:text-lg font-semibold">Colleges by Ranking</p>
                                <button className="flex items-center gap-1 text-[#3ACA2E] text-sm md:text-base">
                                    <p className="font-semibold">View</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" className="h-[9px]">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        <div className="flex flex-col justify-center h-full md:py-12 mt-5 mx-2">
  <div className="grid grid-cols-2 gap-6 md:grid-cols-2 md:gap-10 md:items-center">
    {/* College by Location */}
    <div className="md:order-2 order-1 md:w-full md:h-full w-[150px] h-[180px]  flex flex-col justify-center rounded-[20px] overflow-hidden">
      <div className="w-full h-[150px] bg-gray-200 flex items-center justify-center rounded-t-[20px]">
        <img src={GE} alt="Colleges by Location" className="object-cover w-full h-full rounded-t-[20px]" />
      </div>
      <div className="w-full p-3 bg-white h-[80px] flex flex-col justify-between">
        <p className=" md:text-lg text-[12px] font-[600]">Colleges by Location</p>
        <button className="flex items-center gap-1 text-[#3ACA2E] text-sm md:text-base">
          <p className="font-semibold">View</p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" className="h-[9px]">
            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
          </svg>
        </button>
      </div>
    </div>

    {/* College by Ranking */}
    <div className="md:order-3 mx-2 order-2 md:w-full md:h-full w-[150px] h-[180px] flex flex-col justify-center rounded-[20px] overflow-hidden">
      <div className="w-full h-[150px] bg-gray-200 flex items-center justify-center rounded-t-[20px]">
        <img src={CU} alt="Colleges by Ranking" className="object-cover w-full h-full rounded-t-[20px]" />
      </div>
      <div className="w-full p-3 bg-white h-[80px] flex flex-col justify-between">
        <p className=" md:text-lg text-[12px] font-[600]">Colleges by Ranking</p>
        <button className="flex items-center gap-1 text-[#3ACA2E] text-sm md:text-base">
          <p className="font-semibold">View</p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" className="h-[9px]">
            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
          </svg>
        </button>
      </div>
    </div>

    {/* College by Major - Centered in mobile with custom width/height */}
    <div className="md:order-1  mt-5 order-3 col-span-2 md:col-span-1 md:row-span-2  md:w-auto md:h-auto w-[150px] h-[180px] flex flex-col justify-center rounded-[20px] overflow-hidden mx-auto md:mx-0">
      <div className="w-full h-[150px] bg-gray-200 flex items-center justify-center rounded-t-[20px]">
        <img src={Tula} alt="Colleges by Major" className="object-cover w-full h-full rounded-t-[20px]" />
      </div>
      <div className="w-full p-3 bg-white h-[80px] flex flex-col justify-center rounded-b-[20px]">
        <p className=" md:text-lg text-[12px] font-[600]">Colleges by Major</p>
        <button className="flex items-center gap-1 text-[#3ACA2E] text-sm md:text-base mt-2">
          <p className="font-semibold">View</p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" className="h-[9px]">
            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>

        </div>
    );
}

export default CollegeBy;
