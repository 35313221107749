import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { Base_URL } from '../../../apiConfig';

const EditAdminFaq = () => {
  const [formData, setFormData] = useState({
    question: '',
    answer: ''
  });
  const [error, setError] = useState(null);
  const { id } = useParams(); // Get the FAQ ID from the route parameters
  const navigate = useNavigate();
  const location = useLocation();
  const faq = location.state?.faq; // Get FAQ data passed from AllFaq
  const { mainTitle, subTitle } = location.state || {};

  // If data is passed from AllFaq, use it; otherwise, fetch from API
  useEffect(() => {
    if (faq) {
      setFormData(faq);
    } else {
      const fetchFAQ = async () => {
        try {
          const response = await axios.get(`${Base_URL}/faq/${id}`);
          setFormData(response.data);
        } catch (error) {
          console.error('Failed to fetch FAQ:', error.response?.data || error.message);
          setError('Failed to fetch FAQ. Please try again.');
        }
      };
      fetchFAQ();
    }
  }, [id, faq]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken'); // Assuming you use tokens for authentication

      await axios.put(`${Base_URL}/faq/${id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
      });
      alert('FAQ updated successfully!');
      navigate('/dashboard/faqs/all'); // Navigate back to FAQs list
      setError(null);
    } catch (error) {
      console.error('Failed to update FAQ:', error.response?.data || error.message);
      setError('Failed to update FAQ. Please try again.');
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
       <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        <h2 className="text-gray-800 text-2xl font-semibold mb-4">Edit FAQ</h2>
        {error && <div className="text-red-600 mb-4">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mb-4">
            <label htmlFor="question" className="text-gray-700 mb-2 font-semibold">Question</label>
            <input
              type="text"
              id="question"
              name="question"
              value={formData.question}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded"
              required
            />
          </div>
          <div className="flex flex-col mb-4">
            <label htmlFor="answer" className="text-gray-700 mb-2 font-semibold">Answer</label>
            <input
              type="text"
              id="answer"
              name="answer"
              value={formData.answer}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded"
              required
            />
          </div>
          <button type="submit" className="px-4 py-2 bg-customblue text-white rounded">
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditAdminFaq;
