import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderTitle from '../../dashboard/HeaderTitle';

const AddAdmissions = () => {
  const [formData, setFormData] = useState({
    university_id: null,
    admission_desc: '',
  });

  const [universities, setUniversities] = useState([]);
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  useEffect(() => {
    // Fetch universities from the API to populate the react-select dropdown
    const fetchUniversities = async () => {
      try {
        const response = await axios.get('http://213.210.37.39:3000/api/universities'); // Replace with your API endpoint
        const universityOptions = response.data.map(university => ({
          value: university.university_id,
          label: university.university_name
        }));
        setUniversities(universityOptions);
      } catch (error) {
        console.error('Error fetching universities:', error);
        toast.error('Failed to load universities');
      }
    };

    fetchUniversities();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setFormData(prev => ({ ...prev, university_id: selectedOption ? selectedOption.value : null }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.university_id || !formData.admission_desc) {
      toast.error('Please fill all the required fields');
      return;
    }

    try {
      // Send formData as JSON
      await axios.post('http://213.210.37.39:3000/api/create/admissions', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      toast.success('Admission information added successfully!');
      // Reset form
      setFormData({
        university_id: null,
        admission_desc: '',
      });
    } catch (error) {
      console.error('Failed to add admission information:', error.response?.data || error.message);
      toast.error('Failed to add admission information. Please try again.');
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md">
        <h2 className="text-xl font-semibold mb-4">Add Admission Information</h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Select University Dropdown using react-select */}
            <div className="flex flex-col">
              <label htmlFor="university_id" className="mb-2 font-medium text-sm">Select University</label>
              <Select
                options={universities}
                value={universities.find(option => option.value === formData.university_id)}
                onChange={handleSelectChange}
                placeholder="Select a university"
                className="basic-multi-select"
                classNamePrefix="select"
                isClearable
              />
            </div>

            {/* CKEditor for Admission Description */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="admission_description" className="mb-2 font-medium text-sm">Admission Description</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.admission_desc}
                onChange={(event, editor) => setFormData(prev => ({ ...prev, admission_desc: editor.getData() }))}
              />
            </div>
          </div>

          <button type="submit" className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md">
            Submit Admission
          </button>
        </form>

        {/* Toast Container */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddAdmissions;
