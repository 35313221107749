import React from 'react';

const EventCard = ({ title, subtitle, location, date, month }) => {
    return (
        <div className="flex bg-white border-b-2 border-gray-200">
            <div className="flex-shrink-0 flex flex-col items-center justify-start p-4">
                <div className="w-12 h-12 md:w-[50px] md:h-[38px] bg-[#3ACA2E] rounded-full md:rounded-[20px] flex items-center justify-center">
                    <p className="text-white text-[14px] font-bold">{month}</p>
                </div>
                <p className="text-[18px] font-bold mt-2">{date}</p>
            </div>
            <div className="flex-1 p-4">
                <p className="text-black text-base font-bold mb-1 leading-[18px] ">{title}</p>
                <p className="text-base mb-2 leading-[15px] mt-10 ">{subtitle}</p>
                <div className="flex items-center gap-2 mt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3ACA2E" className="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                    <p className="text-[14px] text-gray-700 ">{location}</p>
                </div>
            </div>
        </div>
    );
};

export default EventCard;
