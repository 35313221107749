// import React, { useState, useEffect } from 'react';
// import college_logo from '../../../assets/college_logo.png'; // Import default logo
// import './landing-news-events.css';
// import EventCard from '../../College/NewsEvents/EventCard/EventCard';
// import NewsCard from '../../College/NewsEvents/NewsCard/NewsCard';
// import { Base_URL } from '../../../apiConfig'; // Assume you have this file for API base URL

// const LandingNewsEvents = () => {
//   // State for announcements, featured news, upcoming events, loading status, and error state
//   const [featuredNews, setFeaturedNews] = useState(null);
//   const [recentNews, setRecentNews] = useState([]); // Separate state for recent news
//   const [upcomingEvents, setUpcomingEvents] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   // Fetch data from the API on component mount
//   useEffect(() => {
//     // Fetch news and announcements
//     fetch(`${Base_URL}/news-announcements`)
//       .then((response) => response.json())
//       .then((data) => {
//         console.log(data);
//         if (data.length > 0) {
//           setFeaturedNews(data[0]); // Set the top news item as the featured news
//           setRecentNews(data.slice(1, 3)); // Set the top 2 recent news items
//         }
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error fetching news data:", error);
//         setError("Failed to load news and announcements.");
//         setLoading(false);
//       });

//     // Fetch upcoming events
//     fetch(`${Base_URL}/event/organizer/1`) // Replace '1' with the actual organizer ID if needed
//       .then((response) => response.json())
//       .then((data) => {
//         console.log(data);
//         setUpcomingEvents(data.slice(0, 3)); // Limit to top 3 events
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error fetching events data:", error);
//         setError("Failed to load upcoming events.");
//         setLoading(false);
//       });
//   }, []);

//   return (
//     <div className="py-8 px-5 bg-white">
//       <div className="text-black text-base font-medium flex flex-col gap-5">
//         <p className="text-center text-lg text-[#808080] font-semibold mb-2">
//           NEWS AND EVENTS
//         </p>

//         <p className="text-xl text-center mb-8">
//           Lorem ipsum odor amet, consectetuer adipiscing elit. Laoreet volutpat
//           ullamcorper donec duis quis, imperdiet tempus justo.
//         </p>
//       </div>

//       {/* Featured News */}
//       <div className="py-10">
//         <div className="flex items-center">
//           <div className="bg-gray-500 flex-shrink-0 h-[300px] w-[300px] rounded-xl">
//             <img
//               src={featuredNews ? featuredNews.picture || college_logo : college_logo}
//               alt="news-image"
//               className="w-full h-full"
//             />
//           </div>
//           <div className="transform -translate-x-5 bg-white rounded-xl p-5 border-2">
//             <div className="flex mb-2">
//               <p className="text-xs text-white bg-[#3ACA2E] py-1 px-3 rounded-xl">
//                 FEATURED NEWS
//               </p>
//             </div>
//             <div>
//               <p className="text-black text-lg font-semibold">
//                 {featuredNews ? featuredNews.event_name : 'Lorem ipsum odor amet, consectetuer adipiscing elit'}
//               </p>
//               <p className="text-xs font-medium mb-3">
//                 {featuredNews
//                   ? featuredNews.event_date
//                   : '23 October 2023'}
//               </p>
//               <p className="text-base news_display_content">
//                 {featuredNews
//                   ? featuredNews.event_details
//                   : `Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus neque
//                     felis a tristique velit metus. aliquam habitant vivamus tempor
//                     vehicula taciti in urna hac. Nascetur class platea primis
//                     lobortis odio mauris dolor Etiam molestie tempus lacus faucibus,
//                     adipiscing velit rutrum. Nec sed ante vulputate eleifend arcu
//                     nostra habitasse ipsum integer. Fermentum integer aenean integer
//                     porttitor mi phasellus. Platea platea ultricies per nam
//                     adipiscing, tellus posuere.`}
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="py-10 px-5 flex items-start">
//         {/* Recent News */}
//         <div className="">
//           <p className="text-black text-base font-semibold mb-5">RECENT NEWS</p>
//           <div className="flex flex-col gap-5 px-5">
//             {(recentNews.length > 0 && !error) ? (
//               recentNews.map((news, index) => (
//                 <NewsCard
//                   key={index}
//                   title={news.title}
//                   desc={news.content}
//                   date={new Date(news.announcement_date).toLocaleDateString()}
//                   image={news.picture || college_logo}
//                 />
//               ))
//             ) : (
//               <>
//                 {/* Default data if no news or error */}
//                 <NewsCard
//                   title={"Lorem ipsum odor amet, consectetuer adipiscing elit "}
//                   desc={
//                     "Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus neque felis a tristique velit metus. aliquam habitant vivamus tempor vehicula taciti in urna hac. Nascetur class platea primis lobortis odio mauris dolor Etiam molestie tempus lacus faucibus"
//                   }
//                   date={"October 23, 2024"}
//                   image={college_logo}
//                 />
//                 <NewsCard
//                   title={"Lorem ipsum odor amet, consectetuer adipiscing elit "}
//                   desc={
//                     "Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus neque felis a tristique velit metus. aliquam habitant vivamus tempor vehicula taciti in urna hac. Nascetur class platea primis lobortis odio mauris dolor Etiam molestie tempus lacus faucibus"
//                   }
//                   date={"October 23, 2024"}
//                   image={college_logo}
//                 />
//               </>
//             )}
//           </div>
//         </div>

//         {/* Upcoming Events */}
//         <div className="w-[500px] flex-shrink-0 px-5">
//           <p className="text-black text-base mb-6 font-semibold">UPCOMING EVENTS</p>
//           <div className="bg-white flex flex-col py-5 rounded-xl border-2">
//             {upcomingEvents.length > 0 ? (
//               upcomingEvents.map((event, index) => (
//                 <EventCard
//                   key={index}
//                   title={event.event_name}
//                   subtitle={event.event_details}
//                   location={event.event_location}
//                   date={new Date(event.event_date).getDate()}
//                   month={new Date(event.event_date).toLocaleString('default', { month: 'short' }).toUpperCase()}
//                 />
//               ))
//             ) : (
//               <>
//                 {/* Default data if no events or error */}
//                 <EventCard
//                   title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
//                   subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
//                   location={"Lorem ipsum odor amet"}
//                   date={30}
//                   month={"AUG"}
//                 />
//                 <EventCard
//                   title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
//                   subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
//                   location={"Lorem ipsum odor amet"}
//                   date={30}
//                   month={"AUG"}
//                 />
//                 <EventCard
//                   title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
//                   subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
//                   location={"Lorem ipsum odor amet"}
//                   date={30}
//                   month={"AUG"}
//                 />
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LandingNewsEvents;
import React, { useState, useEffect } from 'react';
import college_logo from '../../../assets/college_logo.png'; // Import default logo
import Graphic from '../../../assets/landing/Graphic.png';
import Arrow from '../../../assets/landing/Arrow.png';
import './landing-news-events.css';
import EventCard from '../../College/NewsEvents/EventCard/EventCard';
import NewsCard from '../../College/NewsEvents/NewsCard/NewsCard';
import { Base_URL } from '../../../apiConfig'; // Assume you have this file for API base URL

const LandingNewsEvents = () => {
  // State for announcements, featured news, upcoming events, loading status, and error state
  const [featuredNews, setFeaturedNews] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Dummy data for featured news
  const dummyFeaturedNews = {
    title: 'Big Breakthrough in AI Technology',
    content:
      'Researchers at the university have made a significant breakthrough in artificial intelligence that is set to revolutionize the industry.',
    picture: Graphic,
    announcement_date: '2024-10-01',
  };

  // Dummy data for recent news
  const recentNews = [
    {
      title: "Lorem ipsum odor amet, consectetuer adipiscing elit",
      content: "Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus neque felis a tristique velit metus. aliquam habitant vivamus tempor vehicula taciti in urna hac. Nascetur class platea primis lobortis odio mauris dolor Etiam molestie tempus lacus faucibus",
      announcement_date: "2024-10-23",
      picture: Graphic
    },
    {
      title: "Lorem ipsum odor amet, consectetuer adipiscing elit",
      content: "Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus neque felis a tristique velit metus. aliquam habitant vivamus tempor vehicula taciti in urna hac. Nascetur class platea primis lobortis odio mauris dolor Etiam molestie tempus lacus faucibus",
      announcement_date: "2024-10-23",
      picture: Graphic
    }
  ];

  // Dummy data for upcoming events
  const dummyUpcomingEvents = [
    {
      event_name: 'Annual Tech Conference',
      event_details: 'Join us for a day of technology and innovation.',
      event_location: 'Main Auditorium',
      event_date: '2024-09-15',
    },
    {
      event_name: 'Career Fair',
      event_details: 'Meet potential employers and explore career opportunities.',
      event_location: 'Career Center',
      event_date: '2024-10-05',
    },
    {
      event_name: 'Workshop on AI',
      event_details: 'Learn about the latest advancements in Artificial Intelligence.',
      event_location: 'Room 101',
      event_date: '2024-11-10',
    }
  ];

  // Fetch data from the API on component mount
  // useEffect(() => {
  //   // Fetch news and announcements
  //   fetch(`${Base_URL}/news-announcements`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       if (data.length > 0) {
  //         setFeaturedNews(data[0]); // Set the top news item as the featured news
  //         setRecentNews(data.slice(1, 3)); // Set the top 2 recent news items
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching news data:", error);
  //       setError("Failed to load news and announcements.");
  //       setLoading(false);
  //     });

  //   // Set dummy data for upcoming events
  //   setUpcomingEvents(dummyUpcomingEvents);
  //   setLoading(false);

    // Uncomment and use this if you want to fetch upcoming events from API
    /*
    fetch(`${Base_URL}/event/organizer/1`) // Replace '1' with the actual organizer ID if needed
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUpcomingEvents(data.slice(0, 3)); // Limit to top 3 events
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching events data:", error);
        setError("Failed to load upcoming events.");
        setLoading(false);
      });
    */
  // }, []);

  return (
    <div className="py-8 px-4 md:px-6 lg:px-8 bg-white">
      <div className="text-black text-base font-medium flex flex-col gap-5">
        <p className="text-center md:text-lg text-[16px] text-[#808080] font-[500] mb-2">
          NEWS AND EVENTS
        </p>

        <p className="md:text-xl text-[18px] font-[500] text-center mb-8">
          Hot Off the Press: Dive into the Latest News and Exciting Campus Events!
        </p>
      </div>

      {/* Featured News */}
      <div className="py-10">
  <div className="flex flex-col md:flex-row items-center relative">
    <div className="bg-gray-500 flex-shrink-0 h-72 w-full max-w-xs rounded-xl overflow-hidden">
      <img
        src={dummyFeaturedNews.picture || college_logo}
        alt="news-image"
        className="w-full h-full object-cover"
      />
    </div>
    <div className="text-card-container bg-white rounded-xl p-5 border-2 relative -translate-y-10 md:-translate-x-10 mt-auto z-10 md:z-50">
      <img
        src={Arrow}
        alt="Arrow Icon"
        className="arrow-icon absolute right-0 top-0 mt-2 mr-2"
      />
     <div className="badge-container flex mb-2 justify-center md:justify-start">
  <p className="text-xs text-white bg-[#3ACA2E] py-1 px-3 rounded-xl">
    FEATURED NEWS
  </p>
</div>

      <div>
        <p className="text-black text-lg font-semibold mt-5">
          {dummyFeaturedNews.title}
        </p>
        <p className="text-xs font-medium mt-2 mb-3">
          {new Date(dummyFeaturedNews.announcement_date).toLocaleDateString()}
        </p>
        <p className="text-base mt-2 news_display_content">
          {dummyFeaturedNews.content}
        </p>
      </div>
    </div>
  </div>
</div>


<div className="py-10 px-4 md:px-6 flex flex-col gap-8 md:flex-row md:gap-8">
  {/* Recent News */}
  <div className="flex-1 md:w-[30%] w-full mx-auto"> {/* Equal width on large screens */}
  <p className="text-black text-base font-bold mb-6 text-center md:text-left leading-[26px] md:leading-normal hidden md:block">
    Recent News
  </p>

  <div className="flex flex-col gap-5 justify-center items-center md:items-start">
    {recentNews.length > 0 ? (
      recentNews.map((news, index) => (
        <NewsCard
          key={index}
          title={news.title}
          desc={news.content}
          date={new Date(news.announcement_date).toLocaleDateString()}
          image={news.picture || college_logo}
          className="w-full h-auto" // Full width for larger screens
        />
      ))
    ) : (
      <>
        {/* Default data if no news or error */}
        <NewsCard
          title={"Lorem ipsum odor amet, consectetuer adipiscing elit "}
          desc={
            "Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus neque felis a tristique velit metus. aliquam habitant vivamus tempor vehicula taciti in urna hac. Nascetur class platea primis lobortis odio mauris dolor Etiam molestie tempus lacus faucibus"
          }
          date={"October 23, 2024"}
          image={Graphic}
          className="w-full h-auto"
        />
        <NewsCard
          title={"Lorem ipsum odor amet, consectetuer adipiscing elit "}
          desc={
            "Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus neque felis a tristique velit metus. aliquam habitant vivamus tempor vehicula taciti in urna hac. Nascetur class platea primis lobortis odio mauris dolor Etiam molestie tempus lacus faucibus"
          }
          date={"October 23, 2024"}
          image={Graphic}
          className="w-full h-auto"
        />
      </>
    )}
  </div>
</div>


  {/* Upcoming Events */}
  <div className="flex-1 md:w-[50%] w-full mx-auto h-auto md:h-[577px] mt-10 md:mt-0"> {/* Equal width on large screens */}
    <p className="text-black text-base font-bold mb-6 text-center md:text-left leading-[26px] md:leading-normal">
      Upcoming Events
    </p>

    <div className="bg-white flex flex-col py-5 rounded-xl border-2 border-gray-200">
      {upcomingEvents.length > 0 ? (
        upcomingEvents.map((event, index) => (
          <EventCard
            key={index}
            title={event.event_name}
            subtitle={event.event_details}
            location={event.event_location}
            date={new Date(event.event_date).getDate()}
            month={new Date(event.event_date).toLocaleString('default', { month: 'short' }).toUpperCase()}
          />
        ))
      ) : (
        <>
          {/* Default data if no events or error */}
          <EventCard
            title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
            subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
            location={"Lorem ipsum odor amet"}
            date={30}
            month={"AUG"}
          />
          <EventCard
            title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
            subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
            location={"Lorem ipsum odor amet"}
            date={30}
            month={"AUG"}
          />
          <EventCard
            title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
            subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
            location={"Lorem ipsum odor amet"}
            date={30}
            month={"AUG"}
          />
        </>
      )}
    </div>
  </div>
</div>
</div>
  );
};

export default LandingNewsEvents;
