import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LuPlus } from "react-icons/lu";
import 'react-toastify/dist/ReactToastify.css';

const AddPrograms = () => {
  const navigate = useNavigate();
  const [universities, setUniversities] = useState([]);
  const [formData, setFormData] = useState({
    university_id: null,
    program_name: '',
    program_fees: '',
    program_duration: '',
    study_mode: 'Full-time',
    exams_accepted: [],
    eligibility: '',
    program_details: '',
    course_offered: [],
  });
  const [newCourse, setNewCourse] = useState('');
  const [newExam, setNewExam] = useState('');
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  useEffect(() => {
    // Fetch list of universities
    const fetchUniversities = async () => {
      try {
        const response = await axios.get(`${Base_URL}/universities`);
        const universityOptions = response.data.map((university) => ({
          value: university.university_id,
          label: university.university_name,
        }));
        setUniversities(universityOptions);
      } catch (error) {
        toast.error('Failed to fetch universities');
        console.error('Failed to fetch universities:', error);
      }
    };

    fetchUniversities();
  }, []);

  const handleProgramChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUniversityChange = (selectedOption) => {
    setFormData({ ...formData, university_id: selectedOption.value });
  };

  const handleStudyModeChange = (selectedOption) => {
    setFormData({ ...formData, study_mode: selectedOption.value });
  };

  const handleExamAdd = (e) => {
    e.preventDefault();
    if (newExam && !formData.exams_accepted.includes(newExam)) {
      setFormData(prev => ({ ...prev, exams_accepted: [...prev.exams_accepted, newExam] }));
      setNewExam('');
    }
  };

  const handleCourseAdd = (e) => {
    e.preventDefault();
    if (newCourse && !formData.course_offered.includes(newCourse)) {
      setFormData(prev => ({ ...prev, course_offered: [...prev.course_offered, newCourse] }));
      setNewCourse('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newProgram = { ...formData };
      await axios.post(`${Base_URL}/programs`, newProgram, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success('Program added successfully!');
    
    } catch (error) {
      toast.error('Failed to add program.');
      console.error('Failed to add program:', error.response?.data || error.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />

        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col mb-4">
              <label className="mb-2 font-medium text-sm">Select University</label>
              <Select
                options={universities}
                value={universities.find(uni => uni.value === formData.university_id)}
                onChange={handleUniversityChange}
                placeholder="Select University"
                className="basic-single"
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="program_name" className="mb-2 font-medium text-sm">Program Name</label>
                <input
                  type="text"
                  id="program_name"
                  name="program_name"
                  value={formData.program_name}
                  onChange={handleProgramChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="study_mode" className="mb-2 font-medium text-sm">Study Mode</label>
                <Select
                  options={[
                    { value: 'Full-time', label: 'Full-time' },
                    { value: 'Part-time', label: 'Part-time' },
                  ]}
                  value={{ label: formData.study_mode, value: formData.study_mode }}
                  onChange={handleStudyModeChange}
                  placeholder="Select Study Mode"
                  className="basic-single"
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="program_duration" className="mb-2 font-medium text-sm">Program Duration</label>
                <input
                  type="text"
                  id="program_duration"
                  name="program_duration"
                  value={formData.program_duration}
                  onChange={handleProgramChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="program_fees" className="mb-2 font-medium text-sm">Program Fees</label>
                <input
                  type="text"
                  id="program_fees"
                  name="program_fees"
                  value={formData.program_fees}
                  onChange={handleProgramChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col md:col-span-2">
                <label htmlFor="exams_accepted" className="mb-2 font-medium text-sm">Exams Accepted</label>
                <div className="flex items-center">
                  <input
                    type="text"
                    id="new_exam"
                    value={newExam}
                    onChange={(e) => setNewExam(e.target.value)}
                    className="border w-full border-gray-300 p-2 rounded mr-2"
                    placeholder="Enter exam"
                  />
                  <button onClick={handleExamAdd} className="p-3 bg-blue-600 text-white rounded-md">
                    <LuPlus />
                  </button>
                </div>
                <div className="mt-2">
                  {formData.exams_accepted.map((exam, index) => (
                    <div key={index} className="inline-flex items-center mr-2 mb-2 bg-gray-200 p-2 rounded">
                      {exam}
                      <button
                        type="button"
                        className="ml-2 text-red-600"
                        onClick={() => setFormData(prev => ({
                          ...prev,
                          exams_accepted: prev.exams_accepted.filter(e => e !== exam),
                        }))}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col md:col-span-2">
                <label htmlFor="program_details" className="mb-2 font-medium text-sm">Program Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.program_details}
                  onChange={(event, editor) => setFormData({ ...formData, program_details: editor.getData() })}
                />
              </div>

              <div className="flex flex-col md:col-span-2">
                <label htmlFor="courses_offered" className="mb-2 font-medium text-sm">Courses Offered</label>
                <div className="flex items-center">
                  <input
                    type="text"
                    id="new_course"
                    value={newCourse}
                    onChange={(e) => setNewCourse(e.target.value)}
                    className="border w-full border-gray-300 p-2 rounded mr-2"
                    placeholder="Enter course"
                  />
                  <button onClick={handleCourseAdd} className="p-3 bg-blue-600 text-white rounded-md">
                    <LuPlus />
                  </button>
                </div>
                <div className="mt-2">
                  {formData.course_offered.map((course, index) => (
                    <div key={index} className="inline-flex items-center mr-2 mb-2 bg-gray-200 p-2 rounded">
                      {course}
                      <button
                        type="button"
                        className="ml-2 text-red-600"
                        onClick={() => setFormData(prev => ({
                          ...prev,
                          course_offered: prev.course_offered.filter(c => c !== course),
                        }))}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md">Add Program</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddPrograms;
