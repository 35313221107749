import React from "react";
import { Route, Routes } from "react-router-dom";
// import HomePage from "../landing/pages/HomePage";
import Landing from '../pages/Landing';
import College from '../pages/College';
import AddProfessor from "../dashboard/sidebarpages/professor/AddProfessor";
import AddPlacementData from "../dashboard/sidebarpages/placements/AddPlacementData";
import EditPlacement from "../dashboard/sidebarpages/placements/EditPlacement";
import AllPlacements from "../dashboard/sidebarpages/placements/AllPlacements";
// import EditProfessor from "../dashboard/sidebarpages/professor/EditProfessor";
import Dashboard from './../dashboard/components/dashboardpage/DashboardPage';
import AddEvent from "../dashboard/sidebarpages/events/AddEvent";
import AllEvents from "../dashboard/sidebarpages/events/AllEvents";
// import EditEvent from './../dashboard/sidebarpages/events/EditEvent';
import AddTestimonial from "../dashboard/sidebarpages/testimonial/AddTestimonial";
import AllTestimonial from "../dashboard/sidebarpages/testimonial/AllTestimonial";
import EditTestimonial from "../dashboard/sidebarpages/testimonial/EditTestimonial";
import AdminDashboard from "../Admin/dashboard/components/AdminDashboard/AdminDashoard";
import AddUniversity from "../Admin/pages/universities/AddUniversity";
import EditUniversity from "../Admin/pages/universities/EditUniversity";
import AllUniversity from "../Admin/pages/universities/AllUniversity";
import AllPrograms from '../Admin/pages/programs/AllPrograms'
import AddPrograms from "../Admin/pages/programs/AddPrograms";
import EditPrograms from "../Admin/pages/programs/EditPrograms";
import AllCourses from "../Admin/pages/courses/AllCourses";
import AddCourses from "../Admin/pages/courses/AddCourses";
import EditCourses from "../Admin/pages/courses/EditCourses";

import AllProfessor from "../dashboard/sidebarpages/professor/AllProfessor";
import AddFAQ from "../dashboard/sidebarpages/faq/AddFAQ";
import AllFaq from "../dashboard/sidebarpages/faq/AllFaq";
import EditFAQ from "../dashboard/sidebarpages/faq/EditFAQ";
import EditEvent from "../dashboard/sidebarpages/events/EditEvent";
import AddNews from "../dashboard/sidebarpages/news/AddNews";
import EditNews from "../dashboard/sidebarpages/news/EditNews";
import AllNews from "../dashboard/sidebarpages/news/AllNews";
import AddAmbassador from "../dashboard/sidebarpages/ambassadors/AddAmbassador";
import AllAmbassadors from "../dashboard/sidebarpages/ambassadors/AllAmbassadors";
import AllAdmissions from "../Admin/pages/admissions/AllAdmissions";
import AddAdmissions from "../Admin/pages/admissions/AddAdmissions";
import EditAdmissions from "../Admin/pages/admissions/EditAdmissions";
import AddScholarship from "../Admin/pages/scholarship/AddScholarship";
import AllScholarship from "../Admin/pages/scholarship/AllScholarship";
import EditScholarship from "../Admin/pages/scholarship/EditScholarship";
import AddAdminFaq from "../Admin/pages/faqs/AddAdminFaq";
import AllAdminFaq from "../Admin/pages/faqs/AllAdminFaq";
import EditAdminFaq from "../Admin/pages/faqs/EditAdminFaq";
import EditProfessor from "../dashboard/sidebarpages/professor/EditProfessor";
import LandingUniversity from "../components/Landing/LandingUniversity/LandingUniversity";

import AddPlacements from "../Admin/pages/placements/AddPlacements";
import AllPlacement from "../Admin/pages/placements/AllPlacements";
import InterestedProspect from "../dashboard/sidebarpages/student/InterestedProspect";
import AdmissionProspect from "../dashboard/sidebarpages/student/AdmissionProspect";
import EditAmbassador from "../dashboard/sidebarpages/ambassadors/EditAmbassadors";
import Analytics from "../dashboard/sidebarpages/analytics/Analytics";
import Survey from '../Survey';
// >>>>>>> fae7c615ee81025746386618c4839de0f70b6a97

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/survey" element={<Survey />} />      

      <Route path="/landinguniversity" element={<LandingUniversity />} />
      <Route path="/college" element={<College />} />      
      <Route path="/dashboard" element={<Dashboard />}>

      
        {/* Nested routes for events */}
        <Route path="events">
          <Route path="add" element={<AddEvent />} />
          { <Route path="edit/:id" element={<EditEvent />} /> }
          <Route path="all" element={<AllEvents />} />
        </Route>
        <Route path="testimonials">
          <Route path="add" element={<AddTestimonial />} />
          { <Route path="edit/:id" element={<EditTestimonial />} /> } 
          <Route path="all" element={<AllTestimonial />} />
        </Route>
        {/* Other routes */}
        <Route path="professors">
          <Route path="add" element={<AddProfessor />} />
          <Route path="all" element={<AllProfessor />} /> 
          <Route path="edit/:id" element={<EditProfessor/>} /> 
        </Route>
        
        <Route path="students">
          <Route path="interested" element={<InterestedProspect />} />
          <Route path="admission" element={<AdmissionProspect />} /> 
          {/* <Route path="edit/:id" element={<EditProfessor/>} />  */}
        </Route>
      
        {/* <Route path="professors/edit" element={<EditProfessor />} /> */}
      
        <Route path="placements">
          <Route path="add" element={<AddPlacementData />} />
          <Route path="edit/:id" element={<EditPlacement />} /> 
          <Route path="all" element={<AllPlacements />} />
        </Route>
        <Route path="festivals" element={<div>Festivals Content</div>} />
        <Route path="news">
          <Route path="add" element={<AddNews />} />
          <Route path="edit/:id" element={<EditNews />} />
          <Route path="all" element={<AllNews />} />
        </Route>
        <Route path="gallery" element={<div>Gallery Content</div>} />
      
        <Route path="faqs">
          <Route path="add" element={<AddFAQ />} />
          <Route path="all" element={<AllFaq />} />
          <Route path="edit/:id" element={<EditFAQ />} />

        </Route>
        <Route path="ambassadors">
          <Route path="add" element={<AddAmbassador />} />
          <Route path="all" element={<AllAmbassadors />} />
          <Route path="edit/:id" element={<EditAmbassador />} />

        </Route>
        <Route path="testimonials" element={<div>Student Testimonials Content</div>} />
        {/* <Route path="ambassadors" element={<AddAmbassadors/>} /> */}
        <Route index element={ <Analytics />} /> {/* Default content */}
      </Route>


      <Route path="/admin" element={<AdminDashboard />}>

      <Route path="university">

      <Route path="all" element={<AllUniversity />} />
      <Route path="add" element={<AddUniversity />} />
      <Route path="edit/:id" element={<EditUniversity />} />

        </Route>



        <Route path="programs">
        
        <Route path="all" element={<AllPrograms   />} />
        <Route path="add" element={<AddPrograms />} />
        <Route path="edit/:id" element={<EditPrograms />} />
  
          </Route>

          <Route path="courses">
        
        <Route path="all" element={<AllCourses  />} />
        <Route path="add" element={<AddCourses />} />
        <Route path="edit/:id" element={<EditCourses />} />
  
          </Route>

          <Route path="admissions">
        
        <Route path="all" element={<AllAdmissions  />} />
        <Route path="add" element={<AddAdmissions />} />
        <Route path="edit/:id" element={<EditAdmissions />} />
  
          </Route>
          <Route path="scholarship">
        
        <Route path="all" element={<AllScholarship  />} />
        <Route path="add" element={<AddScholarship />} />
        <Route path="edit/:id" element={<EditScholarship />} />
  
          </Route>

          <Route path="placement">
        
        <Route path="all" element={<AllPlacement  />} />
        <Route path="add" element={<AddPlacements />} />
        <Route path="edit/:id" element={<EditScholarship />} />
  
          </Route>

          <Route path="faqs">
          <Route path="add" element={<AddAdminFaq/>} />
          <Route path="all" element={<AllAdminFaq />} />
          <Route path="edit/:id" element={<EditAdminFaq />} />

        </Route>

        

        </Route>

    </Routes>
  );
};

export default AppRoutes;
