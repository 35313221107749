// import React, { useState, useEffect } from 'react';
// import './top-colleges.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import { Base_URL, Img_URL } from '../../../apiConfig';
// import college_logo from '../../../assets/college_logo.png';

// const TopColleges = () => {
//     // State for storing colleges data and loading/error status
//     const [colleges, setColleges] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     // Fetch data from the API on component mount
//     useEffect(() => {
//         fetch(`${Base_URL}/universities/featured/colleges`)
//             .then(response => response.json())
//             .then(data => {
//                 console.log("Fetched data:", data);
//                 setColleges(data);
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching data:", error);
//                 setError("Failed to load featured colleges.");
//                 setLoading(false);
//             });
//     }, []);
// const getImageUrl = (path) => {
//     if (path && !path.startsWith('http')) {
//         const fullUrl = `${Img_URL}/${path}`;
//         console.log("Constructed Image URL:", fullUrl); // Log constructed image URL
//         return fullUrl;
//     }
//     console.log("Using fallback image URL:", college_logo); // Log fallback image URL
//     return college_logo; // Fallback image if URL is invalid
// };


//     const settings = {
//         customPaging: function (i) {
//             return (
//                 <a>
//                     <div className="dot"></div>
//                 </a>
//             );
//         },
//         dots: true,
//         infinite: true,
//         speed: 500,
//         autoplay: true,
//         autoplaySpeed: 3000,
//         cssEase: "linear",
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         appendDots: dots => (
//             <div>
//                 <ul className="custom-dots">
//                     {dots}
//                 </ul>
//             </div>
//         ),
//     };

//     return (
//         <div className="py-8 px-8">
//             <p className="text-center text-4xl font-bold mb-5">Lorem ipsum odor amet</p>
//             <p className="text-center text-base mb-5">Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus neque felis a tristique velit metus. Lorem ipsum odor amet consectetuer adipiscing elit.</p>
//             <div className="py-8">
//                 {loading ? (
//                     <p>Loading...</p>
//                 ) : (
//                      <Slider {...settings}>
// {(error ? [{
//     university_name: "Default College",
//     main_intro: "This is the main introduction about the university.",
//     keywords: "Keywords not available",
//     pictures: [college_logo] // Default image
// }] : colleges).map((college, index) => {
//     // Construct the image URL
//     const imageUrl = college.pictures && college.pictures.length > 0
//         ? getImageUrl(college.pictures[0]) // Assuming pictures is an array
//         : college_logo; // Fallback image

//     console.log("Rendering Image URL:", imageUrl); 

//                             return (
//                                 <div key={index} className="flex justify-center items-center">
//                                     <div className="flex items-center">
//                                         <div className="w-[400px] h-[400px] flex-shrink-0 rounded-3xl overflow-hidden">
//                                             <img
//                                                 src={imageUrl}
//                                                 alt={college.name}
//                                                 className="w-full h-full object-cover"
//                                                 onError={(e) => {
//                                                     console.error(`Error loading image: ${imageUrl}`);
//                                                     e.target.src = college_logo; // Fallback to default image
//                                                 }}
//                                             />
//                                         </div>
//                                         <div className="p-5 flex flex-col gap-3 bg-[#F2FCEB] rounded-2xl transform -translate-x-14 shadow-md">
//                                             <p className="text-[#757575] text-lg font-semibold">{college.keywords || "LOREM IPSUM ODOR AMET"}</p>
//                                             <p className="text-xl font-bold">{college.university_name}</p>
//                                             <p className="text-[#757575] font-medium text-lg">{college.main_intro|| "Lorem ipsum odor amet, consectetuer adipiscing elit. Tristique suscipit molestie lectus quis lacinia molestie. Vestibulum eleifend maecenas vestibulum cras curabitur eu."}</p>
//                                             <div>
//                                                 <button className="py-[3px] px-5 bg-[#3ACA2E] text-white font-medium rounded-2xl">Explore</button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             );
//                         })}
//                     </Slider>
//                 )}
//             </div>
//         </div>
//     );
// };

//  export default TopColleges;
import React from 'react';
import './top-colleges.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import college_logo from '../../../assets/college_logo.png';
import LPU_logo from '../../../assets/landing/LPU_logo.png';
import Tula_logo from '../../../assets/landing/Tula_logo.png';
import GE_logo from '../../../assets/landing/GE_logo.png';
import CU_logo from '../../../assets/landing/CU_logo.png'

// Static data
const colleges = [
   {
        university_name: "Lovely Professional University (LPU)",
        main_intro: "Lovely Professional University (LPU) is a leading private university known for its comprehensive range of programs and a strong focus on global education. LPU emphasizes innovation and industry readiness, preparing students for success in a competitive world.",
        keywords: "Diversity, Innovation, Growth",
        pictures: [college_logo]
    },
    {
        university_name: "Tula’s Institute",
        main_intro: "Tula’s Institute is renowned for its exceptional engineering and management programs. It boasts strong industry connections and a focus on practical learning, equipping students with the skills needed to excel in their professional careers.",
        keywords: "Engineering, Management, Industry",
        pictures: [Tula_logo]
    },
    {
        university_name: "Graphic Era University",
        main_intro: "Graphic Era University offers a diverse array of programs with a commitment to excellence in education and research. The university focuses on comprehensive learning experiences and fosters an environment of growth and development for its students.",
        keywords: "Comprehensive, Research, Excellence",
        pictures: [GE_logo]
    },
    {
        university_name: "Chandigarh University",
        main_intro: "Chandigarh University is a top-tier institution known for its innovative programs and global collaboration. It provides a dynamic learning environment and emphasizes the development of skills necessary for success in a rapidly evolving world.",
        keywords: "Innovation, Collaboration, Growth",
        pictures:[CU_logo]
    }
];

const TopColleges = () => {
    const getImageUrl = (path) => {
        return path || college_logo;
    };

    const settings = {
        customPaging: function (i) {
            return (
                <a>
                    <div className="dot"></div>
                </a>
            );
        },
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => (
            <div>
                <ul className="custom-dots">
                    {dots}
                </ul>
            </div>
        ),
    };

    return (
        <div className="py-8 px-8">
            <p className="text-center text-[28px] md:text-[36px] font-sans font-[700] mb-5">Top Featured Colleges</p>
            <p className="text-center text-[16px] md:text-[24px] font-sans mb-5">
                Discover our selection of top colleges and universities renowned for their excellence and innovation.
            </p>
            <div className="py-8">
                <Slider {...settings}>
                    {colleges.map((college, index) => {
                        const imageUrl = college.pictures && college.pictures.length > 0
                            ? getImageUrl(college.pictures[0])
                            : college_logo;

                        return (
                            <div key={index} className="flex justify-center items-center mt-6">
                                <div className="flex items-center md:flex-row flex-col">
                                    {/* Image for large screens */}
                                    <div className="w-[400px] h-[400px] flex-shrink-0 rounded-full overflow-hidden hidden md:block">
                                        <img
                                            src={imageUrl}
                                            alt={college.university_name}
                                            className="w-full h-full object-cover rounded-full"
                                            onError={(e) => {
                                                e.target.src = college_logo;
                                            }}
                                        />
                                    </div>
                                    
                                    {/* Image for mobile view */}
                                    <div className="w-[332px] h-[316px] flex-shrink-0 rounded-[20px_0px_0px_0px] overflow-hidden block md:hidden ">
                                        <img
                                            src={imageUrl}
                                            alt={college.university_name}
                                            className="w-full h-full object-cover"
                                            onError={(e) => {
                                                e.target.src = college_logo;
                                            }}
                                        />
                                    </div>

                                    {/* Card Text */}
                                    <div className="mb-[10px] p-5 flex flex-col gap-3 bg-[#F2FCEB] rounded-2xl shadow-lg md:transform md:-translate-x-14 -mt-3.5 md:relative relative  md:mt-0 md:w-auto w-[278.51px] h-[267px]" style={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)' }}>

                                        <p className="text-[#757575] text-lg  font-semibold ">{college.keywords || "Keywords Not Available"}</p>
                                        <p className="text-xl font-bold ">{college.university_name}</p>
                                        <p className="text-[#757575] font-medium text-md line-clamp-3">{college.main_intro || "Introduction not available."}</p>
                                        <div>
                                            <button className="py-[3px] px-5  bg-[#3ACA2E] h-[30px] w-[232px] text-white md:w-[150px] md:h-[40px] font-medium rounded-[20px] ">Explore</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default TopColleges;
