const dummystudent = () => {

    const names = ['John Smith', 'Jane Doe', 'Alice Johnson', 'Bob Brown', 'Carol White'];
    const departments = ['Computer Science', 'Mathematics', 'Physics', 'Chemistry', 'Biology'];
    const statuses = [ 'Pending','Completed'];
  
    const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];
    
    return Array.from({ length: 100 }, (_, index,id) => ({
      id: id + 1,
      picture: `https://randomuser.me/api/portraits/men/${index}.jpg`,
      name: getRandomItem(names),
      email: `user${id + 1}@example.com`,
      phone_number: `123-456-${String(id + 1).padStart(4, '0')}`,
      admission: 26/11/2023,
      department: getRandomItem(departments),
      status: getRandomItem(statuses),
    }));
  };
  
  export default dummystudent();
  
  