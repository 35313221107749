import React, { useState, useEffect, useRef } from 'react';
import users from './users'; // Mock data, replace with your data fetching logic

const UserListSection = ({ onUserSelect }) => {
  const [displayedUsers, setDisplayedUsers] = useState(users.slice(0, 20)); // Initial data load
  const [loading, setLoading] = useState(false); // Loading state
  const containerRef = useRef(null); // Reference to the scrollable container

  // Function to load more users (simulate API call)
  const loadMoreUsers = () => {
    if (loading) return; // Prevent loading while already loading

    setLoading(true);

    // Simulate an API call with a timeout
    setTimeout(() => {
      setDisplayedUsers(prevUsers => {
        // Simulate fetching additional data
        const nextUsers = users.slice(prevUsers.length, prevUsers.length + 20);
        return [...prevUsers, ...nextUsers];
      });
      setLoading(false);
    }, 1000); // Simulate network delay
  };

  // Handle scroll event to load more data
  const handleScroll = () => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollHeight - scrollTop <= clientHeight + 100 && !loading) { // Load more when near bottom
      loadMoreUsers();
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [loading]);

  const groupedUsers = displayedUsers.reduce((acc, user) => {
    const letter = user.name.charAt(0).toUpperCase();
    if (!acc[letter]) {
      acc[letter] = [];
    }
    acc[letter].push(user);
    return acc;
  }, {});

  return (
    <div
      ref={containerRef}
      className="flex flex-col w-full h-full overflow-y-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-300 scrollbar-track-gray-100"
      style={{ height: 'calc(100vh - 64px)' }} // Adjust height as needed
    >
      {Object.keys(groupedUsers).sort().map(letter => (
        <div key={letter} className="flex flex-col">
          <div className="bg-gray-200 text-black py-2 px-4 font-semibold">
            {letter}
          </div>
          {groupedUsers[letter].map(user => (
            <div
              key={user.name}
              className="flex items-center py-3 px-4 border-b border-gray-300 bg-white cursor-pointer hover:bg-gray-100"
              onClick={() => onUserSelect(user)}
            >
              <div className="relative w-10 h-10 mr-4">
                <img
                  src={user.profilePic}
                  alt={user.name}
                  className="w-full h-full rounded-full object-cover"
                />
                <div
                  className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 ${user.status === 'online' ? 'bg-green-500' : 'bg-red-500'}`}
                />
              </div>
              <span className="text-black font-medium" style={{ fontFamily: 'Poppins, sans-serif' }}>
                {user.name}
              </span>
            </div>
          ))}
        </div>
      ))}
      {loading && (
        <div className="text-center py-4">
          <span>Loading more...</span>
        </div>
      )}
    </div>
  );
};

export default UserListSection;




