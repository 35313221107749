const generateUsers = () => {
  const users = [];
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numUsersPerLetter = 10;

  // Common Indian first names for each letter of the alphabet
  const indianFirstNames = {
    A: ['Aarav', 'Ananya', 'Aishwarya', 'Aditya', 'Arjun'],
    B: ['Bhavana', 'Bharat', 'Bhaskar', 'Bina', 'Bimal'],
    C: ['Chirag', 'Chitra', 'Chandan', 'Charu', 'Chaitanya'],
    D: ['Divya', 'Deepak', 'Dinesh', 'Dhruv', 'Darshan'],
    E: ['Ekta', 'Esha', 'Eshwar', 'Eshaan', 'Eknath'],
    F: ['Farhan', 'Fatima', 'Firoz', 'Fahad', 'Faizal'],
    G: ['Gaurav', 'Geeta', 'Ganesh', 'Gulshan', 'Gitanjali'],
    H: ['Harsh', 'Heena', 'Himanshu', 'Hemant', 'Harini'],
    I: ['Isha', 'Inder', 'Ishan', 'Indira', 'Iqbal'],
    J: ['Jaspreet', 'Jatin', 'Jaya', 'Jayesh', 'Jyoti'],
    K: ['Kiran', 'Kunal', 'Kavita', 'Karthik', 'Kalyani'],
    L: ['Lakshmi', 'Lalit', 'Leela', 'Lokesh', 'Lalita'],
    M: ['Manish', 'Meena', 'Mohit', 'Monika', 'Mohan'],
    N: ['Nidhi', 'Naveen', 'Neha', 'Nikhil', 'Namrata'],
    O: ['Om', 'Ojas', 'Oviya', 'Omkara', 'Omisha'],
    P: ['Priya', 'Pranav', 'Poonam', 'Piyush', 'Prateek'],
    Q: ['Qasim', 'Qamar', 'Qadir', 'Qutb', 'Qudsia'],
    R: ['Rohit', 'Rekha', 'Rajesh', 'Rina', 'Rakesh'],
    S: ['Sneha', 'Sanjay', 'Sunita', 'Suresh', 'Sakshi'],
    T: ['Tara', 'Tarun', 'Tanvi', 'Tejas', 'Tanuja'],
    U: ['Uday', 'Uma', 'Usha', 'Ujjwal', 'Urmila'],
    V: ['Vikas', 'Vinod', 'Vidya', 'Vijay', 'Varun'],
    W: ['Waseem', 'Wali', 'Wasim', 'Wajid', 'Wafaa'],
    X: ['Xena', 'Xavier', 'Xara', 'Xylo', 'Xenia'],
    Y: ['Yash', 'Yamini', 'Yuvraj', 'Yogesh', 'Yashika'],
    Z: ['Zoya', 'Zaheer', 'Zubin', 'Zara', 'Zayed']
  };

  // Common Indian last names
  const indianLastNames = ['Sharma', 'Verma', 'Patel', 'Gupta', 'Singh', 'Kumar', 'Reddy', 'Nair', 'Desai', 'Mehta'];

  // Generate users based on the alphabet
  alphabet.split('').forEach(letter => {
    const firstNamesForLetter = indianFirstNames[letter] || ['Rohan']; // Fallback to 'Rohan' if letter has no specific names
    for (let i = 1; i <= numUsersPerLetter; i++) {
      const randomFirstName = firstNamesForLetter[Math.floor(Math.random() * firstNamesForLetter.length)];
      const randomLastName = indianLastNames[Math.floor(Math.random() * indianLastNames.length)];
      users.push({
        name: `${randomFirstName} ${randomLastName}`,  // Indian first and last name
        profilePic: "https://randomuser.me/api/portraits/men/1.jpg",  // Default profile picture
        status: i % 2 === 0 ? 'online' : 'offline'  // Alternating status for demo
      });
    }
  });

  return users;
};

const users = generateUsers();

export default users;



