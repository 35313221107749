import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const EditTestimonial = () => {
  const { id } = useParams();
  const navigate = useNavigate(); // Use navigate for redirection
  const [formData, setFormData] = useState({
    student_name: '',
    course: '',
    testimonial_text: '',
    university_id: 1,
  });
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTestimonial = async () => {
      try {
        const response = await axios.get(`${Base_URL}/student-testimonials/${id}`, {
          // headers: {
          //   'Authorization': `Bearer ${localStorage.getItem('token')}`, // Add authorization header
          // },
        });
        setFormData({
          student_name: response.data.student_name,
          course: response.data.course,
          testimonial_text: response.data.testimonial_text,
          university_id:1
        });
   
      } catch (error) {
        console.error('Error fetching testimonial:', error);
      }
    };

    fetchTestimonial();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageName(file.name);
    } else {
      setImageName('No file chosen');
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append('student_name', formData.student_name);
      formDataToSubmit.append('course', formData.course);
      formDataToSubmit.append('testimonial_text', formData.testimonial_text);
      formDataToSubmit.append('university_id', 1)
     
      if (image) {
        formDataToSubmit.append('profile_picture', image);
      }
  
      const url = `${Base_URL}/student-testimonials/${id}`; // Ensure id is properly defined
  
      await axios.put(url, formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      navigate('/dashboard/testimonials/all');
    } catch (error) {
      console.error('Error updating testimonials:', error.response ? error.response.data : error.message);
      toast.error('Failed to updatetestimonial details.');
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <HeaderSection
        title="Edit Testimonial"
        breadcrumb={['Testimonial', 'Edit Testimonial']}
      />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[rgb(136,136,136)] text-[17.25px] font-[400] font-poppins">Edit Testimonial</h2>
        </div>

        {error && <div className="text-red-600 mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                htmlFor="student_name"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Student Name
              </label>
              <input
                type="text"
                id="student_name"
                name="student_name"
                value={formData.student_name}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="course"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Course
              </label>
              <input
                type="text"
                id="course"
                name="course"
                value={formData.course}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="testimonial_text"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Testimonial
              </label>
              <textarea
                id="testimonial_text"
                name="testimonial_text"
                value={formData.testimonial_text}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="profile_picture"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[100] mb-[6px]">
                Upload Profile Picture
              </label>
              <div className="flex border border-gray-300 rounded-lg overflow-hidden">
                <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer font-poppins">
                  <span className='text-[12.25px]'>Choose File</span>
                  <input
                    type="file"
                    id="profile_picture"
                    name="profile_picture"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </label>
                <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center font-poppins text-[12.25px]">
                  {imageName || 'No file chosen'}
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-[rgb(106,115,250)] text-white rounded-md font-poppins">
            Update
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditTestimonial;
