import React, { useState ,useEffect} from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';

// import { useProfessor } from '../../../context/ProfessorContext';

//import { Base_URL,Img_URL} from '../../../apiConfig'; 
import axios from 'axios';
import dummystudent from './dummystudent';
const InterestedProspect = () => {
  // const { professorId } = useProfessor();
  // console.log('professorId from context:', professorId); 
// Function to replace the server path with the base URL
// function convertImagePaths(paths) {
//   return paths.map(path => path.replace('/var/www/zanko/uploads', Img_URL));
// }


  const [professors, setProfessors] = useState([]);
  const [newProfessorId, setNewProfessorId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [id, setId] = useState();
  const navigate = useNavigate();

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = professors.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(professors.length / entriesPerPage);

  // useEffect(() => {
  //   const fetchProfessors = async () => {
  //     try {
  //       const response = await axios.get(`${Base_URL}/faculty/university/1`);
  //       // // const professorsWithUpdatedImages = response.data.map(professor => ({
  //       // //   ...professor,
  //       // //   picture: professor.picture ? convertImagePaths([professor.picture])[0] : '/path/to/default/image.jpg'
  //       // }));
  //       setId(response.data.id);
  //       //setProfessors(professorsWithUpdatedImages);
  //     } catch (error) {
  //       console.error('Error fetching professors:', error);
  //       setError('Failed to fetch professors.');
  //     }
  //   };
  
  //   fetchProfessors();
  // }, []);
  useEffect(() => {
      setProfessors(dummystudent);
    })
  // useEffect(() => {
  //   console.log('Fetching professors ');
  //   const fetchProfessors = async () => {
  //     try {
  //       const response = await axios.get(`${Base_URL}/faculty/university/1`);
  //       console.log(response.data);
  //       setProfessors(response.data);
  //     } catch (error) {
  //       console.error('Error fetching professors:', error.response ? error.response.data : error.message);
  //     }
  //   };

  //   fetchProfessors();
  // }, []);
  
  // const handleEditClick = (id) => {
  //   navigate(`/dashboard/professors/edit/${id}`);
  // };

  // const handleDeleteClick = async (id) => {
  //   console.log('Deleting faculty with ID:', id);
  //   const universityId = 1; // Ensure this is the correct university ID
  //   if (window.confirm('Are you sure you want to delete this professor?')) {
  //     try {
  //       const url = `${Base_URL}/faculty/${id}`;
  //       console.log('DELETE request URL:', url);
  //       await axios.delete(url);
  //       setProfessors(prevProfessors => prevProfessors.filter(professor => professor.faculty_id !== id));
  //       alert('Professor deleted successfully.');
  //     } catch (error) {
  //       console.error('Error deleting professor:', error.response ? error.response.data : error.message);
  //       setError('Failed to delete professor.');
  //     }
  //   }
  // };
  
  

  // const handlePrevious = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  // const handleNext = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  const filteredEntries = currentEntries.filter((professor) =>
    professor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
  
//   const [professors, setProfessors] = useState(dummyProfessors);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [entriesPerPage, setEntriesPerPage] = useState(20);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   const indexOfLastEntry = currentPage * entriesPerPage;
//   const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
//   const currentEntries = professors.slice(indexOfFirstEntry, indexOfFirstEntry + entriesPerPage);

//   const totalPages = Math.ceil(professors.length / entriesPerPage);

 
//   useEffect(() => {
//     const fetchProfessors = async () => {
//       try {
//         const response = await axios.get(`${Base_URL}/faculty/university/1`); // Hardcoded university_id = 1
//         setProfessors(response.data); // Set the fetched events data
//       } catch (error) {
//         console.error('Error fetching events:', error);
//       }
//     };
  
//     fetchProfessors();
//   }, []);

//   const handleEditClick = (id) => {
//     // Redirect or open edit form with id and university_id
//     window.location.href = `/dashboard/professors/edit/${id}?university_id=1`;
//   };

//   const handleDeleteClick = async (id) => {
//     // Confirm delete action
//     if (window.confirm('Are you sure you want to delete this professor?')) {
//       try {
//         await axios.delete(`${Base_URL}/professors/${id}`, {
//           params: { university_id: 1 },
//         });
//         setProfessors(professors.filter(professor => professor.id !== id));
//         alert('Professor deleted successfully.');
//       } catch (error) {
//         console.error('Error deleting professor:', error);
//         setError('Failed to delete professor.');
//       }
//     }
//   };
//   // useEffect(() => {
//   //   const fetchProfessors = async () => {
//   //     try {
//   //       const response = await axios.get(`${Base_URL}/professors`);
//   //       setProfessors(response.data);
//   //     } catch (error) {
//   //       console.error("Error fetching professors:", error);
//   //       setError("Failed to load professors");
//   //     }
//   //   };
  
//   //   fetchProfessors();
//   // }, []);
  
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDeleteClick = (id) => {
    setProfessors(professors.filter((professor) => professor.id !== id));
  };

  const handleEditClick = (id) => {
    navigate('/dashboard/students/interested');
  };

//    const filteredEntries = currentEntries.filter((professor) =>
//      professor.name.toLowerCase().includes(searchTerm.toLowerCase())
//    );

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All Students" breadcrumb={['Interested Prospect', 'All Interested Prospects']} />

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div
            className="font-poppins"
            style={{
              color: 'rgb(49, 42, 42)',
              fontSize: '18.5px',
              fontWeight: 600,
              lineHeight: '26.25px',
              height: '45px',
            }}
          >
            All Interested Prospects
          </div>
          <div className="flex items-center space-x-4">
            <button
              className="px-4 py-2  rounded-bl-[5px] rounded-br-[5px] text-sm  bg-customblue text-white rounded  hover:bg-[rgb(82,89,199)] "
              onClick={() => navigate('/dashboard/students/interested')}
            >
              + Add New
            </button>
          </div>
        </div>
         {/* Flex container for "Show Entries" dropdown and search bar */}
         <div className="flex items-center justify-between mb-4 pb-2 ">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span
              className="text-sm text-gray-600"
              style={{
                color: 'rgb(130, 130, 130)',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '14px',
              }}
            >
              Search:
            </span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr  className="bg-white">
                {['Name', 'Email',  'Phone Number','Admission', 'Department','Status', 'Actions'].map((heading) => (
                  <th key={heading} className="text-left border-b" style={{ color: 'rgb(49, 42, 42)', fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: 600, lineHeight: '21px', padding: '14px 13px', borderBottomColor: 'rgb(230, 230, 230)' }}>
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredEntries.length > 0 ? (
                filteredEntries.map((professor) => (
                  console.log(professor.faculty_id),
                  <tr
                    key={professor.faculty_id}
                    className="hover:bg-white hover:shadow-md border-b"
                    style={{ borderTopWidth: '1px', borderTopColor: 'rgb(230, 230, 230)', verticalAlign: 'top' }}
                  >
                    {/* <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                      <img
                        src={professor.profile_picture || '/path/to/default/image.jpg'}
                        alt={professor.name}
                        className="rounded-full"
                        style={{ width: '35px', verticalAlign: 'middle' }}
                      />
                    </td> */}
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                      {professor.name}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                      {professor.email}
                    </td>
                   
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                      {professor.phone_number}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                      {professor.admission}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                      {professor.department}
                    </td>

                   
                    <td
  className={`text-left border-t border-b ${professor.status === 1 ? 'border-green-500' : 'border-red-500'}`}
  style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)' }}
>
  <span
    className={`inline-block py-1 px-3 rounded-full text-sm ${professor.status === 1 ? 'bg-green-100 text-green-600 border border-green-500' : 'bg-red-100 text-red-600 border border-red-500'}`}
  >
    {professor.status}
  </span>
</td>

                    
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)' }}>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditClick(professor.faculty_id)}
                          className="p-2 rounded bg-blue-500 text-white hover:bg-blue-700"
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(professor.faculty_id)}
                          className="p-2 rounded bg-red-500 text-white hover:bg-red-700"
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center py-4">No professors found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
         
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2  bg-customblue text-white rounded text-sm hover:bg-[rgb(82,89,199)]"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2  bg-customblue text-white rounded text-sm hover:bg-[rgb(82,89,199)]"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestedProspect;

