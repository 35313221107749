import React from 'react'


const ReviewCard = ({rating, name, username, profile_img})=>{
   
    //for rating preview
    const fullStars = Math.floor(rating); 
    const hasHalfStar = rating % 1 >= 0.5;
    return(
        <div className="p-3 bg-white max-w-[400px]">
            <div className="flex gap-2 mb-5">
                <div className="h-[40px] w-[40px]"><img src={profile_img} alt="user-image" className="w-full h-full" /></div>
                <div className="flex flex-col">
                    <p  className="text-base text-black font-medium">{name}</p>
                    <p>@{username}</p>
                    <div className="flex gap-1 mt-1">
                    {Array.from({ length: fullStars }, (_, index) => (
                        <svg key={index} xmlns="http://www.w3.org/2000/svg" fill="#FCD34D" viewBox="0 0 24 24" stroke-width="2" stroke="#FCD34D" className="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                        </svg>
                    ))}
                    {hasHalfStar && (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FCD34D" viewBox="0 0 24 24" stroke-width="2" stroke="#FCD34D" className="size-4">
                             <defs>
                            <linearGradient id="half_grad">
                                <stop offset="50%" stop-color="#FCD34D"/>
                                <stop offset="50%" stop-color="white" stop-opacity="1" />
                            </linearGradient>
                        </defs>
                            <path stroke-linecap="round" stroke-linejoin="round" fill="url(#half_grad)" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                        </svg>
                    )}
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                {/* <div className="mb-3 flex"><p className="bg-[#ECFDF5] text-[#064E3B] text-sm font-medium p-2 rounded-xl">Lorem Ipsum</p></div> */}
                <div className="overflow-hidden">
                    <p className="text-black text-base">Studying at LPU has been one of the most enriching experiences of my life. The university offers a perfect blend of academic excellence and hands-on learning. The state-of-the-art facilities and the diverse student community have helped me grow both personally and professionally. The faculty is incredibly supportive, guiding us through real-world projects and making learning exciting. LPU has truly shaped me into a confident, capable individual ready to take on future challenges.</p>
                </div>
                <div className="flex items-center gap-1 text-black cursor-pointer mt-2">
                    {/* <p className="text-sm font-semibold">show more</p>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg> */}
                </div>
                <div className="mt-5 text-sm">
                    <p>23 Nov 2021</p>
                </div>
            </div>
        </div>
    )
}

export default ReviewCard;