import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { Base_URL } from '../../../apiConfig';
import { useLocation } from 'react-router-dom';

const AddAdminFaq = () => {
  const [formData, setFormData] = useState({
    question: '',
    answer: '',
    university_id: '',  // This will be set after fetching user data
    user_id: '',        // This will be set after fetching user data
    registration_typeid: 3  // Fixed value as specified
  });
  const [error, setError] = useState(null);

  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  useEffect(() => {
    // Fetching user_id and university_id from the context or API
    const fetchIds = async () => {
      try {
        // Example API call to get user and university data
        const response = await axios.get(`${Base_URL}/universities`);
        setFormData((prevData) => ({
          ...prevData,
          university_id: response.data.university_id || 2,
          user_id: response.data.user_id || 2
        }));
      } catch (error) {
        console.error('Failed to fetch IDs:', error.response?.data || error.message);
        setError('Failed to fetch user data. Please try again.');
      }
    };

    fetchIds();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Ensure registration_typeid is always 3
      const postData = { ...formData, registration_typeid: 3 };

      const response = await axios.post(`${Base_URL}/faq/`, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      alert('FAQ added successfully!');
      setFormData({
        question: '',
        answer: '',
        university_id: formData.university_id,
        user_id: formData.user_id,
        registration_typeid: 3, // Reset to default value
      });
      setError(null);
    } catch (error) {
      console.error('Failed to add FAQ:', error.response?.data || error.message);
      setError('Failed to add FAQ. Please try again.');
    }
  };

  return (
    <div className="bg-gray-100 h-screen p-6 rounded-lg ">
    <HeaderTitle subTitle={subTitle} mainTitle={mainTitle} />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        {/* Basic Info Header */}
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[rgb(136,136,136)] text-[17.25px] font-[400] font-poppins">FAQ Details</h2>
        </div>

      

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Form Fields */}
            <div className="flex flex-col">
              <label
                htmlFor="question"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Question
              </label>
              <input
                type="text"
                id="question"
                name="question"
                value={formData.question}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="answer"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Answer
              </label>
              <input
                type="text"
                id="answer"
                name="answer"
                value={formData.answer}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-[rgb(106,115,250)] text-white rounded-md font-poppins">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddAdminFaq;
