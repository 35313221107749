import React from 'react';
import college_logo from '../../../assets/college_logo.png';
import Rankings from '../Overview/Rankings/Rankings';
import PlacementCard from './PlacementCard/PlacementCard';

const Placements = () => {
    return (
        <div className="py-8 px-5 bg-[#DCFFC4] min-h-screen flex flex-col items-center">

            <div className="text-black text-base font-medium flex flex-col gap-5 items-center">

                <div className="flex items-center justify-center gap-3 py-2">
                    {/* <div className="md:h-[80px] md:w-[80px] h-[30px] w-[30px]">
                        <img src={college_logo} alt="college-logo" className="h-full w-full object-cover" />
                    </div> */}
                    <p className="md:text-3xl text-black font-semibold text-[20px] ">LPU : Lovely Professional University</p>
                </div>

                <p className="text-center text-lg text-black font-[500] mb-5">Placements</p>

                <p className="mb-8 text-center">
                    Lovely Professional University (LPU) boasts a stellar placement record, with numerous top-tier companies visiting the campus every year. The university has successfully placed students in leading multinational companies, offering lucrative salary packages and exciting career opportunities. LPU's placement cell works tirelessly to ensure students are well-prepared for interviews and are able to secure positions in companies of their choice.
                </p>
            </div>

            {/* Rankings */}
            <Rankings />

            {/* Placement Cards */}
            <div className="py-5 w-full">
                <p className="py-3 text-center text-lg text-black font-semibold mb-5">Top Company Placements</p>
                
                <div className="flex flex-wrap gap-5 justify-center text-black p-3">
                    <PlacementCard 
                        company="Google" 
                        role="Software Engineer"
                        package="₹40 LPA"
                        image={college_logo} // Replace with company logo
                    />
                    <PlacementCard 
                        company="Amazon" 
                        role="Data Scientist"
                        package="₹35 LPA"
                        image={college_logo} // Replace with company logo
                    />
                    <PlacementCard 
                        company="Microsoft" 
                        role="Cloud Engineer"
                        package="₹32 LPA"
                        image={college_logo} // Replace with company logo
                    />
                    <PlacementCard 
                        company="Deloitte" 
                        role="Consultant"
                        package="₹25 LPA"
                        image={college_logo} // Replace with company logo
                    />
                    <PlacementCard 
                        company="Tata Consultancy Services" 
                        role="Software Developer"
                        package="₹15 LPA"
                        image={college_logo} // Replace with company logo
                    />
                    <PlacementCard 
                        company="Cognizant" 
                        role="Business Analyst"
                        package="₹18 LPA"
                        image={college_logo} // Replace with company logo
                    />
                </div>
            </div>

        </div>
    );
};

export default Placements;
