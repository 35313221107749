// import React from 'react'
// import ReviewCard from '../../College/ReviewCard/ReviewCard';
// import user1 from '../../../assets/user1.png'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

// const Reviews = () =>{

//     function SampleNextArrow(props) {
//         const { className, style, onClick } = props;
//         return (
//             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="#000000" className={`${className} mr-5`} style={{ ...style, display: "block", cursor: "pointer" }} onClick={onClick}>
//                 <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
//             </svg>
//         );
//     }
    
    
//     function SamplePrevArrow(props) {
//         const { className, style, onClick } = props;
//         return (
//             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="#000000" className={`${className} ml-5`} style={{ ...style, display: "block", cursor: "pointer" }} onClick={onClick}>
//                 <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
//             </svg>
//         );
//     }

//     var settings = {
//         infinite: true,
//         speed: 500,
//         autoplay: true,
//         autoplaySpeed: 3000,
//         cssEase: "linear",
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         nextArrow: <SampleNextArrow />,
//         prevArrow: <SamplePrevArrow />
//     };

//     return(
//     <div className="px-5 pt-10 pb-14 bg-[#F2FCEB]">
//         <p className="text-center font-medium text-base mb-2 text-[#666666]">Student Experience</p>
//         <p className="text-black text-xl text-center font-medium mb-10">Real Voices, Real Stories: See How Our Students Are Shaping Their Futures! </p>
//         <Slider {...settings}>
//             <div className="p-10">
//                 <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
//             </div>
//             <div className="p-10">
//                 <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
//             </div>
//             <div className="p-10">
//                 <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
//             </div>
//             <div className="p-10">
//                 <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
//             </div>
//         </Slider>
//     </div>
//     )
// }

// export default Reviews;
import React from 'react';
import ReviewCard from '../../College/ReviewCard/ReviewCard';
import user1 from '../../../assets/user1.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Reviews = () => {

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="#000000" className={`${className} mr-5`} style={{ ...style, display: "block", cursor: "pointer" }} onClick={onClick}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
        );
    }
    
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="#000000" className={`${className} ml-5`} style={{ ...style, display: "block", cursor: "pointer" }} onClick={onClick}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
        );
    }

    const settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="px-5 pt-10 pb-14 bg-[#F2FCEB]">
            <p className="text-center font-medium text-base mb-2 text-[#666666]">Student Experience</p>
            <p className="text-black text-xl text-center font-medium mb-10">Real Voices, Real Stories: See How Our Students Are Shaping Their Futures!</p>
            <Slider {...settings}>
                <div className="p-5 md:p-10">
                    <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
                </div>
                <div className="p-5 md:p-10">
                    <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
                </div>
                <div className="p-5 md:p-10">
                    <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
                </div>
                <div className="p-5 md:p-10">
                    <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
                </div>
            </Slider>
        </div>
    );
}

export default Reviews;
