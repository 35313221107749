// import React, { useState } from 'react';
// import Header from '../../Header/Header';
// import Footer from '../../Footer/Footer';

// function LandingUniversity() {
//   const [wishlist, setWishlist] = useState(new Set());

//   // Sample array of universities
//   const universities = [
//     { id: 1, name: 'LPU', description: 'Lovely Professional University', image: 'https://via.placeholder.com/300' },
//     { id: 2, name: 'Chandigarh University', description: 'Chandigarh University, Punjab', image: 'https://via.placeholder.com/300' },
//     { id: 3, name: 'MIET', description: 'Meerut Institute of Engineering and Technology', image: 'https://via.placeholder.com/300' },
//     { id: 4, name: 'LPU', description: 'Lovely Professional University', image: 'https://via.placeholder.com/300' },
//     { id: 5, name: 'CU', description: 'Chandigarh University', image: 'https://via.placeholder.com/300' },
//     { id: 6, name: 'MIET', description: 'Meerut Institute of Engineering and Technology', image: 'https://via.placeholder.com/300' }
//   ];

//   const handleWishlistClick = (id) => {
//     const newWishlist = new Set(wishlist);
//     if (newWishlist.has(id)) {
//       newWishlist.delete(id);
//     } else {
//       newWishlist.add(id);
//     }
//     setWishlist(newWishlist);
//   };

//   return (
//     <>
//       <Header />
//       <div className="container mx-auto px-4 py-16 mt-20 flex flex-col lg:flex-row gap-6">
//         {/* Left Section - Filter Boxes */}
//         <div className="lg:w-1/4 space-y-6 pr-4 lg:pr-6 lg:ml-[-10%]"> {/* Added negative margin to shift left */}
//           {['Degree', 'Specialization', 'State', 'City'].map((filter, idx) => (
//             <div
//               key={idx}
//               className="p-4 border rounded-lg shadow-lg"
//               style={{ height: '340px', width: '240px' }} // Fixed width for filter boxes
//             >
//               <div className="flex justify-between items-center mb-4">
//                 <h3 className="font-dm-sans text-sm font-bold">{filter}</h3>
//                 <span className="font-dm-sans text-xs font-bold cursor-pointer">Clear All</span>
//               </div>
//               <input
//                 type="text"
//                 className="w-full h-10 p-2 border rounded mb-4"
//                 placeholder={`Search ${filter}`}
//               />
//               {/* List of checkboxes */}
//               <ul className="space-y-2">
//                 {['Lorem Ipsum 1', 'Lorem Ipsum 2', 'Lorem Ipsum 3', 'Lorem Ipsum 4', 'Lorem Ipsum 5', 'Lorem Ipsum 6'].map((item, idx) => (
//                   <li key={idx} className="flex items-center">
//                     <input type="checkbox" className="mr-2" />
//                     <span>{item}</span>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           ))}
//         </div>

//         {/* Right Section - University Cards */}
//         <div className="lg:w-3/4 flex flex-col lg:pl-12"> {/* Added padding to shift right section */}
//           {/* Heading Section */}
//           <div className="flex justify-center mb-8">
//             <h2 className="font-dm-sans text-4xl font-bold">All Universities in India</h2>
//           </div>

//           {/* Cards Section */}
//           <div className="flex flex-col items-center">
//             <div className="flex flex-col gap-8 w-full max-w-6xl"> {/* Increased max-width */}
//               {universities.map((university) => (
//                 <div
//                   key={university.id}
//                   className="relative border rounded-lg shadow-lg p-6 flex items-center"
//                   style={{ height: '260px', width: '100%' }} // Full width of container
//                 >
//                   {/* Image Section */}
//                   <div className="w-1/3">
//                     <img
//                       src={university.image}
//                       alt={university.name}
//                       className="rounded-tl-lg h-48 w-full object-cover"
//                     />
//                   </div>
//                   {/* University Info Section */}
//                   <div className="w-2/3 p-2">
//                     <h3 className="font-dm-sans text-2xl font-bold mb-2">
//                       {university.name}
//                     </h3>
//                     <p>{university.description}</p>
//                     <div className="mt-4">
//                       <button className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2">
//                         Apply Now
//                       </button>
//                       <button className="bg-green-500 text-white px-4 py-2 rounded-md">
//                         Get Free Counseling
//                       </button>
//                     </div>
//                   </div>
//                   {/* Wishlist Heart Icon */}
//                   <div className="absolute top-4 right-4">
//                     <button onClick={() => handleWishlistClick(university.id)}>
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className={`h-8 w-8 ${wishlist.has(university.id) ? 'text-red-500' : 'text-gray-400'}`}
//                         fill="none"
//                         viewBox="0 0 24 24"
//                         stroke="currentColor"
//                       >
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth={2}
//                           d="M5 15l7-7 7 7"
//                         />
//                       </svg>
//                     </button>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>

//           {/* Pagination Section */}
//           <div className="mt-8 flex justify-center">
//             {/* Add pagination logic here if needed */}
//             <span className="font-dm-sans text-lg">Page 1 of 10</span>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }

// export default LandingUniversity;
import React, { useState } from 'react';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import LPU1 from '../../../assets/landing/LPU1.png'
import locationIcon from '../../../assets/landing/locationIcon.png'
import RankIcon from '../../../assets/landing/RankIcon.png'
import ratingIcon from '../../../assets/landing/ratingIcon.png'
import univIcon from '../../../assets/landing/univIcon.png'
import Hat from '../../../assets/landing/Hat.png'
function LandingUniversity() {
  const [wishlist, setWishlist] = useState(new Set());

  // Sample array of universities
  const universities = [
    { id: 1, name: 'LPU', description: 'Lovely Professional University', image: LPU1},
    { id: 2, name: 'CU', description: 'Chandigarh University, Punjab', image: LPU1 },
    { id: 3, name: 'MIET', description: 'Meerut Institute of Engineering and Technology', image: LPU1},
    { id: 4, name: 'LPU', description: 'Lovely Professional University', image: LPU1 },
    { id: 5, name: 'CU', description: 'Chandigarh University', image: LPU1},
    { id: 6, name: 'MIET', description: 'Meerut Institute of Engineering and Technology', image: LPU1}
  ];

  // Dummy data for university info
  const infoRows = [
    { id: 1, img: RankIcon, text: 'NIRF Rank #46' },
    { id: 2, img: univIcon, text: 'Private University' },
    { id: 3, img: ratingIcon, text: 'View Reviews' },
    { id: 4, img: locationIcon, text: 'Punjab, India.' }
  ];

  const handleWishlistClick = (id) => {
    const newWishlist = new Set(wishlist);
    if (newWishlist.has(id)) {
      newWishlist.delete(id);
    } else {
      newWishlist.add(id);
    }
    setWishlist(newWishlist);
  };

  return (
    <>
      <Header />
      <div className="container mx-auto px-4 py-16 mt-20 flex flex-col lg:flex-row gap-6">
        {/* Left Section - Filter Boxes */}
        <div className="w-full lg:w-1/4 font-dm-sans  space-y-6 pr-4 lg:pr-6 lg:ml-[-10%]">
          {['Degree', 'Specialization', 'State', 'City'].map((filter, idx) => (
            <div
              key={idx}
              className="p-4 border rounded-lg shadow-lg"
              style={{ height: 'auto', maxWidth: '100%' }}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-dm-sans text-sm font-bold">{filter}</h3>
                <span className="font-dm-sans text-[12px] text-[#808080] font-[700] cursor-pointer">Clear All</span>
              </div>
              <input
                type="text"
                className="w-full h-10 p-2 border font-dm-sans text-[14px]  rounded mb-4"
                placeholder={'Search'}
              />
              <ul className="space-y-2">
                {['Lorem Ipsum 1', 'Lorem Ipsum 2', 'Lorem Ipsum 3', 'Lorem Ipsum 4', 'Lorem Ipsum 5', 'Lorem Ipsum 6'].map((item, idx) => (
                  <li key={idx} className="flex items-center text-[14px] font-dm-sans font-[700]">
                    <input type="checkbox" className="mr-2 " />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Right Section - University Cards */}
        <div className="w-full lg:w-3/4 flex flex-col gap-8 lg:pl-12">
          {/* Heading Section */}
          <div className="flex justify-center mb-8">
            <h2 className="font-dm-sans text-2xl lg:text-4xl font-bold">All Universities in India</h2>
          </div>

          {/* Cards Section */}
          <div className="flex flex-col items-center gap-8">
            <div className="flex flex-col gap-8 w-full max-w-full lg:max-w-6xl">
              {universities.map((university) => (
                <div
                  key={university.id}
                  className="relative border rounded-lg shadow-lg p-6 flex flex-col lg:flex-row items-center"
                  style={{
                    width: '100%',
                    boxShadow: '1px 2px 11px 4px #00000040', // Added box-shadow
                    padding: '20px', // Added padding for a neat look
                  }}
                >
                  {/* University Image Section */}
                  <div className="w-full lg:w-2/5 mb-4 lg:mb-0 flex justify-center">
                    <img
                      src={university.image}
                      alt={university.name}
                      style={{
                        width: '300px', // Increased width
                        height: '200px', // Increased height
                        objectFit: 'cover', // Ensure the image covers the area without distortion
                        borderRadius: '20px' // Optional: To match the rounded corners
                      }}
                    />
                  </div>

                  {/* University Info Section */}
                  <div className="w-full lg:w-2/3 flex flex-col justify-between p-4 relative">
                    <h3 className="font-dm-sans text-lg font-bold mb-2">
                      {university.name}
                    </h3>
                    <div className="w-full space-y-2">
                      {infoRows.map((row) => (
                        <div key={row.id} className="flex items-center">
                          <img src={row.img} alt="icon" className="mr-2" style={{ width: '15px', height: '15px' }} />
                          <span className="text-[14px] font-dm-sans font-[500] text-gray-600">{row.text}</span>
                        </div>
                      ))}
                    </div>

                    {/* Additional Info Section */}
                    <div className="absolute right-16 top-0 mt-6 w-[150px] h-[100px] p-2 border border-[#57CC0266] rounded-[20px] flex flex-col items-center justify-center">
                      {/* Image Section */}
                      <div className="w-[65px] h-[65px] mb-2">
                        <img
                          src={Hat}
                          alt=""
                          className="w-full h-full object-cover "
                        />
                      </div>

                      {/* Text Section */}
                      <div className="text-center">
                        <span className="font-dm-sans" style={{ fontSize: '19px', fontWeight: '700' }}>
                          35+ Courses
                        </span>
                      </div>
                    </div>

                    {/* Buttons Section */}
                    <div className="mt-6 flex gap-4">
                      <button
                        className="w-[180px] h-[45px] bg-[#3ACA2E] text-white font-dm-sans text-[14px] font-[700] rounded-[20px] flex items-center justify-center"
                      >
                        Apply Now
                      </button>
                      <button
                        className="w-[180px] h-[45px] bg-white border border-[#3ACA2E] text-black  text-[14px] font-dm-sans font-[700] rounded-[20px] flex items-center justify-center"
                      >
                        Get Free Counselling
                      </button>
                    </div>
                  </div>

                  {/* Wishlist Heart Icon */}
                  <div className="absolute top-4 right-4">
                    <button onClick={() => handleWishlistClick(university.id)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`h-4 w-4 ${wishlist.has(university.id) ? 'text-red-500' : 'text-gray-400'}`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Pagination Section */}
          {/* <div className="mt-8 flex justify-center">
            <span className="text-gray-600">Page 1 of 10</span>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LandingUniversity;
