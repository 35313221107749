import React,{useState} from 'react'
import * as Components from './Components'
import Google from '../../assets/Authentication/Google.png'
import Facebook from '../../assets/Authentication/Facebook.png'
import Apple from '../../assets/Authentication/Apple.png'
import ApplyButton from '../ApplyButton/ApplyButton'
import Vector1 from'../../assets/Authentication/Vector-1.png'
import Vector2 from'../../assets/Authentication/Vector-2.png'
import Vector3 from'../../assets/Authentication/Vector-3.png'
import Vector4 from'../../assets/Authentication/Vector-4.png'
import Vector5 from'../../assets/Authentication/Vector-5.png'
import Vector6 from'../../assets/Authentication/Vector-6.png'
import Portal from'../../assets/Authentication/portal.png'
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/slice/authSlice'; // Import loginUser action

const Authentication = () => {
  const [signIn, toggle] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth); // Get loading and error state from Redux store

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle form submission for login
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(formData)); // Dispatch loginUser action with form data
  };

  // Utility to get button text
  const getButtonTitle = () => (loading ? 'Signing In...' : 'Sign In');

  return (
    <>
      <Components.Container>
        {/* Sign Up Container */}
        <Components.SignUpContainer signinIn={signIn}>
          <Components.Form onSubmit={handleLoginSubmit}>
            <div className="flex flex-col w-full">
              <div className="flex flex-col items-start mb-2">
                <Components.LeftTitle>Sign Up</Components.LeftTitle>
              </div>

              <div className="w-full flex flex-col justify-center items-center gap-y-1">
                <div className="w-full flex flex-col items-start">
                  <Components.Label>User Name</Components.Label>
                  <Components.Input placeholder="Enter Your User Name" />
                </div>
                <div className="w-full flex flex-col items-start">
                  <Components.Label>Mobile Number</Components.Label>
                  <Components.Input placeholder="Enter Your Mobile Number" />
                </div>
                <div className="w-full flex flex-col items-start">
                  <Components.Label>Email</Components.Label>
                  <Components.Input placeholder="Enter Your Email" name="email" value={formData.email} onChange={handleInputChange} />
                </div>
                <div className="w-full flex flex-col items-start">
                  <Components.Label>Password</Components.Label>
                  <Components.Input placeholder="Enter Your Password" name="password" type="password" value={formData.password} onChange={handleInputChange} />
                </div>

                <div className="w-full flex flex-col items-start">
                  <Components.Label>Confirm Password</Components.Label>
                  <Components.Input placeholder="Enter Your Password" />
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <ApplyButton title={'Sign Up'} background={'#AFFE79'} />
                <div className="flex items-center px-2">
                  <hr className="flex-grow border-t border-gray" />
                  <span className="mx-2 text-xs">or Login with</span>
                  <hr className="flex-grow border-t border-gray" />
                </div>
                <div className="social-login flex justify-center space-x-4 my-1">
                  <button className="social-btn">
                    <img src={Google} width={20} height={20} alt="Google Login" />
                  </button>
                  <button className="social-btn">
                    <img src={Facebook} width={20} height={20} alt="Facebook Login" />
                  </button>
                  <button className="social-btn">
                    <img src={Apple} width={20} height={20} alt="Apple Login" />
                  </button>
                </div>
                <div className="flex items-center justify-center">
                  <span className="mx-2 text-xs">Already have an account?<span className="underline hover:opacity-75 hover:cursor-pointer" onClick={() => toggle(true)}>Login</span></span>
                </div>
              </div>
            </div>
          </Components.Form>
        </Components.SignUpContainer>

        {/* Sign In Container */}
        <Components.SignInContainer signinIn={signIn}>
          <Components.Form onSubmit={handleLoginSubmit}>
            <div className="flex flex-col gap-y-6 w-full" style={{ zIndex: 15, alignItems: 'flex-start' }}>
              <div className="flex flex-col items-start gap-y-3">
                <Components.LeftTitle>Login</Components.LeftTitle>
                <Components.subTitle>Enter your account details</Components.subTitle>
              </div>
              <div className="flex flex-col w-full gap-y-2.5">
                <div className="w-full flex flex-col items-start">
                  <Components.Label>Email</Components.Label>
                  <Components.Input placeholder="Enter Your Email" name="email" value={formData.email} onChange={handleInputChange} />
                </div>
                <div className="w-full flex flex-col items-start">
                  <Components.Label>Password</Components.Label>
                  <Components.Input placeholder="Enter Your Password" name="password" type="password" value={formData.password} onChange={handleInputChange} />
                </div>

                {/* Show error message if login fails */}
                {error && <p style={{ color: 'red' }}>{error}</p>}

                <div className="w-full flex flex-col items-start">
                  <Components.Anchor>Forgot Password?</Components.Anchor>
                </div>
              </div>
            </div>

            <ApplyButton title={getButtonTitle()} background="#AFFE79" />

            <div className="flex items-center px-2">
              <hr className="flex-grow border-t border-gray" />
              <span className="mx-2 text-xs">or Login with</span>
              <hr className="flex-grow border-t border-gray" />
            </div>

            <div className="social-login flex justify-center space-x-4 my-4">
              <button className="social-btn">
                <img src={Google} width={20} height={20} alt="Google Login" />
              </button>
              <button className="social-btn">
                <img src={Facebook} width={20} height={20} alt="Facebook Login" />
              </button>
              <button className="social-btn">
                <img src={Apple} width={20} height={20} alt="Apple Login" />
              </button>
            </div>

            <div className="flex items-center my-3 px-2">
              <span className="mx-2 text-xs">Don't have an account?<span className="underline hover:opacity-75 hover:cursor-pointer" onClick={() => toggle(false)}>SignUp</span></span>
            </div>
          </Components.Form>
        </Components.SignInContainer>

        {/* Overlay Container */}
        <Components.OverlayContainer signinIn={signIn}>
          <Components.Overlay signinIn={signIn}>
            <Components.LeftOverlayPanel signinIn={signIn}>
              <div className="flex flex-col mb-10" style={{ zIndex: 15, alignItems: 'flex-start' }}>
                <Components.Title>Welcome to</Components.Title>
                <Components.Paragraph>Student Portal</Components.Paragraph>
              </div>
              <div style={{ zIndex: 10 }}>
                <img className="portal" src={Portal} width={'100%'} height={'100%'} alt="Portal" />
              </div>
              <div><img className="vector1" src={Vector1} width={'100%'} height={'100%'} alt="Vector 1" /></div>
              <div><img className="vector2" src={Vector2} width={'100%'} height={'100%'} alt="Vector 2" /></div>
              <div><img className="vector6" src={Vector6} width={'100%'} height={'100%'} alt="Vector 6" /></div>
              <div><img className="vector3" src={Vector3} width={'100%'} height={'100%'} alt="Vector 3" /></div>
              <div><img className="vector4" src={Vector4} width={'100%'} height={'100%'} alt="Vector 4" /></div>
              <div style={{ zIndex: 8 }}><img className="vector5" src={Vector5} width={'100%'} height={'100%'} alt="Vector 5" /></div>
            </Components.LeftOverlayPanel>

            <Components.RightOverlayPanel signinIn={signIn}>
              <div className="flex flex-col mb-10" style={{ zIndex: 15, alignItems: 'flex-start' }}>
                <Components.Title>Welcome to</Components.Title>
                <Components.Paragraph>Student Portal</Components.Paragraph>
              </div>
              <div style={{ zIndex: 10 }}>
                <img className="portal" src={Portal} width={'100%'} height={'100%'} alt="Portal" />
              </div>
              <div><img className="vector1" src={Vector1} width={'100%'} height={'100%'} alt="Vector 1" /></div>
              <div><img className="vector2" src={Vector2} width={'100%'} height={'100%'} alt="Vector 2" /></div>
              <div><img className="vector6" src={Vector6} width={'100%'} height={'100%'} alt="Vector 6" /></div>
              <div><img className="vector3" src={Vector3} width={'100%'} height={'100%'} alt="Vector 3" /></div>
              <div><img className="vector4" src={Vector4} width={'100%'} height={'100%'} alt="Vector 4" /></div>
              <div style={{ zIndex: 8 }}><img className="vector5" src={Vector5} width={'100%'} height={'100%'} alt="Vector 5" /></div>
            </Components.RightOverlayPanel>
          </Components.Overlay>
        </Components.OverlayContainer>
      </Components.Container>
    </>
  );
};

export default Authentication;
