import React from 'react'
import college_logo from '../../../assets/college_logo.png'
import Rankings from './Rankings/Rankings'

const Overview =() =>{
    return(
        <div>
        <div className="bg-[#DCFFC4] px-5 py-10 w-full overflow-x-hidden">
        <div className="flex flex-col items-center justify-center gap-3 px-5 py-2 md:flex-row">
          <div className="md:h-[80px] md:w-[80px] h-[50px] w-[50px] flex-shrink-0">
            <img src={college_logo} alt="college-logo" className="h-full w-full object-cover" />
          </div>
          <p className="text-[24px] text-black font-semibold text-center w-full md:w-auto md:text-left font-bold md:text-3xl">
            LPU: Lovely Professional University
          </p>
        </div>
        
        <div className="py-5 text-black md:text-base text-left font-medium flex flex-col gap-5 w-full">
          <p>
            Lovely Professional University (LPU) was established in 2005 in Jalandhar, Punjab. It is a private university accredited by NAAC with an "A++" grade and approved by UGC, NCTE, PCI, COA, BCI, among other prominent organizations. LPU ranks 27th in the NIRF 2024 ranking among all private and government universities in India. Additionally, LPU is one of India’s oldest private universities, boasting a campus that spans 600 acres.
          </p>
          <p>
            LPU offers more than 150 programs across over 300 disciplines, with B.Tech and MBA among its most popular courses. In the NIRF 2023 rankings, LPU was ranked 50th for B.Tech and 32nd for MBA. The fee structure for B.Tech ranges from INR 11.20 Lakhs to INR 16 Lakhs, while MBA fees range from INR 8 Lakhs to INR 10 Lakhs for two years. Admission to LPU is based on merit in the LPUNEST (Lovely Professional University National Entrance and Scholarship Test), except for the UG Hotel Management programs and MPharm (Ayurveda). Candidates with scores from national exams like CUET, CAT, JEE Mains, NATA, MAT, etc., are also eligible for admission.
          </p>
        </div>
      </div>
      

            {/* Rankings */}
            <Rankings />

           {/* Detailed Description */}
<div className="py-10 px-5 bg-[#DCFFC4] text-black text-base font-medium flex flex-col gap-5">
    <h1 className='font-bold text-lg sm:text-xl'>LPU Admissions 2024</h1>
    <p>
        Candidates seeking admission to LPU are selected based on eligibility for the particular course followed by the score of LPUNEST or any other national-level examination accepted by the university. LPU Admit is the admission portal of Lovely Professional University where students can check their application status. Lovely Professional University Course Admission is open for various UG, PG, Online, Distance Education and Ph.D. courses. 
    </p>
    <h1 className='font-bold text-lg sm:text-xl'>What's New At LPU?</h1>
    <p>
        LPU registration is held in online mode only. Students must visit the official website <a href='https://admission.lpu.in/'>@https://admission.lpu.in/</a> to register for admission. Once the candidate has registered successfully, he or she must log in using the application number/ registered Email ID / registered Mobile number and password sent via Email or SMS. Post that, students have to fill out the application form. The application form can be filled out online or offline.
    </p>
    <h1 className='font-bold text-lg sm:text-xl'>LPU NEST 2024</h1>
    <p>
        LPUNEST Cutoff 2024 is the minimum mark that must be scored by the applicants to secure admission at Lovely Professional University. LPU cutoff is determined through various factors such as the Number of Applicants, Number of Vacant Seats, Average Performance in the Entrance Test and Previous Years’ Cutoff.
    </p>
    <p>
        Students applying for LPUNEST scholarships must qualify for one of the three categories as mentioned below:
        <ul className='list-disc pl-5'>
            <li>LPUNEST Category I Cutoff: 95% Marks or Above</li>
            <li>LPUNEST Category II Cutoff: 90% to 94.9% Marks</li>
            <li>LPUNEST Category III Cutoff: 80% to 89.9% Marks</li>
        </ul>
    </p>
    <p>
        Lovely Professional University conducts its counselling process in online mode for each phase of LPUNEST. Seats are allocated separately for each round of counselling. Candidates who will qualify for LPUNEST 2024 and score more than the required cut-off are called for counselling and seat allocation.
    </p>
</div>
</div>
    )
}

export default Overview;