import React from 'react';
import CourseCard from './CourseCard/CourseCard';

const Courses = () => {
  // Dummy data for courses using all the fields from the programs
  const coursesData = [
    {
      program_name: "Bachelor of Technology",
      program_fees: 2000000,
      program_duration: "4 Years",
      study_mode: "Full-time",
      exams_accepted: ["JEE Main", "State Engineering Exams"],
      eligibility: "10+2 with PCM",
      course_offered: ["Computer Science", "Mechanical Engineering", "Civil Engineering"],
    },
    {
      program_name: "Master of Business Administration",
      program_fees: 3400000,
      program_duration: "2 Years",
      study_mode: "Full-time",
      exams_accepted: ["CAT", "GMAT", "MAT"],
      eligibility: "Bachelor's Degree in any field",
      course_offered: ["Marketing", "Finance", "Human Resources", "Operations"],
    },
    {
      program_name: "Bachelor of Science in Computer Science",
      program_fees: 1500000,
      program_duration: "3 Years",
      study_mode: "Full-time",
      exams_accepted: ["University Entrance Exam"],
      eligibility: "10+2 with Math",
      course_offered: ["Data Science", "Artificial Intelligence", "Cybersecurity"],
    },
  ];

  return (
    <div className="bg-[#DCFFC4] px-5">
    {/* Course Section */}
    <div className="py-8 flex flex-col items-center">
      <p className="text-center text-lg text-black font-semibold mb-8">LPU Courses, Fees and Eligibility Criteria 2024</p>
      <p className='text-center mb-8'>
        LPU offers various academic programmes at diploma, undergraduate, postgraduate, and doctoral levels. Admission to these programs is facilitated through various entrance tests conducted by the university, namely LPUNEST, LPUPET, and LPUTABS. As per the LPU admission process, LPUNEST serves as a mandatory test for admission into B.Tech, M.Tech, MBA, Law, design (UG and PG), integrated BBA MBA, B.Pharm, B.Sc agriculture, B.Sc (Hons), M.Sc (Hons), BCA, and MCA courses. For other programmes, LPUNEST functions as a scholarship test. For admission to BTech courses, candidates need to qualify for JEE Main/LPUNEST/CUET. Interested candidates can apply for LPU admission online and should be aware of the specific admission dates for 2024, as listed below.
      </p>
  
      {/* Centered Grid Container */}
      <div className="flex-grow flex items-center justify-center w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {coursesData.map((course, index) => (
            <CourseCard
              key={index}
              program_name={course.program_name}
              program_fees={course.program_fees}
              program_duration={course.program_duration}
              study_mode={course.study_mode}
              eligibility={course.eligibility}
              exams_accepted={course.exams_accepted}
              course_offered={course.course_offered}
            />
          ))}
        </div>
      </div>
    </div>
  
    {/* Search Section */}
    <div className="py-8 flex flex-col items-center">
      <p className="text-center text-lg text-black font-semibold mb-5">Search Courses</p>
  
      {/* Flex Container for Select Inputs */}
      <div className="flex flex-col sm:flex-row gap-5 mb-5 max-w-4xl w-full mx-auto">
        <div className="flex flex-col gap-1 flex-grow">
          <label htmlFor="degree" className="text-black text-xs font-semibold">Degree</label>
          <select id="degree" name="option" className="rounded-md border border-gray-300 p-2 w-full">
            <option value="">--Select Degree--</option>
            <option value="B.Tech">B.Tech</option>
            <option value="MBA">MBA</option>
            <option value="B.Sc.">B.Sc.</option>
          </select>
        </div>
        <div className="flex flex-col gap-1 flex-grow">
          <label htmlFor="discipline" className="text-black text-xs font-semibold">Discipline</label>
          <select id="discipline" name="option" className="rounded-md border border-gray-300 p-2 w-full">
            <option value="">--Select Discipline--</option>
            <option value="Engineering">Engineering</option>
            <option value="Business">Business</option>
            <option value="Science">Science</option>
          </select>
        </div>
        <div className="flex flex-col gap-1 flex-grow">
          <label htmlFor="department" className="text-black text-xs font-semibold">Department</label>
          <select id="department" name="option" className="rounded-md border border-gray-300 p-2 w-full">
            <option value="">--Select Department--</option>
            <option value="Computer Science">Computer Science</option>
            <option value="Mechanical Engineering">Mechanical Engineering</option>
            <option value="Finance">Finance</option>
          </select>
        </div>
        <div className="flex flex-col gap-1 flex-grow">
          <label htmlFor="specialization" className="text-black text-xs font-semibold">Specialization</label>
          <select id="specialization" name="option" className="rounded-md border border-gray-300 p-2 w-full">
            <option value="">--Select Specialization--</option>
            <option value="Data Science">Data Science</option>
            <option value="Marketing">Marketing</option>
            <option value="Cybersecurity">Cybersecurity</option>
          </select>
        </div>
      </div>
  
      {/* Search Button */}
      <div className="py-3 flex justify-center w-full">
        <button className="py-2 px-5 text-white bg-[#3ACA2E] text-sm rounded-md">SEARCH</button>
      </div>
    </div>
  </div>
  
  );
};

export default Courses;
