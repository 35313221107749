import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const EditEvent = () => {
  const {  id} = useParams();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    event_name: '',
    event_date: '',
    event_time: '',
    event_location: '',
    event_details: '',
    event_organizer: '1', // This should match the current university or be fetched if required
  });
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(`${Base_URL}/event/${id}`);
        const event = response.data;
        const formattedDate = new Date(event.event_date).toISOString().split('T')[0];
        setFormData({
          event_name: event.event_name,
          event_date: formattedDate,
          event_time: event.event_time,
          event_location: event.event_location,
          event_details: event.event_details,
          event_organizer: 1,
        });
      } catch (error) {
        console.error('Failed to fetch event data:', error);
        navigate('/dashboard/events/all'); // Redirect to events list on error
      }
    };

    fetchEventData();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImageName(file ? file.name : 'No file chosen');
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const data = new FormData();
  //     for (const key in formData) {
  //       data.append(key, formData[key]);
  //     }
  //     if (image) {
  //       data.append('picture', image);
  //     }
  
  //     //const token = localStorage.getItem('authToken');
  //     const response = await axios.put(`${Base_URL}/event/${id}`, data, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  
  //     console.log('Event data updated:', response.data);
  //     alert('Event updated successfully!');
  //     navigate('/dashboard/events/all');
  //   } catch (error) {
  //     console.error('Failed to update event:', error.response?.data || error.message);
  //     setError('Failed to update event. Please try again.');
  //   }
  // };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      if (image) {
        data.append('event_picture', image); // Using 'event_picture' as the JSON key
      }
  
      const response = await axios.put(`${Base_URL}/event/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Event data updated:', response.data);
      // toast.success('Event updated successfully!');
      navigate('/dashboard/events/all');
    } catch (error) {
      console.error('Failed to update event:', error.response?.data || error.message);
     toast.error('Failed to update event. Please try again.');
    }
  };
  
  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md font-poppins">
      <HeaderSection
        title="Edit Event"
        breadcrumb={['Event', 'Edit Event']}
      />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        {/* Basic Info Header */}
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[rgb(136,136,136)] text-[17.25px] font-[400] font-poppins">Event Details</h2>
        </div>

        {error && <div className="text-red-600 mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Form Fields */}
            <div className="flex flex-col">
              <label
                htmlFor="event_name"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Event Name
              </label>
              <input
                type="text"
                id="event_name"
                name="event_name"
                value={formData.event_name}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="event_date"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Event Date
              </label>
              <input
                type="date"
                id="event_date"
                name="event_date"
                value={formData.event_date}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="event_time"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Event Time
              </label>
              <input
                type="time"
                id="event_time"
                name="event_time"
                value={formData.event_time}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="event_location"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Event Location
              </label>
              <input
                type="text"
                id="event_location"
                name="event_location"
                value={formData.event_location}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col md:col-span-2">
              <label
                htmlFor="event_details"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Event Details
              </label>
              <textarea
                id="event_details"
                name="event_details"
                value={formData.event_details}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="profile_picture"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[100] mb-[6px]">
                Upload Profile Picture
              </label>
              <div className="flex border border-gray-300 rounded-lg overflow-hidden">
                <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer font-poppins">
                  <span className='text-[12.25px]'>Choose File</span>
                  <input
                    type="file"
                    id="profile_picture"
                    name="profile_picture"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </label>
                <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center font-poppins text-[12.25px]">
                  {imageName}
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-[rgb(106,115,250)] text-white rounded-md font-poppins">
            Submit
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditEvent;

