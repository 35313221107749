import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';
import { Base_URL } from '../../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const universities = [
  { id: 1, name: 'LPU University' },
  { id: 2, name: 'Chandigarh University' },
  { id: 3, name: 'Graphic Era University' },
  { id: 4, name: 'Tula University' },
  { id: 5, name: 'Miet University' },
  
];

const EditAmbassador = () => {
  const { id } = useParams(); // Get the ambassador ID from the URL
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    university_id:1,
    registration_typeid:3,
    name: '',
    subject: '',
    introduction: '',
    password:'',
    email: '',
    status: 1, // Default to active (1)
  });
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');
  const [universityId, setUniversityId] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (id) {
      const fetchAmbassadorDetails = async () => {
        try {
          const response = await axios.get(`${Base_URL}/ambassadors/${id}`);
          setFormData({
            name: response.data.name,
            subject: response.data.subject,
            introduction: response.data.introduction,
            email: response.data.email,
            status: response.data.status === 1 ? 1 : 0, // Map the status to 1 or 0
          });
          setImage(response.data.profile_picture || '/path/to/default/image.jpg'); // Handle image fallback
          setPassword(response.data.password || '');
        } catch (error) {
          console.error('Error fetching ambassador details:', error);
          setError('Failed to fetch ambassador details.');
        }
      };

      fetchAmbassadorDetails();
    }
  }, [id]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: name === "status" ? parseInt(value, 10) : value, });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageName(file.name);
    }
  };


const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('subject', formData.subject);
    formDataToSubmit.append('introduction', formData.introduction);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('status', formData.status);
    formDataToSubmit.append('university_id', 1)
    formDataToSubmit.append('registration_typeid', 3)
    if (password) {
      formDataToSubmit.append('password', password); // Include the password
    }
    if (image) {
      formDataToSubmit.append('profile_picture', image);
    }

    const url = `${Base_URL}/ambassadors/${id}`; // Ensure id is properly defined

    // Store response in a variable
    const response = await axios.put(url, formDataToSubmit, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Pass updated data to AllAmbassadors via navigation state
    navigate('/dashboard/ambassadors/all', {
      state: { updatedAmbassador: response.data },
    });
}
   catch (error) {
    console.error('Error updating ambassadors:', error.response ? error.response.data : error.message);
    toast.error('Failed to update ambassador details.');
  }
};


  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <HeaderSection
        title="Edit Ambassador"
        breadcrumb={['Ambassador', 'Edit Ambassador']}
      />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[#312a2a] text-[17.25px] font-[500] font-poppins">Basic Info</h2>
        </div>

        {error && <div className="text-red-600 mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4  ">
            <div className="flex flex-col">
              <label
                htmlFor="name"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            {/* <div className="flex flex-col">
              <label
                htmlFor="university_id"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                University
              </label>
              <select
                id="university_id"
                name="university_id"
                // value={universityId}
                onChange={(e) => setUniversityId(e.target.value)}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                
              >
                <option value="">Select University</option>
                {universities.map((university) => (
                  <option key={university.id} value={university.id}>
                    {university.name}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="flex flex-col">
              <label
                htmlFor="subject"
                className=" cursor-default inline-block font-poppins text-[#312a2a]  text-[0.875rem] font-[500] mb-[7px]">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="introduction"
                className=" text-[#312a2a]  text-[0.875rem] cursor-default inline-block font-poppins  font-[500] mb-[7px]">
                Introduction
              </label>
              <textarea
                id="introduction"
                name="introduction"
                value={formData.introduction}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                rows="4"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="text-[#312a2a]  text-[0.875rem] cursor-default inline-block font-poppins font-[500] mb-[7px]">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            {/* <div className="flex flex-col">
              <label
                htmlFor="password"
                className="text-[#312a2a]  text-[0.875rem] cursor-default inline-block font-poppins  font-[500] mb-[7px]">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div> */}
            <div className="flex flex-col">
              <label
                htmlFor="status"
                className="text-[#312a2a]  text-[0.875rem] cursor-default inline-block font-poppins font-[500] mb-[7px]">
                Status
              </label>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              >
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="profile_picture"
                className="text-[#312a2a]  text-[0.875rem] cursor-default inline-block font-poppins  font-[500] mb-[6px]">
                Upload Profile Picture
              </label>
              <div className="flex border border-gray-300 rounded-lg overflow-hidden">
                <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer font-poppins">
                  <span className='text-[12.25px]'>Choose File</span>
                  <input
                    type="file"
                    id="profile_picture"
                    name="profile_picture"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </label>
                <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center font-poppins text-[12.25px]">
                  {imageName || 'No file chosen'}
                </div>
              </div>
            </div>
            </div>

          <div className="mt-6">
            <button
              type="submit"
              className=" text-white px-4 py-2 bg-customblue rounded text-sm hover:bg-[rgb(82,89,199)]">
              Submit
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditAmbassador;
