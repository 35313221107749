import React, { useState } from "react";

const Survey = () => {
  const [formData, setFormData] = useState({
    full_name: "", // matches full_name column in the DB
    contact_number: "", // matches contact_number column in the DB
    stream_12th: "", // matches stream_12th column in the DB
    field_of_interest: [], // matches field_of_interest column in the DB
    financial_budget: "", // matches financial_budget column in the DB
    city_preference: [], // matches city_preference column in the DB
    counseling_support: "", // matches counseling_support column in the DB
    referral_contact: "" // matches referral_contact column in the DB
  });

  const [status, setStatus] = useState(""); // To track success or error status

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked
          ? [...prevState[name], value]
          : prevState[name].filter((item) => item !== value)
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://213.210.37.39:3000/api/create/student_survey', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Send the formData with correct DB columns
      });

      if (response.ok) {
        const result = await response.json();
        setStatus("Form submitted successfully!");
        console.log(result); // This will log the result if needed
      } else {
        setStatus("Form submission failed.");
      }
    } catch (error) {
      console.error("Error submitting the form: ", error);
      setStatus("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-lg mx-auto">
        <h2 className="text-2xl font-bold text-center mb-6">"Your Journey, Our Guidance: From Admission to Career Success with College Dwarka!"

</h2>
<p>At College Dwarka, we help you find the right college and guide you throughout your college years. We provide advice on your career, help you get internships, and support you in landing your desired job. Everything you need for your college and career success is available in one place!
   Fill out this survey to let us know how we can best assist you on your journey.</p><br/>
        <form onSubmit={handleSubmit}>
          {/* Full Name */}
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Full Name:
            </label>
            <input
              type="text"
              name="full_name" // matches the database column name
              value={formData.full_name}
              onChange={handleChange}
              required
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
          </div>

          {/* Contact Number */}
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Contact Number:
            </label>
            <input
              type="text"
              name="contact_number" // matches the database column name
              value={formData.contact_number}
              onChange={handleChange}
              required
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
          </div>

          {/* Stream Studied in 12th Grade */}
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Stream Studied in 12th Grade:
            </label>
            <select
              name="stream_12th" // matches the database column name
              value={formData.stream_12th}
              onChange={handleChange}
              required
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-400"
            >
              <option value="">Select</option>
              <option value="Science (Medical)">Science (Medical)</option>
              <option value="Science (Non-Medical)">Science (Non-Medical)</option>
              <option value="Commerce">Commerce</option>
              <option value="Arts/Humanities">Arts/Humanities</option>
            </select>
          </div>

          {/* Field of Interest */}
          <fieldset className="mb-4">
            <legend className="block text-gray-700 font-semibold mb-2">
              Field of Interest:
            </legend>
            {[
              "Engineering (B.Tech)",
              "Medical (MBBS, BDS, etc.)",
              "Commerce (B.Com, BBA)",
              "Science (B.Sc)",
              "Arts/Humanities",
              "Law (LLB)",
              "Management (MBA)",
              "Computer Applications (BCA, MCA)",
              "B.Pharma, M.Pharma, B-Architecture, Hotel Management",
              "Others (Please Specify)"
            ].map((field) => (
              <label key={field} className="block mb-1">
                <input
                  type="checkbox"
                  name="field_of_interest" // matches the database column name
                  value={field}
                  onChange={handleChange}
                  className="mr-2"
                />
                {field}
              </label>
            ))}
          </fieldset>

          {/* Financial Considerations */}
          <fieldset className="mb-4">
            <legend className="block text-gray-700 font-semibold mb-2">
              Financial Considerations:
            </legend>
            {["Below ₹50,000", "₹50,000 - ₹1,00,000", "₹1,00,000 - ₹2,00,000", "Above ₹2,00,000"].map(
              (budget) => (
                <label key={budget} className="block mb-1">
                  <input
                    type="radio"
                    name="financial_budget" // matches the database column name
                    value={budget}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  {budget}
                </label>
              )
            )}
          </fieldset>

          {/* College Preferences */}
          <fieldset className="mb-4">
            <legend className="block text-gray-700 font-semibold mb-2">
              College Preferences:
            </legend>
            {[
              "Chandigarh",
              "Dehradun",
              "Delhi",
              "Mumbai",
              "Bangalore",
              "Kolkata",
              "Hyderabad",
              "Any other"
            ].map((city) => (
              <label key={city} className="block mb-1">
                <input
                  type="checkbox"
                  name="city_preference" // matches the database column name
                  value={city}
                  onChange={handleChange}
                  className="mr-2"
                />
                {city}
              </label>
            ))}
          </fieldset>

          {/* Counseling Support */}
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Would you like to receive counseling or guidance from our team?
            </label>
            <select
              name="counseling_support" // matches the database column name
              value={formData.counseling_support}
              onChange={handleChange}
              required
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-400"
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          {/* Referral Contact */}
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Referral Contact:
            </label>
            <input
              type="text"
              name="referral_contact" // matches the database column name
              value={formData.referral_contact}
              onChange={handleChange}
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-indigo-500 text-white px-6 py-3 rounded-lg hover:bg-indigo-600 transition-colors duration-300"
            >
              Submit
            </button>
          </div>

          {/* Submission Status */}
          {status && (
            <div className="mt-4 text-center text-red-500">
              {status}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Survey;
