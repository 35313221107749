// import React, { useState } from 'react';
// import axios from 'axios';
// import { Base_URL } from '../../../apiConfig';
// import HeaderSection from '../headerSection/HeaderSection';

// const AddEvent = ({ universityId = 1 }) => {
//   const [formData, setFormData] = useState({
//     event_name: '',
//     event_date: '',
//     event_time: '',
//     event_location: '',
//     event_details: '',
//     event_organizer: universityId,
//   });
//   const [error, setError] = useState(null);
//   const [image, setImage] = useState(null);
//   const [imageName, setImageName] = useState('No file chosen');

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     setImage(file);
//     setImageName(file ? file.name : 'No file chosen');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const data = new FormData();
//       for (const key in formData) {
//         data.append(key, formData[key]);
//       }
//       if (image) {
//         data.append('picture', image);
//       }

//       const response = await axios.post(`${Base_URL}/event`, data, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       console.log('Event data response:', response.data);
//       alert('Event created successfully!');
//       setFormData({
//         event_name: '',
//         event_date: '',
//         event_time: '',
//         event_location: '',
//         event_details: '',
//         event_organizer: universityId,
//       });
//       setImage(null);
//       setImageName('No file chosen');
//       setError(null);
//     } catch (error) {
//       console.error('Failed to create event:', error.response?.data || error.message);
//       setError('Failed to create event. Please try again.');
//     }
//   };

//   return (
//     <div className="bg-gray-100 p-6 rounded-lg shadow-md font-poppins">
//       <HeaderSection
//         title="Add Event"
//         breadcrumb={['Event', 'Add Event']}
//       />
//       <div className="border border-gray-300 bg-white p-4 rounded-md">
//         {/* Basic Info Header */}
//         <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
//           <h2 className="text-[#312a2a] text-[17.25px] font-[500] font-poppins">Event Details</h2>
//         </div>

//         {error && <div className="text-red-600 mb-4">{error}</div>}

//         <form onSubmit={handleSubmit}>
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4 font-poppins">
//             {/* Form Fields */}
//             <div className="flex flex-col">
//               <label
//                 htmlFor="event_name"
//                 className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
//                 Event Name
//               </label>
//               <input
//                 type="text"
//                 id="event_name"
//                 name="event_name"
//                 value={formData.event_name}
//                 onChange={handleChange}
//                 className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
//                 required
//               />
//             </div>
//             <div className="flex flex-col">
//               <label
//                 htmlFor="event_date"
//                 className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
//                 Event Date
//               </label>
//               <input
//                 type="date"
//                 id="event_date"
//                 name="event_date"
//                 value={formData.event_date}
//                 onChange={handleChange}
//                 className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
//                 required
//               />
//             </div>
//             <div className="flex flex-col">
//               <label
//                 htmlFor="event_time"
//                 className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
//                 Event Time
//               </label>
//               <input
//                 type="time"
//                 id="event_time"
//                 name="event_time"
//                 value={formData.event_time}
//                 onChange={handleChange}
//                 className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
//                 required
//               />
//             </div>
//             <div className="flex flex-col">
//               <label
//                 htmlFor="event_location"
//                 className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
//                 Event Location
//               </label>
//               <input
//                 type="text"
//                 id="event_location"
//                 name="event_location"
//                 value={formData.event_location}
//                 onChange={handleChange}
//                 className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
//                 required
//               />
//             </div>
//             <div className="flex flex-col md:col-span-2">
//               <label
//                 htmlFor="event_details"
//                 className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
//                 Event Details
//               </label>
//               <textarea
//                 id="event_details"
//                 name="event_details"
//                 value={formData.event_details}
//                 onChange={handleChange}
//                 className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
//                 required
//               />
//             </div>
//             <div className="flex flex-col">
//               <label
//                 htmlFor="profile_picture"
//                 className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[6px]">
//                 Upload Profile Picture
//               </label>
//               <div className="flex border border-gray-300 rounded-lg overflow-hidden">
//                 <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer font-poppins">
//                   <span className='text-[12.25px]'>Choose File</span>
//                   <input
//                     type="file"
//                     id="profile_picture"
//                     name="profile_picture"
//                     onChange={handleImageChange}
//                     className="hidden"
//                   />
//                 </label>
//                 <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center font-poppins text-[12.25px]">
//                   {imageName}
//                 </div>
//               </div>
//             </div>
//           </div>
//           <button
//             type="submit"
//             className="mt-4 px-4 py-2 bg-customblue hover:bg-[rgb(82,89,199)] text-white rounded-md font-poppins">
//             Submit
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default AddEvent;


//FOR TEMPORARY PURPOSE

import React, { useState } from 'react';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
const AddEvent = ({ university_id = 1 }) => {
  const [formData, setFormData] = useState({
    event_name: '',
    event_date: '',
    event_time: '',
    event_location: '',
    event_details: '',
    event_organizer: university_id,
  });
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImageName(file ? file.name : 'No file chosen');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   

    try {
      const data = new FormData();
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      if (image) {
        data.append('event_picture', image); // Using 'event_picture' as the JSON key
      }
  
      const response = await axios.post(`${Base_URL}/event`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      const id = response.data.id; // Assuming the ID is returned in the response
      console.log('Event created with ID:', id);
      toast.success('Events created successfully!');
  
      // Optionally, redirect or update state
      // navigate(`/dashboard/events/edit/${eventId}`);
  
      // Clear form data
      setFormData({
        event_name: '',
        event_date: '',
        event_time: '',
        event_location: '',
        event_details: '',
        event_organizer: university_id,
      });
      setImage(null);
      setImageName('No file chosen');
    } catch (error) {
      console.error('Failed to create event:', error.response?.data || error.message);
      toast.error('Cannot add events')
    }
  };
  

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md font-poppins">
      <HeaderSection
        title="Add Event"
        breadcrumb={['Event', 'Add Event']}
      />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        {/* Basic Info Header */}
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[#312a2a] text-[17.25px] font-[500] font-poppins">Event Details</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 font-poppins">
            {/* Form Fields */}
            <div className="flex flex-col">
              <label
                htmlFor="event_name"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Event Name
              </label>
              <input
                type="text"
                id="event_name"
                name="event_name"
                value={formData.event_name}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="event_date"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Event Date
              </label>
              <input
                type="date"
                id="event_date"
                name="event_date"
                value={formData.event_date}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="event_time"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Event Time
              </label>
              <input
                type="time"
                id="event_time"
                name="event_time"
                value={formData.event_time}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="event_location"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Event Location
              </label>
              <input
                type="text"
                id="event_location"
                name="event_location"
                value={formData.event_location}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col md:col-span-2">
              <label
                htmlFor="event_details"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Event Details
              </label>
              <textarea
                id="event_details"
                name="event_details"
                value={formData.event_details}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="event_picture"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[6px]">
                Upload Event Picture
              </label>
              <div className="flex border border-gray-300 rounded-lg overflow-hidden">
                <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer font-poppins">
                  <span className='text-[12.25px]'>Choose File</span>
                  <input
                    type="file"
                    id="event_picture"
                    name="event_picture"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </label>
                <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center font-poppins text-[12.25px]">
                  {imageName}
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-customblue hover:bg-[rgb(82,89,199)] text-white rounded-md font-poppins">
            Submit
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddEvent;
