import React from 'react'
import Arrow from '../../../../assets/landing/Arrow.png';
const NewsCard = ({title,desc,date,image})=>{
    return(
        <div className="flex items-center  mx-4 md:mx-0">
            {/* <div className="bg-gray-500 flex-shrink-0 h-[200px] w-[200px] rounded-xl"><img src={image} alt="news-image" className="w-full h-full"/></div> */}
            <div className="bg-gray-500 flex-shrink-0 h-[140px] w-[85px] md:h-[200px] md:w-[200px] rounded-[20px]"><img src={image} alt="news-image" className="w-full h-full"/></div>

            <div className="transform -translate-x-5 bg-white rounded-xl p-5 border-2 h-[140px] w-[280px] md:h-[190px] md:w-[350px]">
                <div className="flex mb-2"><p className="text-xs text-white bg-[#3ACA2E] py-1 px-3 rounded-xl ">RECENT NEWS</p> <img
        src={Arrow} // Assuming Arrow is your image path
        alt="Arrow Icon"
        className="arrow-icon absolute right-0 top-0 mt-2 mr-2"
      /></div>
                <div>
                    <p className="text-black text-base font-semibold truncate">{title}</p>
                    <p className="text-xs font-medium mb-3 truncate">{date}</p>
                    <p className="text-sm news_display_content truncate">{desc}</p>
                </div>
            </div>
        </div>
    )
}

export default NewsCard;



