import React, { useState } from "react";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { useNavigate, useLocation } from "react-router-dom";

const AllScholarship = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  const scholarships = [
    {
      scholarship_id: "S001",
      university_name: "Harvard University",
      scholarship_desc: "Harvard University offers a variety of scholarships based on merit and need...",

    },
    {
      scholarship_id: "S002",
      university_name: "Stanford University",
      scholarship_desc: "Stanford University provides various financial aid and scholarship options...",
      
    },
    // More scholarships...
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(5);

  const filteredScholarships = scholarships.filter((scholarship) =>
    scholarship.university_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalEntries = filteredScholarships.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const displayedScholarships = filteredScholarships.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePrevious = () => setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  const handleNext = () => setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));

  return (
    <div className="bg-gray-100 p-6 h-screen overflow-auto">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md shadow-md">
        <div className="flex justify-between items-center mb-4 pb-2 border-b border-gray-200">
          <h2 className="text-xl font-semibold mb-4">{subTitle}</h2>
          <button className="bg-[#6a73fa] text-white px-4 py-2 rounded-md">+ Add New</button>
        </div>

        <div className="flex justify-end mb-4">
          <input
            type="text"
            className="px-3 py-1 border border-gray-300 rounded"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by University Name"
          />
        </div>

        <div className="grid grid-cols-4  gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md">
          <div>Scholarship Id</div>
          <div>University Name</div>
          <div>Scholarship Description</div>
          <div>Action</div>
        </div>

        {displayedScholarships.length > 0 ? (
          displayedScholarships.map((scholarship) => (
            <div key={scholarship.scholarship_id} className="grid grid-cols-4 gap-2 text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100">
              <div>{scholarship.scholarship_id}</div>
              <div className="truncate">{scholarship.university_name}</div>
              <div className="truncate max-w-xs">{scholarship.scholarship_desc}</div>
              <div className="flex space-x-2">
                <button
                  onClick={() => navigate(`/admin/scholarship/edit/${scholarship.scholarship_id}`, { state: scholarship })}
                  className="bg-blue-600 text-white p-2 rounded-md"
                >
                  <HiPencilAlt />
                </button>
                <button className="bg-red-600 text-white p-2 rounded-md">
                  <HiTrash />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-4">No scholarships found</div>
        )}

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => {
                setEntriesPerPage(Number(e.target.value));
                setCurrentPage(1); // Reset to first page when entries per page changes
              }}
              className="border border-gray-300 rounded"
            >
              {[5, 10, 20].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className={`px-4 py-2 rounded-md ${currentPage === 1 ? 'bg-[#6a73fa] text-white' : 'bg-blue-600 text-white'} disabled:opacity-50`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">Page {currentPage} of {totalPages}</span>
            <button
              onClick={handleNext}
              className={`px-4 py-2 rounded-md ${currentPage === totalPages ? 'bg-[#6a73fa] text-white' : 'bg-blue-600 text-white'} disabled:opacity-50`}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllScholarship;
