import React, { useState, useEffect } from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
//import dummyPlacements from './dummyPlacements';
const AllPlacements = () => {
  const [placements, setPlacements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = placements.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(placements.length / entriesPerPage);


  // useEffect(() => {
  //   // Fetch placements when the component mounts
  //   const fetchPlacements = async () => {
  //     try {
  //       const response = await axios.get(`${Base_URL}/placement/university/1`); // Replace '1' with actual university ID
  //       setPlacements(response.data);
  //     } catch (error) {
  //       console.error('Error fetching placements:', error);
  //     }
  //   };

  //   fetchPlacements();
  // }, []);
// useEffect(() => {
//   setPlacements(dummyPlacements);
// })
useEffect(() => {
  console.log('Fetching placements ');
  const fetchPlacements = async () => {
    try {
      const response = await axios.get(`${Base_URL}/placement/university/1`);
      console.log(response.data);
      setPlacements(response.data);
    } catch (error) {
      console.error('Error fetching placements:', error.response ? error.response.data : error.message);
    }
  };

  fetchPlacements();
}, []);

const handleEditClick = (id) => {
  navigate(`/dashboard/placements/edit/${id}`);
};

const handleDeleteClick = async (id) => {
  console.log('Deleting faculty with ID:', id);
  const universityId = 1; // Ensure this is the correct university ID
 {
    try {
      const url = `${Base_URL}/placement/${id}`;
      console.log('DELETE request URL:', url);
      await axios.delete(url);
      setPlacements(prevPlacements => prevPlacements.filter(placements => placements.placement_id !== id));
      toast.success('Placements deleted successfully.');
    } catch (error) {
      console.error('Error deleting placements:', error.response ? error.response.data : error.message);
      toast.error('Failed to delete placements.');
    }
  }
};

const handlePrevious = () => {
  if (currentPage > 1) {
    setCurrentPage(currentPage - 1);
  }
};

const handleNext = () => {
  if (currentPage < totalPages) {
    setCurrentPage(currentPage + 1);
  }
};


  const filteredEntries = currentEntries.filter((placement) =>
    placement.student_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All Placements" breadcrumb={['Placements', 'All Placements']} />

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div
            className="font-poppins"
            style={{
              color: 'rgb(49, 42, 42)',
              fontSize: '18.5px',
              fontWeight: 600,
              lineHeight: '26.25px',
              height:'45px',
            }}
          >
            All Placements
          </div>
          <div className="flex items-center space-x-4">
            <button
              className="px-4 py-2 bg-[rgb(106,115,250)] text-white rounded-bl-[5px] rounded-br-[5px] text-sm hover:bg-[rgb(82,89,199)] "
              onClick={() => navigate('/dashboard/placements/add')}
            >
              + Add New
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 pb-2 ">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span
              className="text-sm text-gray-600"
              style={{
                color: 'rgb(130, 130, 130)',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '14px',
              }}
            >
              Search:
            </span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-white">
                {[ 'Student Name', 'Course', 'Company Name', 'Recruitment Date', 'Average Package', 'Actions'].map((heading) => (
                  <th key={heading} className="text-left border-b" style={{ color: 'rgb(49, 42, 42)', fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: 600, lineHeight: '21px', padding: '14px 13px', borderBottomColor: 'rgb(230, 230, 230)' }}>
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredEntries.length > 0 ? (
                filteredEntries.map((placement) => (
                  <tr key={placement.placement_id} className="hover:bg-white hover:shadow-md" style={{ borderTopWidth: '1px', borderTopColor: 'rgb(230, 230, 230)',verticalAlign: 'top'}}>
                    {/* <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      <img
                        src={placement.picture || '/path/to/default/image.jpg'}
                        alt={placement.student_name}
                        className="rounded-full"
                        style={{ width: '35px', verticalAlign: 'middle' }}
                      />
                    </td> */}
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      {placement.student_name}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      {placement.course}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      {placement.company_name}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      {placement.recruitment_date}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      {placement.average_salary_package}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditClick(placement.placement_id)}
                          className="p-2 rounded bg-blue-500 text-white hover:bg-blue-700"
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(placement.placement_id)}
                          className="p-2 rounded bg-red-500 text-white hover:bg-red-700"
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4">No placements found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
         
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-customblue text-white hover:bg-hoverblue"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-customblue text-white rounded hover:bg-hoverblue"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllPlacements;
