// import React from 'react';
// import './footer.css';

// const ListItem = ({ text }) => (
//   <div className="flex items-center gap-1 text-white cursor-pointer">
//     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-4">
//       <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
//     </svg>
//     <p className="text-lg">{text}</p>
//   </div>
// );

// const FooterSection = ({ title, items }) => (
//   <div className="flex flex-col gap-2 p-5">
//     <div className="text-white text-xl font-semibold">
//       <p>{title}</p>
//     </div>
//     {items.map((item, index) => (
//       <ListItem key={index} text={item} />
//     ))}
//   </div>
// );

// const Footer = () => {
//   const collegeItems = ['About Us', 'Campus Life', 'Admissions', 'Student Testimonials', 'Alumni'];
//   const examItems = ['Entrance Exams', 'Exam Schedules', 'Results', 'Preparation Tips'];
//   const courseItems = ['Undergraduate Programs', 'Postgraduate Programs', 'Diploma Courses', 'Online Courses'];
//   const updatesItems = ['News', 'Events', 'Workshops', 'Campus Tours'];
//   const moreItems = ['FAQs', 'Contact Us', 'Privacy Policy', 'Terms of Service'];

//   const socialLinks = [
//     {
//       svgPath: 'M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z',
//     },
//     {
//       svgPath: 'M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3c94 0 111.3 61.9 111.3 142.3V448z',
//     },
//     {
//       svgPath: 'M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z',
//     },
//     {
//       svgPath: 'M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9S287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7s74.7 33.5 74.7 74.7s-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8c-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8s26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9s-58-34.4-93.9-36.2c-37-2.1-147.9-2.1-184.9 0c-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9c1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0c35.9-1.7 67.7-9.9 93.9-36.2s34.4-58 36.2-93.9c2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6c-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6c-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6c29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6c11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z',
//     },
//   ];

//   return (
//     <footer className="bg-[#002147] footer_main">
//       <div className="flex flex-wrap justify-center gap-10 p-10">
//         <FooterSection title="COLLEGE" items={collegeItems} />
//         <FooterSection title="EXAM" items={examItems} />
//         <FooterSection title="COURSES" items={courseItems} />
//         <FooterSection title="LATEST UPDATES" items={updatesItems} />
//         <FooterSection title="MORE" items={moreItems} />
//       </div>

//       {/* Social Links */}
//       <div className="flex justify-center gap-5 p-5 text-white">
//         {socialLinks.map((link, index) => (
//           <svg key={index} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="size-8 cursor-pointer" fill="white">
//             <path d={link.svgPath} />
//           </svg>
//         ))}
//       </div>

//       {/* Copyright */}
//       <div className="text-white p-5">
//         <p className="text-center text-base">© 2024 College Name. All Rights Reserved.</p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;
import React from 'react';
import './footer.css';

const ListItem = ({ text }) => (
  <div className="footer-item">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="icon">
      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
    <p className="item-text">{text}</p>
  </div>
);

const FooterSection = ({ title, items }) => (
  <div className="footer-section">
    <div className="footer-title">{title}</div>
    {items.map((item, index) => (
      <ListItem key={index} text={item} />
    ))}
  </div>
);

const Footer = () => {
  const collegeItems = ['About Us', 'Campus Life', 'Admissions', 'Testimonials', 'Alumni'];
  const examItems = ['Entrance ', ' Schedules', 'Results', 'Prep Tips'];
  const courseItems = ['UG Programs', 'PG Programs', 'Diploma Courses', 'Online Courses'];
  const updatesItems = ['News', 'Events', 'Workshop', 'Campus'];
  const moreItems = ['FAQs', 'Contact Us', 'Privacy Policy', 'Terms of Service'];

  const socialLinks = [
    {
      svgPath: 'M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z',
    },
    {
      svgPath: 'M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3c94 0 111.3 61.9 111.3 142.3V448z',
    },
    {
      svgPath: 'M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z',
    },
    {
      svgPath: 'M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9S287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7s74.7 33.5 74.7 74.7s-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8c-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8s26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9s-58-34.4-93.9-36.2c-37-2.1-147.9-2.1-184.9 0c-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9c1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0c35.9-1.7 67.7-9.9 93.9-36.2s34.4-58 36.2-93.9c2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6c-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6c-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6c29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6c11.7 29.5 9 99.5 9 132.1s2.6 102.7-9 132.1z',
    },
  ];

  return (
    <footer className="footer">
      <div className="footer-container font-poppins">
        <FooterSection title="Colleges" items={collegeItems} />
        <FooterSection title="Exams" items={examItems} />
        <FooterSection title="Courses" items={courseItems} />
        <FooterSection title="Updates" items={updatesItems} />
        <FooterSection title="More" items={moreItems} />
      </div>

      <div className="footer-social">
        {socialLinks.map((link, index) => (
          <svg key={index} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="social-icon">
            <path d={link.svgPath} />
          </svg>
        ))}
      </div>

      <div className="footer-bottom">
        © 2024 College Dwarka. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;

