import React from 'react';
import college_logo from '../../../assets/college_logo.png';
import EventCard from './EventCard/EventCard';
import NewsCard from './NewsCard/NewsCard';

const NewsEvents = () => {
    return (
        <div className="py-8 px-5 bg-[#DCFFC4] overflow-x-hidden">
            {/* University Header */}
            <div className="text-black text-base font-medium flex flex-col gap-5">
                <div className="flex flex-col md:flex-row items-center justify-center gap-3 py-2">
                    <div className="h-[80px] w-[80px]">
                        <img src={college_logo} alt="college-logo" className="h-full w-full object-cover" />
                    </div>
                    <p className="text-3xl text-black font-semibold text-center md:text-left">
                        LPU : Lovely Professional University
                    </p>
                </div>

                <p className="text-lg text-black font-semibold mb-5 text-center md:text-left">
                    News and Events
                </p>

                <p className="mb-8 text-sm md:text-base text-center md:text-left">
                    Lovely Professional University (LPU) is known for its vibrant campus life, hosting a variety of events and making headlines in education and innovation. Stay updated with the latest news and events happening at LPU.
                </p>
            </div>

            {/* Featured News */}
            <div className="py-10">
                <div className="flex flex-col md:flex-row items-center gap-5">
                    <div className="bg-gray-500 flex-shrink-0 h-[300px] w-full md:w-[300px] rounded-xl overflow-hidden">
                        <img src={college_logo} alt="news-image" className="w-full h-full object-cover" />
                    </div>
                    <div className="bg-white rounded-xl p-5 flex-1">
                        <div className="flex mb-2">
                            <p className="text-xs text-white bg-[#3ACA2E] py-1 px-3 rounded-xl">FEATURED NEWS</p>
                        </div>
                        <div>
                            <p className="text-black text-lg font-semibold mb-2">LPU Hosts International Conference on Emerging Technologies</p>
                            <p className="text-xs font-medium mb-3">23 October 2024</p>
                            <p className="text-sm">
                                LPU successfully hosted the International Conference on Emerging Technologies, where top experts from around the world shared insights on AI, Machine Learning, and Blockchain. The event saw participation from over 200 delegates and was a huge success in fostering innovation and collaboration.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-10 px-5 flex flex-col md:flex-row gap-10 items-start">
                {/* Recent News */}
                <div className="flex-1">
                    <p className="text-black text-base font-semibold mb-5">RECENT NEWS</p>
                    <div className="flex flex-col gap-5">
                        <NewsCard
                            title={"LPU Ranks Among Top 100 Universities in India"}
                            desc={"LPU has once again ranked among the top 100 universities in India according to the latest NIRF rankings. The university continues to excel in academic and extracurricular activities."}
                            date={"October 20, 2024"}
                            image={college_logo}
                        />
                        <NewsCard
                            title={"LPU Collaborates with IBM for Advanced Technology Courses"}
                            desc={"LPU has signed a memorandum of understanding (MoU) with IBM to introduce advanced technology courses in AI, Data Science, and Cloud Computing, enhancing the skill set of students and preparing them for future challenges."}
                            date={"October 18, 2024"}
                            image={college_logo}
                        />
                    </div>
                </div>

                {/* Upcoming Events */}
                <div className="flex-1">
                    <p className="text-black text-base mb-6 font-semibold">UPCOMING EVENTS</p>
                    <div className="bg-white flex flex-col py-5 rounded-xl">
                        <EventCard
                            title={"Annual Tech Fest"}
                            subtitle={"Join us for LPU's Annual Tech Fest, showcasing the latest in technology, innovation, and creativity. Participate in workshops, hackathons, and competitions."}
                            location={"LPU Campus"}
                            date={30}
                            month={"AUG"}
                        />
                        <EventCard
                            title={"LPU Cultural Fest"}
                            subtitle={"LPU's Cultural Fest is back! Experience a blend of music, dance, and drama with performances by students and special guest appearances."}
                            location={"LPU Auditorium"}
                            date={15}
                            month={"SEP"}
                        />
                        <EventCard
                            title={"Placement Drive 2024"}
                            subtitle={"LPU's Placement Drive 2024 is here. Leading companies will be visiting the campus to recruit students for various roles. Prepare your resumes and get ready for interviews."}
                            location={"LPU Campus"}
                            date={10}
                            month={"OCT"}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NewsEvents;
