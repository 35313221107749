import React, { useState, useEffect } from "react";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { Base_URL } from "../../../apiConfig";

const AddScholarship = () => {
  const [formData, setFormData] = useState({
    university_id: null,
    scholarship_desc: "", // Handled by CKEditor
  });

  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the list of universities from the API
    const fetchUniversities = async () => {
      try {
        const response = await axios.get("http://213.210.37.39:3000/api/universities");
        const universityOptions = response.data.map((uni) => ({
          value: uni.university_id,
          label: uni.university_name,
        }));
        setUniversities(universityOptions);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch universities:", error);
        toast.error("Failed to fetch universities.");
        setLoading(false);
      }
    };

    fetchUniversities();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.university_id || !formData.scholarship_desc) {
      toast.error("Please fill in all the fields.");
      return;
    }

    try {
      // Submit the form data to the API
      const response = await axios.post(`${Base_URL}/create/scholarship`, formData);

      // Success handling
      toast.success("Scholarship added successfully!");
      console.log("Submitted Data:", response.data);

      // Reset form
      setFormData({ university_id: null, scholarship_desc: "" });
    } catch (error) {
      console.error("Error adding scholarship:", error.response?.data || error.message);
      toast.error("Failed to add scholarship. Please try again.");
    }
  };

  return (
    <>
      <div className="bg-gray-100 p-6">
        <HeaderTitle mainTitle="Scholarship" subTitle="Add Scholarship" />
        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Select University */}
              <div className="flex flex-col">
                <label htmlFor="university_id" className="mb-2 font-medium text-sm">
                  Select University
                </label>
                {loading ? (
                  <p>Loading universities...</p>
                ) : (
                  <Select
                    id="university_id"
                    value={universities.find((uni) => uni.value === formData.university_id)}
                    onChange={(selectedOption) => setFormData({ ...formData, university_id: selectedOption.value })}
                    options={universities}
                    placeholder="Select University"
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable
                  />
                )}
              </div>

              {/* Scholarship Description with CKEditor */}
              <div className="flex flex-col md:col-span-2">
                <label htmlFor="scholarship_desc" className="mb-2 font-medium text-sm">
                  Scholarship Description
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.scholarship_desc}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFormData((prev) => ({ ...prev, scholarship_desc: data }));
                  }}
                />
              </div>
            </div>

            <button type="submit" className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md">
              Submit
            </button>
          </form>

          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default AddScholarship;
