// import React, { useState, useEffect } from 'react';
// import { HiPencil, HiTrash } from 'react-icons/hi';
// import { useNavigate } from 'react-router-dom';
// import HeaderSection from '../headerSection/HeaderSection';
// import axios from 'axios';
// import { Base_URL } from '../../../apiConfig';
// import dummytestimonial from './dummytestimonial';

// const AllTestimonial = () => {
//   const [testimonials, setTestimonials] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [entriesPerPage, setEntriesPerPage] = useState(20);
//   const [searchTerm, setSearchTerm] = useState('');
//   const navigate = useNavigate();
//   // useEffect(() => {
//   //   const fetchTestimonials = async () => {
//   //     try {
//   //       const response = await axios.get(`${Base_URL}/api/testimonials`);
//   //       setTestimonials(response.data);
//   //     } catch (error) {
//   //       console.error('Error fetching testimonials:', error);
//   //     }
//   //   };

//   //   fetchTestimonials();
//   // }, []);

//   // Fetch testimonials (replace with actual API call if needed)
//   useEffect(() => {
//     setTestimonials(dummytestimonial);
//   }, []);

//   const indexOfLastEntry = currentPage * entriesPerPage;
//   const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
//   const currentEntries = testimonials.slice(indexOfFirstEntry, indexOfFirstEntry + entriesPerPage);

//   const totalPages = Math.ceil(testimonials.length / entriesPerPage);

//   const handlePrevious = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const handleNext = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handleDeleteClick = async (id) => {
//     try {
//       await axios.delete(`${Base_URL}/api/testimonial/${id}`);
//       setTestimonials(testimonials.filter((testimonial) => testimonial.id !== id));
//     } catch (error) {
//       console.error('Error deleting testimonial:', error);
//     }
//   };

//   const handleEditClick = (id) => {
//     navigate(`/testimonials/edit/${id}`);
//   };

//   const filteredEntries = currentEntries.filter((testimonial) =>
//     testimonial.student_name.toLowerCase().includes(searchTerm.toLowerCase())
//   );
import React, { useState, useEffect } from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';
import axios from 'axios';
import { Base_URL,Img_URL } from '../../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

// function convertImagePaths(paths) {
//   return paths.map(path => path.replace('/var/www/zanko/uploads', Img_URL));
// }

const AllTestimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = testimonials.slice(indexOfFirstEntry, indexOfFirstEntry + entriesPerPage);

  const totalPages = Math.ceil(testimonials.length / entriesPerPage);

  useEffect(() => {
    const fetchTestimonial = async () => {
      try {
        const response = await axios.get(`${Base_URL}/student-testimonials/university/1`);
        console.log('Fetched Data:', response.data); // Debugging line
        setTestimonials(response.data);
      } catch (error) {
        console.error('Error fetching testimonial:', error.response ? error.response.data : error.message);
        setError('Error fetching testimonial.');
      }
    };

    fetchTestimonial();
  }, []);

  // useEffect(() => {
  //   const fetchTestimonials = async () => {
  //     try {
  //       // Fetch testimonials with hardcoded university_id = 1
  //       const response = await axios.get(`${Base_URL}/student-testimonials`, {
  //         params: { university_id: 1 }
  //       });
  //       setTestimonials(response.data);
  //     } catch (error) {
  //       console.error('Error fetching testimonials:', error);
  //     }
  //   };

  //   fetchTestimonials();
  // }, []);

 

  const handleEditClick = (id) => {
    navigate(`/dashboard/testimonials/edit/${id}`);
  };

  const handleDeleteClick = async (id) => {
    console.log('Deleting testimonials with ID:', id);
    const universityId = 1; // Ensure this is the correct university ID
     {
      try {
        const url = `${Base_URL}/student-testimonials/${id}`;
        console.log('DELETE request URL:', url);
        await axios.delete(url);
        setTestimonials(prevTestimonials => prevTestimonials.filter(testimonial=> testimonial.testimonial_id !== id));
        toast.success('Testimonial deleted successfully.');
      } catch (error) {
        console.error('Error deleting Testimonial:', error.response ? error.response.data : error.message);
        toast.error('Failed to delete Testimonial.');
      }
    }
  };
  

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filteredEntries = currentEntries.filter((testimonial) =>
    testimonial.student_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All Testimonials" breadcrumb={['Testimonials', 'All Testimonials']} />

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div
            className="font-poppins"
            style={{
              color: 'rgb(49, 42, 42)',
              fontSize: '18.5px',
              fontWeight: 600,
              lineHeight: '26.25px',
              height:'45px',
            }}
          >
            All Testimonials
          </div>
          <div className="flex items-center space-x-4">
            <button
              className="px-4 py-2 bg-[rgb(106,115,250)] text-white rounded-bl-[5px] rounded-br-[5px] text-sm hover:bg-[rgb(82,89,199)] "
              onClick={() => navigate('/dashboard/testimonials/add')}
            >
              + Add New
            </button>
          </div>
        </div>
                {/* Flex container for "Show Entries" dropdown and search bar */}
                <div className="flex items-center justify-between mb-4 pb-2 ">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span
              className="text-sm text-gray-600"
              style={{
                color: 'rgb(130, 130, 130)',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '14px',
              }}
            >
              Search:
            </span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-white">
                {[ 'Name', 'Course', 'Testimonial Text', 'Actions'].map((heading) => (
                  <th key={heading} className="text-left border-b" style={{ color: 'rgb(49, 42, 42)', fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: 600, lineHeight: '21px', padding: '14px 13px', borderBottomColor: 'rgb(230, 230, 230)' }}>
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredEntries.length > 0 ? (
                filteredEntries.map((testimonial) => (
                  <tr key={testimonial.testimonial_id} className="hover:bg-white hover:shadow-md" style={{ borderTopWidth: '1px', borderTopColor: 'rgb(230, 230, 230)',verticalAlign: 'top'}}>
                    {/* <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                      {testimonial.profile_picture}
                    </td> */}
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      {testimonial.student_name}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      {testimonial.course}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      {testimonial.testimonial_text}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditClick(testimonial.testimonial_id)}
                          className="p-2 rounded bg-blue-500 text-white hover:bg-blue-700"
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(testimonial.testimonial_id)}
                          className="p-2 rounded bg-red-500 text-white hover:bg-red-700"
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">No testimonials found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          
          <div className="flex items-center">
            <button
              onClick={handlePrevious}
              className="px-3 py-1 bg-customblue text-white rounded-l"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="mx-2 text-sm">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-3 py-1 bg-customblue text-white rounded-r"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllTestimonial;
