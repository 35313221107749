import React, { useState } from 'react';
import { HiPencilAlt, HiTrash } from 'react-icons/hi';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { useNavigate, useLocation } from 'react-router-dom';

const AllPrograms = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  const programs = [
    {
      program_id: 'P001',
      program_name: 'BTECH',
      university_name: 'Harvard University',
      program_duration: '4 Years',
      program_fees: '$20,000 per year',
      exams_accepted: ['SAT', 'ACT'],
      program_details: 'A comprehensive program focusing on engineering fundamentals and modern technology.',
      course_offered: ['Data Science', 'AI', 'Cybersecurity'],
    },
    {
      program_id: 'P002',
      program_name: 'BCA',
      university_name: 'Stanford University',
      program_duration: '3 Years',
      program_fees: '$18,000 per year',
      exams_accepted: ['SAT', 'ACT'],
      program_details: 'A program focused on computer applications and software development.',
      course_offered: ['Software Engineering', 'Web Development'],
    },
    {
        program_id: 'P001',
        program_name: 'BTECH',
        university_name: 'Harvard University',
        program_duration: '4 Years',
        program_fees: '$20,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A comprehensive program focusing on engineering fundamentals and modern technology.',
        course_offered: ['Data Science', 'AI', 'Cybersecurity'],
      },
      {
        program_id: 'P002',
        program_name: 'BCA',
        university_name: 'Stanford University',
        program_duration: '3 Years',
        program_fees: '$18,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A program focused on computer applications and software development.',
        course_offered: ['Software Engineering', 'Web Development'],
      },
      {
        program_id: 'P001',
        program_name: 'BTECH',
        university_name: 'Harvard University',
        program_duration: '4 Years',
        program_fees: '$20,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A comprehensive program focusing on engineering fundamentals and modern technology.',
        course_offered: ['Data Science', 'AI', 'Cybersecurity'],
      },
      {
        program_id: 'P002',
        program_name: 'BCA',
        university_name: 'Stanford University',
        program_duration: '3 Years',
        program_fees: '$18,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A program focused on computer applications and software development.',
        course_offered: ['Software Engineering', 'Web Development'],
      },
      {
        program_id: 'P001',
        program_name: 'BTECH',
        university_name: 'Harvard University',
        program_duration: '4 Years',
        program_fees: '$20,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A comprehensive program focusing on engineering fundamentals and modern technology.',
        course_offered: ['Data Science', 'AI', 'Cybersecurity'],
      },
      {
        program_id: 'P002',
        program_name: 'BCA',
        university_name: 'Stanford University',
        program_duration: '3 Years',
        program_fees: '$18,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A program focused on computer applications and software development.',
        course_offered: ['Software Engineering', 'Web Development'],
      },
      {
        program_id: 'P001',
        program_name: 'BTECH',
        university_name: 'Harvard University',
        program_duration: '4 Years',
        program_fees: '$20,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A comprehensive program focusing on engineering fundamentals and modern technology.',
        course_offered: ['Data Science', 'AI', 'Cybersecurity'],
      },
      {
        program_id: 'P002',
        program_name: 'BCA',
        university_name: 'Stanford University',
        program_duration: '3 Years',
        program_fees: '$18,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A program focused on computer applications and software development.',
        course_offered: ['Software Engineering', 'Web Development'],
      },
      {
        program_id: 'P001',
        program_name: 'BTECH',
        university_name: 'Harvard University',
        program_duration: '4 Years',
        program_fees: '$20,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A comprehensive program focusing on engineering fundamentals and modern technology.',
        course_offered: ['Data Science', 'AI', 'Cybersecurity'],
      },
      {
        program_id: 'P002',
        program_name: 'BCA',
        university_name: 'Stanford University',
        program_duration: '3 Years',
        program_fees: '$18,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A program focused on computer applications and software development.',
        course_offered: ['Software Engineering', 'Web Development'],
      },
      {
        program_id: 'P001',
        program_name: 'BTECH',
        university_name: 'Harvard University',
        program_duration: '4 Years',
        program_fees: '$20,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A comprehensive program focusing on engineering fundamentals and modern technology.',
        course_offered: ['Data Science', 'AI', 'Cybersecurity'],
      },
      {
        program_id: 'P002',
        program_name: 'BCA',
        university_name: 'Stanford University',
        program_duration: '3 Years',
        program_fees: '$18,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A program focused on computer applications and software development.',
        course_offered: ['Software Engineering', 'Web Development'],
      },
      {
        program_id: 'P001',
        program_name: 'BTECH',
        university_name: 'Harvard University',
        program_duration: '4 Years',
        program_fees: '$20,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A comprehensive program focusing on engineering fundamentals and modern technology.',
        course_offered: ['Data Science', 'AI', 'Cybersecurity'],
      },
      {
        program_id: 'P002',
        program_name: 'BCA',
        university_name: 'Stanford University',
        program_duration: '3 Years',
        program_fees: '$18,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A program focused on computer applications and software development.',
        course_offered: ['Software Engineering', 'Web Development'],
      },
      {
        program_id: 'P001',
        program_name: 'BTECH',
        university_name: 'Harvard University',
        program_duration: '4 Years',
        program_fees: '$20,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A comprehensive program focusing on engineering fundamentals and modern technology.',
        course_offered: ['Data Science', 'AI', 'Cybersecurity'],
      },
      {
        program_id: 'P002',
        program_name: 'BCA',
        university_name: 'Stanford University',
        program_duration: '3 Years',
        program_fees: '$18,000 per year',
        exams_accepted: ['SAT', 'ACT'],
        program_details: 'A program focused on computer applications and software development.',
        course_offered: ['Software Engineering', 'Web Development'],
      },
    // Add more programs here
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(5); // Entries per page for pagination

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const filteredEntries = programs
    .filter((program) =>
      program.program_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(
    programs.filter((program) =>
      program.program_name.toLowerCase().includes(searchTerm.toLowerCase())
    ).length / entriesPerPage
  );

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="bg-gray-100 p-6 h-screen overflow-auto">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md shadow-md">
        <div className="flex justify-between items-center mb-4 pb-2 border-b border-gray-200">
          <h2 className="text-xl font-semibold mb-4">All Programs</h2>
          <button className="bg-[#6a73fa] text-white px-4 py-2 rounded-md" onClick={()=>navigate('/admin/programs/add',{state:{subTitle:'Add Programs',mainTitle:'Program and Courses'}})} >+ Add New</button>
        </div>

        <div className="flex items-center justify-end space-x-4 mb-4">
          <div className="flex items-center text-gray-600">
            <span className="mr-2 text-sm">Search:</span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by Program Name"
            />
          </div>
        </div>

        <div className="grid grid-cols-9 gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md">
          <div>Program ID</div>
          <div>University Name</div>
          <div>Program Name</div>
          <div>Duration</div>
          <div>Fees</div>
          <div>Exams Accepted</div>
          <div>Courses Offered</div>
          <div>Description</div>
          <div>Action</div>
        </div>

        {filteredEntries.length > 0 ? (
          filteredEntries.map((program, index) => (
            <div key={index} className="grid grid-cols-9 gap-2 text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100">
              <div>{program.program_id}</div>
              <div className="truncate">{program.university_name}</div>
              <div className="truncate">{program.program_name}</div>
              <div className="truncate">{program.program_duration}</div>
              <div className="truncate">{program.program_fees}</div>
              <div className="truncate">{program.exams_accepted.join(', ')}</div>
              <div className="truncate">{program.course_offered.join(', ')}</div>
              <div className="truncate max-w-xs">
                <p className="line-clamp-2">{program.program_details}</p>
              </div>
              <div className="flex space-x-2">
              <button
  onClick={() => navigate(`/admin/programs/edit/${program.program_id}`, { state: program })}
  className="bg-blue-600 text-white p-2 rounded-md"
>
  <HiPencilAlt />
</button>
                <button className="bg-red-600 text-white p-2 rounded-md">
                  <HiTrash />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-4">No programs found</div>
        )}

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
            </select>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-blue-600 text-white rounded-md disabled:opacity-50"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-blue-600 text-white rounded-md disabled:opacity-50"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPrograms;
