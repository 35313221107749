import React, { useState } from 'react';
import { HiPencilAlt, HiTrash } from 'react-icons/hi';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { useNavigate, useLocation } from 'react-router-dom';

const AllPlacement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  // Sample data for Placements
  const placements = [
    {
      placement_id: 'P001',
      university_name: 'Harvard University',
      placement_desc: 'Harvard University has a placement rate of 90% in top tech companies...',
      
    },
    {
      placement_id: 'P002',
      university_name: 'Stanford University',
      placement_desc: 'Stanford University places students in leading companies across the globe...',
    },
    // More placements...
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(5);

  // Filter and Paginate placements based on search term and current page
  const filteredPlacements = placements.filter(placement =>
    placement.university_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalEntries = filteredPlacements.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const displayedPlacements = filteredPlacements.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePrevious = () => setCurrentPage(prev => (prev > 1 ? prev - 1 : prev));
  const handleNext = () => setCurrentPage(prev => (prev < totalPages ? prev + 1 : prev));

  return (
    <div className="bg-gray-100 p-6 h-screen overflow-auto">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md shadow-md">
        <div className="flex justify-between items-center mb-4 pb-2 border-b border-gray-200">
          <h2 className="text-xl font-semibold mb-4">{subTitle}</h2>
          <button className="bg-[#6a73fa] text-white px-4 py-2 rounded-md">+ Add New</button>
        </div>

        <div className="flex justify-end mb-4">
          <input
            type="text"
            className="px-3 py-1 border border-gray-300 rounded"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by University Name"
          />
        </div>

        <div className="grid grid-cols-4 gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md">
          <div>Placement Id</div>
          <div>University Name</div>
          <div>Placement Description</div>
     
          <div>Action</div>
        </div>

        {displayedPlacements.length > 0 ? (
          displayedPlacements.map((placement) => (
            <div key={placement.placement_id} className="grid grid-cols-4 gap-2 text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100">
              <div>{placement.placement_id}</div>
              <div className="truncate">{placement.university_name}</div>
              <div className="truncate max-w-xs">{placement.placement_desc}</div>
              <div className="flex space-x-2">
                <button
                  onClick={() => navigate(`/admin/placements/edit/${placement.placement_id}`, { state: placement })}
                  className="bg-blue-600 text-white p-2 rounded-md"
                >
                  <HiPencilAlt />
                </button>
                <button className="bg-red-600 text-white p-2 rounded-md">
                  <HiTrash />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-4">No placements found</div>
        )}

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => {
                setEntriesPerPage(Number(e.target.value));
                setCurrentPage(1); // Reset to first page when entries per page changes
              }}
              className="border border-gray-300 rounded"
            >
              {[5, 10, 20].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className={`px-4 py-2 rounded-md ${currentPage === 1 ? 'bg-[#6a73fa] text-white' : 'bg-blue-600 text-white'}`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">Page {currentPage} of {totalPages}</span>
            <button
              onClick={handleNext}
              className={`px-4 py-2 rounded-md ${currentPage === totalPages ? 'bg-[#6a73fa] text-white' : 'bg-blue-600 text-white'}`}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPlacement;
