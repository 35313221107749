import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const EditPlacement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    university_id: 1,
    student_name: '',
    course: '',
    picture:'',
    company_name: '',
    recruitment_date: '',
    average_salary_package: '',
  });
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [imageName, setImageName] = useState('No file chosen'); 
  useEffect(() => {
    const fetchPlacementData = async () => {
      try {
        const response = await axios.get(`${Base_URL}/placement/${id}`);
        const event = response.data;
        const formattedDate = new Date(event.recruitment_date).toISOString().split('T')[0];
        
        setFormData({
          student_name: event.student_name,
          recruitment_date: formattedDate,
          course: event.course,
          company_name: event.company_name,
          picture: event.picture,
          average_salary_package: event.average_salary_package,
          university_id: 1,
        });
       // Set image name from fetched data
      } catch (error) {
        console.error('Error fetching placement data:', error);
      }
    };
    fetchPlacementData();
  }, [id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      if (image) {
        data.append('picture', image);
      }

      // Retrieve token from local storage or other storage method
      //const token = localStorage.getItem('authToken');

      const response = await axios.put(`${Base_URL}/placement/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          //'Authorization': `Bearer ${token}`, // Add Authorization header
        },
      });

      console.log('Updated placement data response:', response.data);
      toast.success('Placement data updated successfully!');
      navigate('/dashboard/placements/all'); // Navigate to All Placements after update
    } catch (error) {
      console.error('Failed to update placement data:', error.response?.data || error.message);
      toast.error('Failed to update placement data. Please try again.');
    }
  };
  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <HeaderSection
        title="Edit Placement"
        breadcrumb={['Placement', 'Edit Placements']}
      />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        {/* Basic Info Header */}
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[#312a2a] text-[17.25px] font-[500] font-poppins">Basic Info</h2>
        </div>

        {error && <div className="text-red-600 mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Form Fields */}
            <div className="flex flex-col">
              <label
                htmlFor="student_name"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Student Name
              </label>
              <input
                type="text"
                id="student_name"
                name="student_name"
                value={formData.student_name}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
               
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="course"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Course
              </label>
              <input
                type="text"
                id="course"
                name="course"
                value={formData.course}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
               
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="company_name"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Company Name
              </label>
              <input
                type="text"
                id="company_name"
                name="company_name"
                value={formData.company_name}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
               
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="recruitment_date"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Recruitment Date
              </label>
              <input
                type="date"
                id="recruitment_date"
                name="recruitment_date"
                value={formData.recruitment_date}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
             
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="average_salary_package"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Average Salary Package
              </label>
              <input
                type="number"
                id="average_salary_package"
                name="average_salary_package"
                value={formData.average_salary_package}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
               
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="profile_picture"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[6px]">
                Upload Profile Picture
              </label>
              <div className="flex border border-gray-300 rounded-lg overflow-hidden">
                <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer font-poppins">
                  <span className='text-[12.25px]'>Choose File</span>
                  <input
                    type="file"
                    id="profile_picture"
                    name="profile_picture"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </label>
                <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center font-poppins text-[12.25px]">
                  {imageName || 'No file chosen'}
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-customblue text-white rounded-md font-poppins hover:bg-[rgb(82,89,199)]">
            Submit
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

 
export default EditPlacement;
