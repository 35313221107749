import React, { useState } from 'react';
import { HiPencilAlt, HiTrash } from 'react-icons/hi';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { useNavigate } from 'react-router-dom';

const AllCourses = () => {
  const navigate = useNavigate();
  
  // Sample data for courses
  const courses = [
    {
      course_id: 'C001',
      program_id: 'P001',
      university_name: 'Harvard University',
      course_name: 'MCA',
      course_fees: '$15,000 per year',
      course_duration: '2 Years',
      exams_accepted: ['GATE', 'CAT'],
      course_description: 'This is a two-year postgraduate course that provides advanced knowledge in computer applications.',
    },
    {
      course_id: 'C002',
      program_id: 'P001',
      university_name: 'Harvard University',
      course_name: 'MCA (Hons.) (Data Science)',
      course_fees: '$18,000 per year',
      course_duration: '2 Years',
      exams_accepted: ['GATE', 'CAT', 'University Entrance'],
      course_description: 'This specialization focuses on data science and machine learning techniques in the context of computer applications.',
    },
    {
      course_id: 'C003',
      program_id: 'P002',
      university_name: 'Stanford University',
      course_name: 'MBA',
      course_fees: '$25,000 per year',
      course_duration: '2 Years',
      exams_accepted: ['GMAT', 'CAT'],
      course_description: 'A two-year management program aimed at developing leadership skills, business strategies, and financial acumen.',
    },
    {
      course_id: 'C004',
      program_id: 'P003',
      university_name: 'MIT',
      course_name: 'B.Tech (Mechanical)',
      course_fees: '$20,000 per year',
      course_duration: '4 Years',
      exams_accepted: ['JEE Main', 'State Entrance'],
      course_description: 'This course provides in-depth knowledge and hands-on experience in mechanical engineering principles.',
    },
  ];

  return (
    <div className="bg-gray-100 p-6 h-screen overflow-auto">
      <HeaderTitle mainTitle="Courses" subTitle="All Courses" />
      <div className="bg-white p-4 rounded-md shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold mb-4">All Courses</h2>
          <button className="bg-[#6a73fa] text-white px-4 py-2 rounded-md">+ Add New</button>
        </div>
        <div className="flex justify-between mb-4">
          <div className="flex items-center">
            <label className="text-sm">Show</label>
            <select className="ml-2 border border-gray-300 rounded-md p-1">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
            <span className="ml-2 text-sm">entries</span>
          </div>
          <div>
            <label className="text-sm mr-2">Search :</label>
            <input
              type="text"
              className="border border-gray-300 rounded-md p-1"
              placeholder="Search..."
            />
          </div>
        </div>

        {/* Table Headers */}
        <div className="grid grid-cols-9 gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md">
          <div>Course ID</div>
          <div>Program ID</div>
          <div>University Name</div> {/* New Column */}
          <div>Course Name</div>
          <div>Fees</div>
          <div>Duration</div>
          <div>Exams Accepted</div>
          <div>Course Description</div>
          <div>Action</div>
        </div>

        {/* Table Body */}
        {courses.map((course, index) => (
          <div key={index} className="grid grid-cols-9 gap-2 text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100">
            <div>{course.course_id}</div>
            <div>{course.program_id}</div>
            <div>{course.university_name}</div> {/* New Field */}
            <div className="truncate">{course.course_name}</div>
            <div className="truncate">{course.course_fees}</div>
            <div className="truncate">{course.course_duration}</div>
            <div className="truncate max-w-xs">
              <p className="line-clamp-2">{course.exams_accepted.join(', ')}</p>
            </div>
            <div className="truncate max-w-xs">
              <p className="line-clamp-2">{course.course_description}</p>
            </div>
            <div className="flex space-x-2">
              <button 
                onClick={() => navigate(`/admin/courses/edit/${course.course_id}`, { state: course })} 
                className="bg-blue-600 text-white p-2 rounded-md">
                <HiPencilAlt />
              </button>
              <button className="bg-red-600 text-white p-2 rounded-md">
                <HiTrash />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AllCourses;
