import React from 'react'

const Rankings = () => {
    return (
        <div className="px-5 py-14 bg-white w-full flex flex-col items-center">
            <div className="text-center mb-5">
                <p className="text-[28px] md:text-3xl font-bold text-[#507aa0]">
                    India's Top Ranked Institute
                </p>
            </div>
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 py-5 max-w-screen-lg">
                <div className="relative flex flex-col items-center justify-center p-3 w-full sm:w-[200px] h-auto border-2 border-dotted border-black">
                    <p className="text-3xl font-bold text-[#ef7d00] bg-white absolute -top-5 px-2">#23</p>
                    <div className="text-black text-sm flex flex-col gap-1 items-center">
                        <p className="text-center">LPU's Global Rank</p>
                        <p className="text-center">The Impact Ranking 2022</p>
                    </div>
                </div>
                <div className="relative flex flex-col items-center justify-center p-3 w-full sm:w-[200px] h-auto border-2 border-dotted border-black">
                    <p className="text-3xl font-bold text-[#ef7d00] bg-white absolute -top-5 px-2">#2</p>
                    <div className="text-black text-sm flex flex-col gap-1 items-center">
                        <p className="text-center">LPU amongst top universities in India</p>
                        <p className="text-center">The World University Ranking 2023</p>
                    </div>
                </div>
                <div className="relative flex flex-col items-center justify-center p-3 w-full sm:w-[200px] h-auto border-2 border-dotted border-black">
                    <p className="text-3xl font-bold text-[#ef7d00] bg-white absolute -top-5 px-2">#38</p>
                    <div className="text-black text-sm flex flex-col gap-1 items-center">
                        <p className="text-center">LPU's Global Rank</p>
                        <p className="text-center">The Impact Ranking 2022</p>
                    </div>
                </div>
                <div className="relative flex flex-col items-center justify-center p-3 w-full sm:w-[200px] h-auto border-2 border-dotted border-black">
                    <p className="text-3xl font-bold text-[#ef7d00] bg-white absolute -top-5 px-2">#3</p>
                    <div className="text-black text-sm flex flex-col gap-1 items-center">
                        <p className="text-center">
                            Amongst all Government and Private Universities in India
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rankings;

