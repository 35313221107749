import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css'; // Import the custom CSS
import { HiChevronRight, HiOutlineCurrencyDollar, HiNewspaper, HiBriefcase } from 'react-icons/hi';
import { LiaUniversitySolid, LiaGraduationCapSolid } from 'react-icons/lia';
import { LuBookMinus } from 'react-icons/lu';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { MdOutlineDashboard } from 'react-icons/md';

const Sidebar = ({ isSidebarOpen }) => {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null); // Add selected state
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState(null); // Add selected sub-menu state

  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleMenuItemClick = (index) => {
    setSelectedMenuItem(index); // Set selected menu item
    setSelectedSubMenuItem(null); // Reset sub-menu selection when a new menu is selected
  };

  const handleSubMenuItemClick = (subIndex) => {
    setSelectedSubMenuItem(subIndex); // Set selected sub-menu item
  };

  const menuItems = [
    { title: 'Admin', icon: MdOutlineDashboard, path: '/admin', items: [] },
    {
      title: 'University',
      icon: LiaUniversitySolid,
      items: [
        { title: 'All University', path: '/admin/university/all' },
        { title: 'Add University', path: '/admin/university/add' },
      ],
    },
    {
      title: 'Programs and Courses',
      icon: LiaGraduationCapSolid,
      items: [
        { title: 'All Programs', path: '/admin/programs/all' },
        { title: 'Add Programs', path: '/admin/programs/add' },
        { title: 'All Courses', path: '/admin/courses/all' },
        { title: 'Add Courses', path: '/admin/courses/add' },
      ],
    },
    {
      title: 'Admission',
      icon: LuBookMinus,
      items: [
        { title: 'All Admissions', path: '/admin/admissions/all' },
        { title: 'Add Admissions', path: '/admin/admissions/add' },
      ],
    },
    {
      title: 'Scholarship',
      icon: HiOutlineCurrencyDollar,
      items: [
        { title: 'All Scholarship', path: '/admin/scholarship/all' },
        { title: 'Add Scholarship', path: '/admin/scholarship/add' },
      ],
    },
    {
      title: 'Placement',
      icon: HiBriefcase,
      items: [
        { title: 'All Placement', path: '/admin/placement/all' },
        { title: 'Add Placement', path: '/admin/placement/add' },
      ],
    },
    {
      title: 'News & Announcements',
      icon: HiNewspaper,
      items: [
        { title: 'All News', path: '/admin/news/all' },
        { title: 'Add News', path: '/admin/news/add' },
        { title: 'Edit News', path: '/admin/news/edit' },
      ],
    },
    {
      title: 'FAQs',
      icon: FaRegQuestionCircle,
      items: [
        { title: 'All Faq', path: '/admin/faqs/all' },
        { title: 'Add Faq', path: '/admin/faqs/add' },
      ],
    },
  ];

  return (
    <div className={`fixed top-0 left-0 h-screen  z-10 text-gray-500 transition-all duration-300 ${isSidebarOpen ? 'w-64' : 'w-20 '} scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-white`}
      style={{ transition: 'width 0.3s ease ', fontFamily: 'Poppins, sans-serif' }}
    >
      <div className="flex items-center p-4 bg-indigo-500">
        <img src="" alt="" className={`h-8 w-8 ${isSidebarOpen ? '' : 'mx-auto'}`} />
        {isSidebarOpen && <span className="text-white ml-2 text-xl">College Dwarka</span>}
      </div>
      <nav className={`flex-1  py-4 space-y-1 sidebar`}>
        {menuItems.map((item, index) => (
          <div key={index} className={`${isSidebarOpen ? 'px-0' : 'px-2'} dashboard_sidebar_element`}>
            <div key={index} className="dashboard_sidebar_element ">
              <div
                className={`flex items-center justify-center px-4 py-2 text-[12.25px] font-lightbold ${isSidebarOpen ? null : 'rounded-md '} ${isSidebarOpen ? selectedMenuItem === index ? 'bg-[#f0f0fe] text-[#6a73fa] border-r-4 border-[#6a73fa] ' : '' : selectedMenuItem === index ? 'bg-[#f0f0fe] text-[#6a73fa] ' : ''} hover:${isSidebarOpen ? 'border-r-4 ' : 'border-r-0'} hover:border-[#6a73fa] font-medium menu cursor-pointer`}
                onClick={() => {
                  handleDropdownToggle(index);
                  handleMenuItemClick(index); // Mark item as selected
                }}
              >
                <item.icon className={`mr-2 hover:text-[#6a73fa] transition-all duration-300 ${isSidebarOpen ? 'text-xl' : 'text-2xl'}  ${selectedMenuItem === index ? 'text-[#6a73fa]' : 'text-gray-500'}  `} />
                {isSidebarOpen ? item.title : null}
                {isSidebarOpen ? item.items.length >= 0 && (
                  <HiChevronRight
                    className={`ml-auto  transition-transform duration-300 ${openDropdown === index ? 'rotate-90' : ''} ${selectedMenuItem === index ? 'text-[#6a73fa]' : 'text-gray-500'} `}
                  />
                ) : null}
              </div>
              {isSidebarOpen && openDropdown === index && item.items.length > 0 && (
                <div className="pl-6 mt-1">
                  {item.items.map((subItem, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subItem.path}
                      className={`flex items-center px-3 py-2 z-10 text-[12.25px] font-medium block ${location.pathname === subItem.path ? 'text-[#6a73fa]' : 'text-gray-500'} hover:text-[#6a73fa]`}
                      state={{ mainTitle: item.title, subTitle: subItem.title }}
                      onClick={() => handleSubMenuItemClick(subIndex)}
                    >
                      <HiChevronRight className={`mr-2 ${location.pathname === subItem.path ? 'text-[#6a73fa]' : 'text-gray-500'} `} />
                      {subItem.title}
                    </Link>
                  ))}
                </div>
              )}
              {!isSidebarOpen && item.items.length > 0 && (
                <div className="submenu">
                  <ul>
                    {item.items.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={subItem.path}
                          className={`block rounded-md px-3 py-1 text-sm font-medium ${location.pathname === subItem.path ? 'text-[#6a73fa]' : 'text-[#737b8b]'} hover:text-[#6a73fa]`}
                          state={{ mainTitle: item.title, subTitle: subItem.title }}
                          onClick={() => handleSubMenuItemClick(subIndex)}
                        >
                          {subItem.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;
