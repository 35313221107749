import React, { useState } from 'react';
import college_logo from '../../../assets/college_logo.png';

const Admission = () => {
    const [showDocuments, setShowDocuments] = useState(false);

    const toggleDocuments = () => {
        setShowDocuments(!showDocuments);
    };

    return (
        <div className="px-5 py-8 bg-[#DCFFC4]">
            {/* Header with logo and university name */}
            <div className="text-black text-base font-medium flex flex-col gap-5 mb-8 items-center ">
                <div className="flex flex-col md:flex-row items-center gap-3">
                    <div className="md:h-[80px] md:w-[80px] h-[50px] w-[50px]">
                        <img src={college_logo} alt="college-logo" className="h-full w-full object-cover" />
                    </div>
                    <p className="md:text-3xl text-black font-[700] text-center ">
                        LPU : Lovely Professional University
                    </p>
                </div>

                {/* Admission Steps */}
                <div className="w-full">
                    {/* Mobile centered steps */}
                    <div className="flex flex-col items-center md:items-start">
                        <div className="w-full md:w-auto">
                            <div className="flex flex-col gap-4">
                                {/* Step 1 */}
                                <div className="flex flex-col md:flex-row items-start py-2 w-full">
                                    <div className="flex-shrink-0">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="white"
                                            className="size-8"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.49 12 3.75 3.75m0 0-3.75 3.75m3.75-3.75H3.74V4.499" />
                                        </svg>
                                    </div>
                                    <div className="bg-white py-1 my-1 px-2 flex-shrink-0">
                                        <p className="text-base font-medium text-center md:text-left">Step 1: Registration</p>
                                    </div>
                                    <div className="px-2 py-2">
                                        <p>
                                            To start the admission process at Lovely Professional University, the first step is to register on the official LPU website. Create your account and provide the required personal information. This will give you access to all the admission-related services.
                                        </p>
                                    </div>
                                </div>

                                {/* Step 2 */}
                                <div className="flex flex-col md:flex-row items-start py-2 w-full">
                                    <div className="flex-shrink-0">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="white"
                                            className="size-8"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.49 12 3.75 3.75m0 0-3.75 3.75m3.75-3.75H3.74V4.499" />
                                        </svg>
                                    </div>
                                    <div className="bg-white py-1 my-1 px-2 flex-shrink-0">
                                        <p className="text-base font-medium text-center md:text-left">Step 2: Application Form</p>
                                    </div>
                                    <div className="px-2 py-2">
                                        <p>
                                            After registering, fill out the online application form. You will need to upload your academic records, identity documents, and provide details about the program you wish to apply for. Ensure that you complete all the sections accurately to avoid delays in the admission process.
                                        </p>
                                    </div>
                                </div>

                                {/* Step 3 */}
                                <div className="flex flex-col md:flex-row items-start py-2 w-full">
                                    <div className="flex-shrink-0">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="white"
                                            className="size-8"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.49 12 3.75 3.75m0 0-3.75 3.75m3.75-3.75H3.74V4.499" />
                                        </svg>
                                    </div>
                                    <div className="bg-white py-1 my-1 px-2 flex-shrink-0">
                                        <p className="text-base font-medium text-center md:text-left">Step 3: LPU NEST Entrance Exam</p>
                                    </div>
                                    <div className="px-2 py-2">
                                        <p>
                                            Depending on the course you are applying for, you may need to take the LPU NEST (National Entrance and Scholarship Test). The entrance test is mandatory for many programs and is also a gateway for scholarships. Schedule your exam date and complete it within the provided timeline.
                                        </p>
                                    </div>
                                </div>

                                {/* Step 4 */}
                                <div className="flex flex-col md:flex-row items-start py-2 w-full">
                                    <div className="flex-shrink-0">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="white"
                                            className="size-8"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.49 12 3.75 3.75m0 0-3.75 3.75m3.75-3.75H3.74V4.499" />
                                        </svg>
                                    </div>
                                    <div className="bg-white py-1 my-1 px-2 flex-shrink-0">
                                        <p className="text-base font-medium text-center md:text-left">Step 4: Interview & Counseling</p>
                                    </div>
                                    <div className="px-2 py-2">
                                        <p>
                                            After passing the LPU NEST, shortlisted candidates will be called for an interview or counseling session. This may be done in person or online, depending on your location. Be prepared to discuss your academic interests, program details, and why you are a good fit for the course.
                                        </p>
                                    </div>
                                </div>

                                {/* Step 5 */}
                                <div className="flex flex-col md:flex-row items-start py-2 w-full">
                                    <div className="flex-shrink-0">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="white"
                                            className="size-8"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.49 12 3.75 3.75m0 0-3.75 3.75m3.75-3.75H3.74V4.499" />
                                        </svg>
                                    </div>
                                    <div className="bg-white py-1 my-1 px-2 flex-shrink-0">
                                        <p className="text-base font-medium text-center md:text-left">Step 5: Fee Payment & Enrollment</p>
                                    </div>
                                    <div className="px-2 py-2">
                                        <p>
                                            Upon successful completion of the interview/counseling, you will receive an offer of admission. At this stage, you must confirm your seat by paying the initial tuition fees. Once the payment is processed, your enrollment is confirmed and you can begin your journey at LPU.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Toggle Documents Section */}
            <div className="py-5">
                <button 
                    onClick={toggleDocuments} 
                    className="text-white bg-[#3ACA2E] px-4 py-2 rounded-md w-full md:w-auto block mx-auto md:mx-0"
                >
                    {showDocuments ? "- Documents Required for Admission" : "+ Documents Required for Admission"}
                </button>

                {showDocuments && (
                    <div className="mt-4 bg-white p-4 rounded-md shadow-md mx-auto w-full max-w-lg">
                        <p className="text-lg font-semibold mb-2">List of Documents Required:</p>
                        <ul className="list-disc list-inside ml-4">
                            <li>10th and 12th Marksheets</li>
                            <li>Graduation Degree (for PG programs)</li>
                            <li>Passport size photographs</li>
                            <li>Identity Proof (Aadhaar card, Passport, etc.)</li>
                            <li>Category Certificate (if applicable)</li>
                            <li>LPU NEST scorecard (for entrance-based admissions)</li>
                            <li>Other relevant certificates as per the course requirements</li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Admission;
