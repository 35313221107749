import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select'; // Import react-select for dropdowns
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { LuPlus } from "react-icons/lu";
import { Base_URL } from '../../../apiConfig'; // Assuming you have a base URL defined

const AddCourses = () => {
  const [formData, setFormData] = useState({
    program_id: '', 
    course_name: '',
    course_fees: '',
    course_duration: '',
    exams_accepted: [],
    course_description: '',
  });

  const [newExam, setNewExam] = useState('');
  const [universities, setUniversities] = useState([]); // Store universities data
  const [programs, setPrograms] = useState([]); // Store programs for selected university
  const [selectedUniversity, setSelectedUniversity] = useState(null); // Selected university
  const [selectedProgram, setSelectedProgram] = useState(null); // Selected program

  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  // Fetch universities when component mounts
  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const response = await axios.get(`${Base_URL}/universities`);
        const universityOptions = response.data.map(university => ({
          value: university.university_id,
          label: university.university_name,
        }));
        setUniversities(universityOptions);
      } catch (error) {
        toast.error('Failed to load universities');
      }
    };
    fetchUniversities();
  }, []);

  // Fetch programs when a university is selected
  useEffect(() => {
    const fetchPrograms = async () => {
      if (!selectedUniversity) return;

      try {
        const response = await axios.get(`${Base_URL}/programs/university/${selectedUniversity.value}`);
        const programOptions = response.data.map(program => ({
          value: program.program_id,
          label: program.program_name,
        }));
        setPrograms(programOptions);
      } catch (error) {
        toast.error('Failed to load programs for the selected university');
      }
    };
    fetchPrograms();
  }, [selectedUniversity]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleExamAdd = (e) => {
    e.preventDefault();
    if (newExam && !formData.exams_accepted.includes(newExam)) {
      setFormData((prev) => ({ ...prev, exams_accepted: [...prev.exams_accepted, newExam] }));
      setNewExam('');
    }
  };

  const handleExamRemove = (exam) => {
    setFormData((prev) => ({
      ...prev,
      exams_accepted: prev.exams_accepted.filter((e) => e !== exam),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.course_name || !formData.course_fees || !formData.course_duration || !selectedUniversity || !selectedProgram) {
      toast.error('Please fill all the required fields');
      return;
    }

    const dataToSubmit = {
      ...formData,
      university_id: selectedUniversity.value,
      program_id: selectedProgram.value,
    };

    try {
      await axios.post(`${Base_URL}/courses`, dataToSubmit, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      toast.success('Course created successfully!');
      setFormData({
        program_id: '',
        course_name: '',
        course_fees: '',
        course_duration: '',
        exams_accepted: [],
        course_description: '',
      });
      setSelectedUniversity(null); // Reset university selection
      setSelectedProgram(null); // Reset program selection
    } catch (error) {
      toast.error('Failed to create course. Please try again.');
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md">
        <h2 className="text-xl font-semibold mb-4">Add Course</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            
            {/* Select University */}
            <div className="flex flex-col">
              <label htmlFor="university_id" className="mb-2 font-medium text-sm">Select University</label>
              <Select
                options={universities}
                value={selectedUniversity}
                onChange={(selectedOption) => setSelectedUniversity(selectedOption)}
                placeholder="Select a university"
                className="basic-single"
                classNamePrefix="select"
                isClearable
              />
            </div>

            {/* Select Program */}
            <div className="flex flex-col">
              <label htmlFor="program_id" className="mb-2 font-medium text-sm">Select Program</label>
              <Select
                options={programs}
                value={selectedProgram}
                onChange={(selectedOption) => setSelectedProgram(selectedOption)}
                placeholder="Select a program"
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isDisabled={!selectedUniversity} // Disable until a university is selected
              />
            </div>

            {/* Course Name */}
            <div className="flex flex-col">
              <label htmlFor="course_name" className="mb-2 font-medium text-sm">Course Name</label>
              <input
                type="text"
                id="course_name"
                name="course_name"
                value={formData.course_name}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Course Name"
                required
              />
            </div>

            {/* Course Fees */}
            <div className="flex flex-col">
              <label htmlFor="course_fees" className="mb-2 font-medium text-sm">Course Fees</label>
              <input
                type="text"
                id="course_fees"
                name="course_fees"
                value={formData.course_fees}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Course Fees"
                required
              />
            </div>

            {/* Course Duration */}
            <div className="flex flex-col">
              <label htmlFor="course_duration" className="mb-2 font-medium text-sm">Course Duration</label>
              <input
                type="text"
                id="course_duration"
                name="course_duration"
                value={formData.course_duration}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Course Duration"
                required
              />
            </div>

            {/* Exams Accepted */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="exams_accepted" className="mb-2 font-medium text-sm">Exams Accepted</label>
              <div className="flex items-center">
                <input
                  type="text"
                  id="new_exam"
                  value={newExam}
                  onChange={(e) => setNewExam(e.target.value)}
                  className="border w-full border-gray-300 p-2 rounded mr-2"
                  placeholder="Enter Exam"
                />
                <button onClick={handleExamAdd} className="p-3 bg-blue-600 text-white rounded-md">
                  <LuPlus />
                </button>
              </div>
              <div className="mt-2">
                {formData.exams_accepted.map((exam, index) => (
                  <div key={index} className="inline-flex items-center mr-2 mb-2 bg-gray-200 p-2 rounded">
                    {exam}
                    <button
                      type="button"
                      className="ml-2 text-red-600"
                      onClick={() => handleExamRemove(exam)}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Course Description */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="course_description" className="mb-2 font-medium text-sm">Course Description</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.course_description}
                onChange={(event, editor) => setFormData((prev) => ({ ...prev, course_description: editor.getData() }))}
              />
            </div>
          </div>
          <button type="submit" className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md">Submit</button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddCourses;
