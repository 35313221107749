// import React from 'react'
// import landing_video from '../../../assets/landing/landing_video.png'

// const LandingVideo = () =>{
//     return(
//         <div>
//             <div className="relative h-[500px]">
//                 <img src={landing_video} alt="" className="h-full w-full object-cover" />

//                 <p className="absolute top-0 w-full text-center py-10 text-3xl font-bold text-white mb-20">Find Your Future: Watch to Discover Your Path </p>

//                 <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
//                     <button className="bg-white h-[80px] w-[80px] rounded-[40px] flex items-center justify-center">
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="#282828" viewBox="0 0 24 24" stroke-width="1.5" stroke="#282828" className="h-[60px] ml-2">
//                             <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
//                         </svg>
//                     </button>
//                 </div>
//             </div>

//             <div className="py-8 px-5 bg-[#002147] flex items-center gap-10">
//                 <div className="text-white flex flex-col gap-3 px-5">
//                     <p className="text-2xl font-bold">Not sure which career suits you best?</p>
//                     <p className="text-xl font-semibold">Find your perfect fit with College Dwarka!</p>
//                     <p className="text-lg">Our unique system matches your skills, personality, and interests to the perfect career path.</p>
//                 </div>
//                 <div className="flex items-center justify-center px-20">
//                     <button className="text-white bg-[#3ACA2E] py-2 px-5 rounded-xl">GET CAREER RECOMMENDATIONS</button>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default LandingVideo;
import React from 'react';
import landing_video from '../../../assets/landing/landing_video.png';

const LandingVideo = () => {
    return (
        <div>
            <div className="relative h-[500px] md:h-[400px]">
                <img src={landing_video} alt="" className="h-full w-full object-cover" />

                <p className="absolute top-0 w-full text-center py-10 text-[26px] font-sans md:text-2xl lg:text-3xl font-bold text-white mb-10 md:mb-16">Find Your Future: Watch to Discover Your Path</p>

                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <button className="bg-white h-[60px] w-[60px] md:h-[70px] md:w-[70px] lg:h-[80px] lg:w-[80px] rounded-full flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#282828" viewBox="0 0 24 24" stroke-width="1.5" stroke="#282828" className="h-[40px] md:h-[50px] lg:h-[60px] ml-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                        </svg>
                    </button>
                </div>
            </div>

            <div className="py-6 md:py-8 px-4 md:px-5 bg-[#002147] flex flex-col md:flex-row items-center gap-6 md:gap-10h-[470px] md:h-[331px] font-sans ">
                <div className="text-white text-center md:text-left flex flex-col gap-2 md:gap-3 px-4 md:px-5">
                    <p className="text-[24px] md:text-2xl font-sans font-[500] ">Not sure which career suits you best?</p>
                    <p className="text-[20px] md:text-xl  font-[500]">Find your perfect fit with College Dwarka!</p>
                    <p className="text-[16px] md:text-lg font-[500]">Our unique system matches your skills, personality, and interests to the perfect career path.</p>
                </div>
                <div className="flex items-center justify-center px-4 md:px-4 ">
                    <button className="text-white md:w-[360px] md:h-[60px] md:rounded-full bg-[#57CC02] py-2 px-4 md:py-2 md:px-5 rounded-full text-base md:text-[20px] ">GET CAREER RECOMMENDATIONS</button>
                </div>
            </div>
        </div>
    );
}

export default LandingVideo;
