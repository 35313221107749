import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LuPlus } from "react-icons/lu";
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import 'react-toastify/dist/ReactToastify.css';

const EditCourses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const course = location.state; // The course data passed from AllCourses

  const [formData, setFormData] = useState({
    course_id: course?.course_id || '',
    program_id: course?.program_id || '',
    university_name: course?.university_name || '',
    course_name: course?.course_name || '',
    course_fees: course?.course_fees || '',
    course_duration: course?.course_duration || '',
    exams_accepted: course?.exams_accepted || [],
    course_description: course?.course_description || '',
  });
  const [newExam, setNewExam] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleExamAdd = (e) => {
    e.preventDefault();
    if (newExam && !formData.exams_accepted.includes(newExam)) {
      setFormData(prev => ({ ...prev, exams_accepted: [...prev.exams_accepted, newExam] }));
      setNewExam('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make API call to update course details
      const updatedCourse = { ...formData };
      await axios.put(`${Base_URL}/courses/${course.course_id}`, updatedCourse);
      toast.success('Course updated successfully!');
      navigate('/admin/courses/all');
    } catch (error) {
      toast.error('Failed to update course.');
      console.error('Failed to update course:', error.response?.data || error.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-gray-100 p-6">
        <HeaderTitle mainTitle="Edit Course" subTitle="Update Course Details" />

        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Program ID */}
              <div className="flex flex-col">
                <label htmlFor="program_id" className="mb-2 font-medium text-sm">Program ID</label>
                <input
                  type="text"
                  id="program_id"
                  name="program_id"
                  value={formData.program_id}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                  disabled // Program ID is not editable
                />
              </div>

              {/* University Name */}
              <div className="flex flex-col">
                <label htmlFor="university_name" className="mb-2 font-medium text-sm">University Name</label>
                <input
                  type="text"
                  id="university_name"
                  name="university_name"
                  value={formData.university_name}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                  disabled // University name is not editable
                />
              </div>

              {/* Course Name */}
              <div className="flex flex-col">
                <label htmlFor="course_name" className="mb-2 font-medium text-sm">Course Name</label>
                <input
                  type="text"
                  id="course_name"
                  name="course_name"
                  value={formData.course_name}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              {/* Course Fees */}
              <div className="flex flex-col">
                <label htmlFor="course_fees" className="mb-2 font-medium text-sm">Course Fees</label>
                <input
                  type="text"
                  id="course_fees"
                  name="course_fees"
                  value={formData.course_fees}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              {/* Course Duration */}
              <div className="flex flex-col">
                <label htmlFor="course_duration" className="mb-2 font-medium text-sm">Course Duration</label>
                <input
                  type="text"
                  id="course_duration"
                  name="course_duration"
                  value={formData.course_duration}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              {/* Exams Accepted */}
              <div className="flex flex-col md:col-span-2">
                <label htmlFor="exams_accepted" className="mb-2 font-medium text-sm">Exams Accepted</label>
                <div className="flex items-center">
                  <input
                    type="text"
                    id="new_exam"
                    value={newExam}
                    onChange={(e) => setNewExam(e.target.value)}
                    className="border w-full border-gray-300 p-2 rounded mr-2"
                    placeholder="Enter exam"
                  />
                  <button onClick={handleExamAdd} className="p-3 bg-blue-600 text-white rounded-md">
                    <LuPlus />
                  </button>
                </div>
                <div className="mt-2">
                  {formData.exams_accepted.map((exam, index) => (
                    <div key={index} className="inline-flex items-center mr-2 mb-2 bg-gray-200 p-2 rounded">
                      {exam}
                      <button
                        type="button"
                        className="ml-2 text-red-600"
                        onClick={() => setFormData(prev => ({
                          ...prev,
                          exams_accepted: prev.exams_accepted.filter(e => e !== exam),
                        }))}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Course Description */}
              <div className="flex flex-col md:col-span-2">
                <label htmlFor="course_description" className="mb-2 font-medium text-sm">Course Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.course_description}
                  onChange={(event, editor) => setFormData({ ...formData, course_description: editor.getData() })}
                />
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md">Update Course</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditCourses;
