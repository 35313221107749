import React from 'react';
import college_logo from '../../../assets/college_logo.png';

const Scholarship = () => {
    return (
        <div className="px-5 py-8 bg-[#DCFFC4]">

            {/* University Header */}
            <div className="flex flex-col items-center md:flex-row md:items-center md:justify-center gap-3 mb-5">
                <div className="md:h-[80px] md:w-[80px] h-[50px] w-[50px]">
                    <img src={college_logo} alt="college-logo" className="h-full w-full object-cover" />
                </div>
                <p className="md:text-3xl text-[22px] text-black md:font-semibold  font-[700] text-left md:text-left">
                     Lovely Professional University
                </p>
            </div>

            {/* Popular Scholarships Title */}
            <div className="mb-5">
                <p className="py-3 text-center text-lg text-black font-[500]">About Scholarship 2024</p>
            </div>

            {/* LPU Scholarship Details */}
            <div className="">
                <p className="mb-4">
                    <strong>LPU Scholarships 2024:</strong> LPU scholarships are offered for all the programmes of the university, ranging from Diplomas to PhD. Students were awarded with scholarships worth Rs. 100+ crores in the last year itself.
                </p>
                <p className="mb-4">
                    All the AICTE-approved colleges were eligible for GPAT-based scholarships as well as the AICTE-M.Arch scholarships. The same has been discontinued from 2023. However, LPU is still continuing with its GPAT-based scholarships, and interested candidates can apply for the same.
                </p>

                <p className="mb-4 font-semibold">LPU Scholarship Categories:</p>

                <p className="mb-4">
                    Scholarships are awarded to candidates based on their performance on any of the following categories:
                </p>

                {/* Merit Scholarship Bullet List */}
                <ul className="list-disc ml-6 mb-4">
                    <li><strong>Merit Scholarship:</strong> Scholarships are awarded to candidates based on their performance on any of the following categories:</li>
                    <ul className="list-disc ml-6">
                        <li>Eligibility Qualification: (for example, on the basis of performance in 10+2 for Undergraduate Programmes and graduation for PG Programmes)</li>
                        <li>Qualification previous to eligibility qualification: This scholarship is applicable After Graduation Programmes, scholarship on the basis of qualification previous to eligibility qualification.</li>
                        <li>LPU's Entrance and Scholarship Test: LPUNEST</li>
                        <li>Certain National Level Tests (NEET, UCEED, CAT, MAT, GATE, GPAT, MAT, etc.)</li>
                        <li>Scholarship based on Innovation, Start-up & Entrepreneurship</li>
                        <li>Performance in Sports, Cultural, R&D, Co-curricular, Social Service, and Bravery Awards</li>
                        <li>Ph.D. Programmes Scholarship</li>
                        <li>For Toppers of Centre and State Boards</li>
                    </ul>
                </ul>

                {/* Financial Aid Bullet List */}
                <ul className="list-disc ml-6 mb-4">
                    <li><strong>Financial Aid:</strong></li>
                    <ul className="list-disc ml-6">
                        <li>Need-Based</li>
                        <li>Defence and Central Armed Police Forces (CAPF) personnel and their dependants</li>
                        <li>For Orphan Applicants</li>
                        <li>For Persons with Certain Disabilities</li>
                    </ul>
                </ul>

                {/* LPU NEST Scholarship Section */}
                <p className="mb-4 font-semibold">LPU NEST Scholarship:</p>

                <p className="mb-4">
                    Lovely Professional University conducts the **LPU NEST** (National Entrance and Scholarship Test) to offer scholarships for various programmes including Engineering, Management, Law, and more.
                </p>
                <ul className="list-disc ml-6 mb-4">
                    <li>Based on the performance in **LPU NEST**, students can avail scholarships that cover up to 100% of the tuition fees.</li>
                    <li>There are three different brackets of scholarships under LPU NEST:</li>
                    <ul className="list-disc ml-6">
                        <li>**Bracket 1**: 90% to 100% fee waiver for top-performing students</li>
                        <li>**Bracket 2**: 50% to 90% fee waiver for students with moderate scores</li>
                        <li>**Bracket 3**: 20% to 50% fee waiver for students who meet the minimum eligibility criteria</li>
                    </ul>
                    <li>**Eligibility**: The test is open to all students who have completed or are about to complete their 12th or graduation, depending on the programme they wish to apply for.</li>
                    <li>**Application Process**: Candidates must register for LPU NEST online, and the test can be taken either remotely from home or at designated centers.</li>
                </ul>
            </div>

        </div>
    );
};

export default Scholarship;
