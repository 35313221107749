import React, { useState, useEffect } from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
//import dummyNews from './dummyNews';

const AllNews = () => {
  const [newsList, setNewsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = newsList.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(newsList.length / entriesPerPage);

  useEffect(() => {
    console.log('Fetching news');
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${Base_URL}/news-announcements/university/1`);
        console.log(response.data);
        setNewsList(response.data.reverse());
      } catch (error) {
        console.error('Error fetching news:', error.response ? error.response.data : error.message);
      }
    };
  
    fetchNews();
  }, []);
  
  const handleEditClick = (id) => {
    navigate(`/dashboard/news/edit/${id}`);
  };
  
  const handleDeleteClick = async (id) => {
    console.log('Deleting news with ID:', id);
    const universityId = 1; // Ensure this is the correct university ID
  {
      try {
        const url = `${Base_URL}/news-announcements/${id}`;
        console.log('DELETE request URL:', url);
        await axios.delete(url);
        setNewsList(prevNewsList => prevNewsList.filter(newsList => newsList.announcement_id !== id));
        toast.success('Placements deleted successfully.');
      } catch (error) {
        console.error('Error deleting placements:', error.response ? error.response.data : error.message);
        toast.error('Failed to delete placements.');
      }
    }
  };
  
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  
    // const filteredEntries = currentEntries.filter((newsList) =>
    //   newsList.student_name.toLowerCase().includes(searchTerm.toLowerCase())
    // );

  // useEffect(() => {
  //   setNewsList(dummyNews);
  // }, []);

  // const indexOfLastEntry = currentPage * entriesPerPage;
  // const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  // const currentEntries = newsList.slice(indexOfFirstEntry, indexOfLastEntry);

  // const totalPages = Math.ceil(newsList.length / entriesPerPage);

  // const handlePrevious = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  // const handleNext = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const handleDeleteClick = async (id) => {
  //   try {
  //     await axios.delete(`${Base_URL}/news-announcements/${id}`);
  //     setNewsList(newsList.filter((news) => news.id !== id));
  //   } catch (error) {
  //     console.error('Error deleting news:', error);
  //   }
  // };

  // const handleEditClick = (id) => {
  //   navigate(`/news/edit/${id}`);
  // };

  const filteredEntries = currentEntries.filter((newsList) =>
    newsList.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All News" breadcrumb={['News', 'All News']} />

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div
            className="font-poppins"
            style={{
              color: 'rgb(49, 42, 42)',
              fontSize: '18.5px',
              fontWeight: 600,
              lineHeight: '26.25px',
              height: '45px',
            }}
          >
            All News
          </div>
          <div className="flex items-center space-x-4">
            <button
              className="px-4 py-2 bg-[rgb(106,115,250)] text-white rounded-bl-[5px] rounded-br-[5px] text-sm hover:bg-hoverblue"
              onClick={() => navigate('/dashboard/news/add')}
            >
              + Add New
            </button>
          </div>
        </div>

        <div className="flex items-center justify-between mb-4 pb-2 ">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span
              className="text-sm text-gray-600"
              style={{
                color: 'rgb(130, 130, 130)',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '14px',
              }}
            >
              Search:
            </span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-white">
                {['Title', 'Content', 'Date', 'Actions'].map((heading) => (
                  <th key={heading} className="text-left border-b" style={{ color: 'rgb(49, 42, 42)', fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: 600, lineHeight: '21px', padding: '14px 13px', borderBottomColor: 'rgb(230, 230, 230)' }}>
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredEntries.length > 0 ? (
                filteredEntries.map((news) => (
                  <tr key={news.announcement_id} className="hover:bg-white hover:shadow-md" style={{ borderTopWidth: '1px', borderTopColor: 'rgb(230, 230, 230)',verticalAlign: 'top' }}>
                    {/* <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      <img
                        src={news.picture || '/path/to/default/image.jpg'}
                        alt={news.title}
                        className="rounded-full"
                        style={{ width: '35px', verticalAlign: 'middle' }}
                      />
                    </td> */}
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      {news.title}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      {news.content}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',  }}>
                      {new Date(news.announcement_date).toLocaleDateString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditClick(news.announcement_id)}
                          className="p-1 rounded bg-blue-500 text-white hover:bg-blue-700"
                        >
                          <HiPencil size={18} />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(news.announcement_id)}
                          className="p-1 rounded bg-red-500 text-white hover:bg-red-700"
                        >
                          <HiTrash size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">No news found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          
          <div className="flex items-center">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-customblue text-white rounded-l"
            >
              Previous
            </button>
            <span className="px-4 py-2">{`Page ${currentPage} of ${totalPages}`}</span>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-customblue text-white rounded-r"
            >
              Next
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllNews;
// import React, { useState, useEffect } from 'react';
// import { HiPencil, HiTrash } from 'react-icons/hi';
// import { useNavigate } from 'react-router-dom';
// import HeaderSection from '../headerSection/HeaderSection';
// import axios from 'axios';
// import { Base_URL } from '../../../apiConfig';

// const AllNews = () => {
//   const [newsList, setNewsList] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [entriesPerPage, setEntriesPerPage] = useState(20);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [totalPages, setTotalPages] = useState(0);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchNews = async () => {
//       try {
//         const response = await axios.get(`${Base_URL}/news-announcements`);
//         setNewsList(response.data);
//         setTotalPages(Math.ceil(response.data.length / entriesPerPage));
//       } catch (error) {
//         console.error('Error fetching news:', error);
//       }
//     };

//     fetchNews();
//   }, [entriesPerPage]);

//   const indexOfLastEntry = currentPage * entriesPerPage;
//   const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
//   const currentEntries = newsList.slice(indexOfFirstEntry, indexOfLastEntry);

//   const handlePrevious = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const handleNext = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handleDeleteClick = async (id) => {
//     try {
//       await axios.delete(`${Base_URL}/news-announcements/${id}`);
//       setNewsList(newsList.filter((news) => news.id !== id));
//       setTotalPages(Math.ceil(newsList.length / entriesPerPage));
//     } catch (error) {
//       console.error('Error deleting news:', error);
//     }
//   };

//   const handleEditClick = (id) => {
//     navigate(`/news/edit/${id}`);
//   };

//   const filteredEntries = currentEntries.filter((news) =>
//     news.title.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div className="p-6 bg-gray-100 min-h-screen">
//       <HeaderSection title="All News" breadcrumb={['News', 'All News']} />

//       <div className="bg-white p-6 rounded-lg shadow">
//         <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
//           <div
//             className="font-poppins"
//             style={{
//               color: 'rgb(49, 42, 42)',
//               fontSize: '18.5px',
//               fontWeight: 600,
//               lineHeight: '26.25px',
//               height: '45px',
//             }}
//           >
//             All News
//           </div>
//           <div className="flex items-center space-x-4">
//             <button
//               className="px-4 py-2 bg-[rgb(106,115,250)] text-white rounded-bl-[5px] rounded-br-[5px] text-sm hover:bg-[rgb(82,89,199)] hover:text-[13px]"
//               onClick={() => navigate('/news/add')}
//             >
//               + Add New
//             </button>
//           </div>
//         </div>

//         <div className="flex items-center justify-end space-x-4" style={{ height: '75px', padding: '0 20px' }}>
//           <div className="flex items-center text-gray-600" style={{ marginRight: '20px' }}>
//             <span
//               className="mr-2 text-sm"
//               style={{
//                 color: 'rgb(130, 130, 130)',
//                 fontFamily: 'Poppins, sans-serif',
//                 fontSize: '14px',
//               }}
//             >
//               Search:
//             </span>
//             <input
//               type="text"
//               className="px-3 py-1 border border-gray-300 rounded"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//               style={{
//                 height: '30px',
//                 width: '200px',
//                 borderRadius: '5px',
//                 borderColor: '#d0d0d0',
//                 padding: '0 10px',
//               }}
//             />
//           </div>
//         </div>

//         <div className="overflow-x-auto">
//           <table className="min-w-full bg-white">
//             <thead>
//               <tr className="bg-white">
//                 {['Picture', 'Title', 'Content', 'Date', 'Actions'].map((heading) => (
//                   <th key={heading} className="text-left border-b" style={{ color: 'rgb(49, 42, 42)', fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: 600, lineHeight: '21px', padding: '14px 13px', borderBottomColor: 'rgb(230, 230, 230)' }}>
//                     {heading}
//                   </th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {filteredEntries.length > 0 ? (
//                 filteredEntries.map((news) => (
//                   <tr key={news.id} className="hover:bg-white hover:shadow-md" style={{ borderTopWidth: '1px', borderTopColor: 'rgb(230, 230, 230)'}}>
//                     <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)' }}>
//                       <img
//                         src={news.picture || '/path/to/default/image.jpg'}
//                         alt={news.title}
//                         className="rounded-full"
//                         style={{ width: '35px', verticalAlign: 'middle' }}
//                       />
//                     </td>
//                     <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)' }}>
//                       {news.title}
//                     </td>
//                     <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)' }}>
//                       {news.content}
//                     </td>
//                     <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)' }}>
//                       {new Date(news.announcement_date).toLocaleDateString('en-IN', {
//                         day: '2-digit',
//                         month: '2-digit',
//                         year: 'numeric',
//                       })}
//                     </td>
//                     <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)' }}>
//                       <div className="flex space-x-2">
//                         <button
//                           onClick={() => handleEditClick(news.id)}
//                           className="p-1 rounded bg-blue-500 text-white hover:bg-blue-700"
//                         >
//                           <HiPencil size={18} />
//                         </button>
//                         <button
//                           onClick={() => handleDeleteClick(news.id)}
//                           className="p-1 rounded bg-red-500 text-white hover:bg-red-700"
//                         >
//                           <HiTrash size={18} />
//                         </button>
//                       </div>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="5" className="text-center py-4">No news found</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         <div className="flex justify-between items-center mt-4">
//           <div className="flex items-center">
//             <span className="text-sm text-gray-600 mr-2">Show:</span>
//             <select
//               value={entriesPerPage}
//               onChange={(e) => setEntriesPerPage(Number(e.target.value))}
//               className="px-2 py-1 border border-gray-300 rounded"
//             >
//               {[10, 20, 30, 40, 50].map((size) => (
//                 <option key={size} value={size}>
//                   {size}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className="flex items-center space-x-2">
//             <button
//               onClick={handlePrevious}
//               disabled={currentPage === 1}
//               className="px-4 py-2 bg-gray-300 text-white rounded"
//             >
//               Previous
//             </button>
//             <span className="text-sm text-gray-600">
//               Page {currentPage} of {totalPages}
//             </span>
//             <button
//               onClick={handleNext}
//               disabled={currentPage === totalPages}
//               className="px-4 py-2 bg-gray-300 text-white rounded"
//             >
//               Next
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AllNews;
