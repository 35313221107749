import React from 'react';

const MenuIcon = ({isCollapsed}) => (
  <div className="relative w-8 h-5  flex flex-col justify-between cursor-pointer group">
    {/* Top Line */}
    <div className={` h-0.5 bg-customblue transition-all duration-300 ease-in-out ${isCollapsed?'rotate-45 w-3 translate-y-1 translate-x-1':'rotate-0 w-3 group-hover:w-8 '}  `}></div>
    {/* Middle Line */}
    <div className={`w-4 h-0.5 bg-customblue transition-all duration-300 ease-in-out  ${isCollapsed?'w-6 -translate-x-2   ':'rotate-0 w-6 group-hover:w-8'}`}></div>
    {/* Bottom Line */}
    <div className={` h-0.5 bg-customblue transition-all duration-300 ease-in-out ${isCollapsed?'-rotate-45 w-3 -translate-y-1 translate-x-1':'rotate-0 w-3  group-hover:w-8'} `}></div>
  </div>
);

export default MenuIcon;

