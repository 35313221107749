// import React, { useEffect, useState } from 'react';
// import './college.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// //import college from '../../../assets/landing/college.png'; // Placeholder image
// import Graphic from '../../../assets/landing/Graphic.png'; 
// import LPU from '../../../assets/landing/LPU.jpg'; 
// import Tula from '../../../assets/landing/Tula.jpg'; 
// import CU from '../../../assets/landing/CU.jpg'; 
// import { Base_URL,Img_URL } from '../../../apiConfig';



// const Colleges = () => {
//     // State variables
//     const [collegeData, setCollegeData] = useState([]); // To store fetched data
//     const [loading, setLoading] = useState(true); // To manage loading state
//     const [error, setError] = useState(null); // To handle errors

//     // Dummy data to show in case of an error
//     const defaultColleges = [
//         { name: 'LPU University', image: LPU},
//         { name:'Graphic era', image: Graphic },
//         { name: 'Tula Institute', image: Tula },
//         { name: 'Chandigarh University', image: CU },
//     ];

//     // Fetch data from the backend
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await fetch(`${Base_URL}/universities/popular/colleges`);
//                 if (!response.ok) {
//                     throw new Error('Failed to fetch data');
//                 }
//                 const data = await response.json();
//                 console.log('Fetched college data:', data); // Log the fetched data
//                 setCollegeData(data);
//             } catch (error) {
//                 console.error('Error fetching college data:', error); // Log the error
//                 setError(error.message); // Set error message
//             } finally {
//                 setLoading(false); // Always set loading to false after fetching
//             }
//         };

//         fetchData();
//     }, []);

//     const settings = {
//         customPaging: function(i) {
//             return (
//                 <a>
//                     <div className="dot"></div>
//                 </a>
//             );
//         },
//         dots: true,
//         infinite: true,
//         speed: 500,
//         autoplay: true,
//         autoplaySpeed: 3000,
//         cssEase: "linear",
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         appendDots: dots => (
//             <div>
//                 <ul className="custom-dots">
//                     {dots}
//                 </ul>
//             </div>
//         ),
//     };

//     return (
//         <div>
//             <div className="bg-[#002147] p-5">
//                 <p className="text-white font-medium text-base text-center mb-8">POPULAR UNIVERSITY</p>
//                 <p className="text-white font-semibold text-lg text-center mb-24">List of Popular Universities for your right start</p>
//             </div>
//             <div className="transform -translate-y-32">
//                 <Slider {...settings}>
//                     {(error ? defaultColleges : collegeData).map((collegeItem, index) => {
//                         // Construct full image URL
//                         const imageUrl = collegeItem.pictures ? `${Img_URL}/${collegeItem.pictures}` : college;
                        
//                         // Log each college item and constructed image URL
//                         console.log('Rendering college item:', collegeItem);
//                         console.log('Image URL:', imageUrl);

//                         return (
//                             <div className="flex justify-center items-center p-16" key={index}>
//                                 <div className="relative w-full h-full">
//                                     <div className="w-full h-full rounded-[20px] overflow-hidden">
//                                         <img 
//                                             src={imageUrl} 
//                                             alt={collegeItem.university_name} 
//                                             className="w-full h-full object-cover" 
//                                             onError={(e) => {
//                                                 console.error('Failed to load image:', e);
//                                                 e.target.src = college; // Fallback image
//                                             }}
//                                         />
//                                     </div>
//                                     <div className="absolute bottom-0 left-0 p-3">
//                                         <p className="bg-white rounded-[20px] px-3 py-1 mb-3 font-medium">
//                                             {collegeItem.university_name}
//                                         </p>
//                                         <button className="bg-[#3ACA2E] text-white rounded-[20px] px-3 py-1">
//                                             Apply Now
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         );
//                     })}
//                 </Slider>
//             </div>
//         </div>
//     );
// };

// export default Colleges;

import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import GE from '../../../assets/landing/GE.png'; 
import LPU1 from '../../../assets/landing/LPU1.png'; 
import Tula from '../../../assets/landing/Tula.jpg'; 
import CU from '../../../assets/landing/CU.jpg'; 
import college from '../../../assets/landing/college.png'; // Placeholder image

const Colleges = () => {
    // Static data for colleges
    const colleges = [
        { name: 'LPU University', image: LPU1 },
        { name: 'Graphic Era University', image: GE },
        { name: 'Tula Institute', image: Tula },
        { name: 'Chandigarh University', image: CU },
    ];

    const settings = {
        customPaging: function(i) {
            return (
                <a>
                    <div className="dot"></div>
                </a>
            );
        },
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        slidesToShow: 3, // Adjusted for better mobile experience
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // Tablets and larger screens
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768, // Tablets and smaller screens
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
        appendDots: dots => (
            <div>
                <ul className="custom-dots flex justify-center space-x-2 mt-4">
                    {dots}
                </ul>
            </div>
        ),
    };

    return (
        <div className="overflow-hidden">
            <div className="bg-[#002147] p-5 text-center">
                <p className="text-white font-medium text-base mb-8">POPULAR UNIVERSITIES</p>
                <p className="text-white font-semibold text-lg mb-24">List of Popular Universities to kick-start your journey</p>
            </div>
            <div className="relative -translate-y-32 mt-12">
                <Slider {...settings}>
                    {colleges.map((collegeItem, index) => {
                        // Construct full image URL
                        const imageUrl = collegeItem.image || college;
                        
                        return (
                            <div className="flex justify-center items-center px-4 py-2" key={index}>
                                <div className="relative w-full md:w-[300px] lg:w-[350px] h-[250px] rounded-[20px] overflow-hidden bg-gray-200">
                                    <img 
                                        src={imageUrl} 
                                        alt={collegeItem.name} 
                                        className="w-full h-full object-cover" 
                                        onError={(e) => {
                                            console.error('Failed to load image:', e);
                                            e.target.src = college; // Fallback image
                                        }}
                                    />
                                    <div className="absolute bottom-0 left-0 w-full p-3 bg-[rgb(0,33,71)] bg-opacity-75">
                                        <p className="bg-white rounded-[20px] px-3 py-1 mb-3 font-medium text-center">
                                            {collegeItem.name}
                                        </p>
                                        <button className="bg-[#3ACA2E] text-white rounded-[20px] px-3 py-1 w-full">
                                            Apply Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default Colleges;
