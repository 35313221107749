import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

const EditScholarship = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const scholarship = location.state; // Get the scholarship data passed from AllScholarships

  // State for the form data
  const [formData, setFormData] = useState({
    university_name: scholarship?.university_name || "", // Prefilled and displayed
    scholarship_desc: scholarship?.scholarship_desc || "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedScholarship = {
        scholarship_desc: formData.scholarship_desc,
      };

      // Make PUT request to update scholarship
      await axios.put(`http://213.210.37.39:3000/api/update/scholarships/${scholarship.scholarship_id}`, updatedScholarship);

      toast.success("Scholarship updated successfully!");
      navigate("/admin/scholarship/all"); // Navigate back to the scholarship list after success
    } catch (error) {
      console.error("Failed to update scholarship:", error.response?.data || error.message);
      toast.error("Failed to update scholarship. Please try again.");
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle="Scholarship" subTitle="Edit Scholarship" />

      <div className="bg-white p-4 rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* University Name */}
            <div className="flex flex-col">
              <label htmlFor="university_name" className="mb-2 font-medium text-sm">
                University Name
              </label>
              <input
                type="text"
                id="university_name"
                name="university_name"
                value={formData.university_name}
                className="border border-gray-300 p-2 rounded bg-gray-200"
                disabled
              />
            </div>

            {/* Scholarship Description (CKEditor) */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="scholarship_desc" className="mb-2 font-medium text-sm">
                Scholarship Description
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.scholarship_desc}
                onChange={(event, editor) => {
                  setFormData({ ...formData, scholarship_desc: editor.getData() });
                }}
              />
            </div>
          </div>

          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md"
          >
            Submit
          </button>
        </form>
      </div>

      {/* Toast for notifications */}
      <ToastContainer />
    </div>
  );
};

export default EditScholarship;
