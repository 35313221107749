import React from 'react';
import ChatCard from './ChatCard/ChatCard';

const Chat = () => {
  return (
    <div className="py-8 px-5 bg-[#DCFFC4]">
      {/* Chat with the Staff */}
      <div className="py-8 px-5">
        <p className="text-center text-black font-semibold text-lg mb-5">Chat with the Staff</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 justify-items-center">
          <ChatCard />
          <ChatCard />
          <ChatCard />
          <ChatCard />
          <ChatCard />
          <ChatCard />
        </div>
      </div>

      {/* Chat with Students */}
      <div className="py-8 px-5">
        <p className="text-center text-black font-semibold text-lg mb-5">Chat with Students</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 justify-items-center">
          <ChatCard />
          <ChatCard />
          <ChatCard />
          <ChatCard />
          <ChatCard />
          <ChatCard />
        </div>
      </div>
    </div>
  );
};

export default Chat;
