import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import '../../../styles/Editor.css';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { LuPlus } from "react-icons/lu";

const EditUniversity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const university = location.state; // Get university data passed from the list

  const [formData, setFormData] = useState({
    university_name: university?.university_name || '',
    main_intro: university?.main_intro || '', // Handled by CKEditor
    keywords: university?.keywords || [],
    website: university?.website || '',
    featuredcollege: university?.featuredcollege || '0', // Default value
    popularcollege: university?.popularcollege || '0', // Default value
  });

  const [logo, setLogo] = useState(null); // Logo upload
  const [pictures, setPictures] = useState([]); // Multiple image uploads
  const [newKeyword, setNewKeyword] = useState(''); // Keyword input

  useEffect(() => {
    // Load existing data for the university here if necessary
  }, [university]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'logo') {
      setLogo(files[0]);
    } else if (name === 'pictures') {
      setPictures([...files]);
    }
  };

  const handleKeywordAdd = (e) => {
    e.preventDefault();
    if (newKeyword && !formData.keywords.includes(newKeyword)) {
      setFormData(prev => ({ ...prev, keywords: [...prev.keywords, newKeyword] }));
      setNewKeyword(''); // Clear input after adding
    }
  };

  const handleKeywordRemove = (keyword) => {
    setFormData(prev => ({
      ...prev,
      keywords: prev.keywords.filter(k => k !== keyword),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.university_name || !formData.main_intro || !formData.website) {
      toast.error('Please fill all the required fields');
      return;
    }

    try {
      const data = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        data.append(key, Array.isArray(value) ? value.join(',') : value); // For keywords
      });
      if (logo) data.append('logo', logo); // Logo upload
      pictures.forEach(picture => data.append('pictures', picture)); // Multiple images

      // Update the university using PUT or PATCH
      await axios.put(`http://213.210.37.39:3000/api/universities/${university.university_id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('University updated successfully!');
      navigate("/admin/university/all"); // Redirect after successful update
    } catch (error) {
      console.error('Failed to update university:', error.response?.data || error.message);
      toast.error('Failed to update university. Please try again.');
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle="University" subTitle="Edit University" />
      <div className="bg-white p-4 rounded-md">
        <h2 className="text-xl font-semibold mb-4">Edit University</h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Text Input Fields */}
            {['university_name', 'website'].map((field) => (
              <div key={field} className="flex flex-col">
                <label htmlFor={field} className="mb-2 font-medium text-sm capitalize">
                  {field.replace('_', ' ')}
                </label>
                <input
                  type="text"
                  id={field}
                  name={field}
                  value={formData[field]}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                  placeholder={`Enter ${field.replace('_', ' ')}`}
                />
              </div>
            ))}

            {/* CKEditor for Main Intro */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="main_intro" className="mb-2 font-medium text-sm">University Main Intro</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.main_intro}
                onChange={(event, editor) => setFormData(prev => ({ ...prev, main_intro: editor.getData() }))}
              />
            </div>

            {/* Keywords Section */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="keywords" className="mb-2 font-medium text-sm">Keywords</label>
              <div className="flex items-center">
                <input
                  type="text"
                  id="new_keyword"
                  value={newKeyword}
                  onChange={(e) => setNewKeyword(e.target.value)}
                  className="border w-full border-gray-300 p-2 rounded mr-2"
                  placeholder="Enter keyword"
                />
                <button onClick={handleKeywordAdd} className="p-3 bg-blue-600 text-white rounded-md">
                  <LuPlus />
                </button>
              </div>
              <div className="mt-2">
                {formData.keywords.map((keyword, index) => (
                  <div key={index} className="inline-flex items-center mr-2 mb-2 bg-gray-200 p-2 rounded">
                    {keyword}
                    <button
                      type="button"
                      className="ml-2 text-red-600"
                      onClick={() => handleKeywordRemove(keyword)}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Dropdowns for Featured and Popular College */}
            {['featuredcollege', 'popularcollege'].map((field) => (
              <div key={field} className="flex flex-col">
                <label htmlFor={field} className="mb-2 font-medium text-sm capitalize">{field.replace('_', ' ')}</label>
                <select
                  id={field}
                  name={field}
                  value={formData[field]}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            ))}

            {/* Logo and Pictures Upload */}
            {[
              { label: 'Logo', name: 'logo', multiple: false },
              { label: 'Upload Pictures', name: 'pictures', multiple: true },
            ].map(({ label, name, multiple }) => (
              <div key={name} className="flex flex-col md:col-span-2">
                <label htmlFor={name} className="mb-2 font-medium text-sm">{label}</label>
                <input
                  type="file"
                  id={name}
                  name={name}
                  onChange={handleFileChange}
                  className="border border-gray-300 rounded"
                  multiple={multiple}
                />
              </div>
            ))}
          </div>

          <button type="submit" className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md">
            Update University
          </button>
        </form>

        {/* Toast Container */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditUniversity;
