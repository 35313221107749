import React, { useState } from 'react';
import { HiPencilAlt, HiTrash } from 'react-icons/hi';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { useNavigate, useLocation } from 'react-router-dom';

const AllUniversity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  const universities = [
    {
      universityId: 'U001',
      logo: 'path-to-logo1.jpg',
      university_name: 'Harvard University',
      main_intro: 'Harvard University is a private Ivy League research university in Cambridge, Massachusetts.',
      keywords: ['Ivy League', 'Harvard', 'Research'],
      website: 'https://www.harvard.edu',
      featuredcollege: 'Yes',
      popularcollege: 'Yes',
      joiningDate: '2020/07/25',
    },
    {
      universityId: 'U002',
      logo: 'path-to-logo2.jpg',
      university_name: 'Stanford University',
      main_intro: 'Stanford University is a private research university located in Stanford, California.',
      keywords: ['Stanford', 'Silicon Valley', 'Innovation'],
      website: 'https://www.stanford.edu',
      featuredcollege: 'No',
      popularcollege: 'Yes',
      joiningDate: '2021/09/14',
    },
    // More universities...
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(5);

  // Filter and Paginate universities based on search term and current page
  const filteredUniversities = universities.filter(university =>
    university.university_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalEntries = filteredUniversities.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const displayedUniversities = filteredUniversities.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePrevious = () => setCurrentPage(prev => (prev > 1 ? prev - 1 : prev));
  const handleNext = () => setCurrentPage(prev => (prev < totalPages ? prev + 1 : prev));

  return (
    <div className="bg-gray-100 p-6 h-screen overflow-auto">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md shadow-md">
        <div className="flex justify-between items-center mb-4 pb-2 border-b border-gray-200">
          <h2 className="text-xl font-semibold mb-4">{subTitle}</h2>
          <button className="bg-[#6a73fa] text-white px-4 py-2 rounded-md">+ Add New</button>
        </div>

        <div className="flex justify-end mb-4">
          <input
            type="text"
            className="px-3 py-1 border border-gray-300 rounded"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>

        <div className="grid grid-cols-10 gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md">
          <div>University Id</div>
          <div>Logo</div>
          <div>University Name</div>
          <div>Main Intro</div>
          <div>Keywords</div>
          <div>Website</div>
          <div>Featured College</div>
          <div>Popular College</div>
          <div>Joining Date</div>
          <div>Action</div>
        </div>

        {displayedUniversities.length > 0 ? (
          displayedUniversities.map((university) => (
            <div key={university.universityId} className="grid grid-cols-10 gap-2 text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100">
              <div>{university.universityId}</div>
              <div>
                <img src={university.logo} alt="Logo" className="w-8 h-8 rounded-full" />
              </div>
              <div className="truncate">{university.university_name}</div>
              <div className="truncate max-w-xs">{university.main_intro}</div>
              <div className="truncate max-w-xs">{university.keywords.join(', ')}</div>
              <div className="truncate">
                <a href={university.website} className="text-blue-600 hover:underline">
                  {university.website}
                </a>
              </div>
              <div className="truncate">{university.featuredcollege}</div>
              <div className="truncate">{university.popularcollege}</div>
              <div>{university.joiningDate}</div>
              <div className="flex space-x-2">
                <button
                  onClick={() => navigate(`/admin/university/edit/${university.universityId}`, { state: university })}
                  className="bg-blue-600 text-white p-2 rounded-md"
                >
                  <HiPencilAlt />
                </button>
                <button className="bg-red-600 text-white p-2 rounded-md">
                  <HiTrash />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-4">No universities found</div>
        )}

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => {
                setEntriesPerPage(Number(e.target.value));
                setCurrentPage(1); // Reset to first page when entries per page changes
              }}
              className="border border-gray-300 rounded"
            >
              {[5, 10, 20].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className={`px-4 py-2 rounded-md ${currentPage === 1 ? 'bg-[#6a73fa] text-white' : 'bg-blue-600 text-white'} disabled:opacity-50`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">Page {currentPage} of {totalPages}</span>
            <button
              onClick={handleNext}
              className={`px-4 py-2 rounded-md ${currentPage === totalPages ? 'bg-[#6a73fa] text-white' : 'bg-blue-600 text-white'} disabled:opacity-50`}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUniversity;
