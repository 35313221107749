// import React from 'react'
// import newsletter from '../../../assets/landing/newsletter.png'

// const Newsletter = ()=>{
//     return(
//         <div className="relative h-[400px] flex justify-center items-center py-8 px-5">
            // <div className="absolute top-0 left-0 w-full h-full z-[-1]"><img src={newsletter} alt="" className="w-full h-full object-cover"/></div>
            // <div className="flex flex-col items-center">
            //     <p className="text-white text-lg font-semibold mb-5">NEWSLETTER</p>
            //     <p className="text-white text-base mb-10">Stay in the Loop: Get the Latest Buzz on Campus News and Upcoming Events!</p>
            //     <div className="relative flex justify-center items-center bg-white rounded-3xl mb-12 rounded-xl overflow-hidden">
            //         <div className="text-white text-base bg-[#57CC02] h-[40px] flex items-center px-3">Subscribe</div>
            //         <input type="text" placeholder="Find your e-mail" className="w-[300px] h-[40px] border-0" />
            //     </div>
            // </div>
//         </div>
//     )
// }

// export default Newsletter;
import React from 'react';
import newsletter from '../../../assets/landing/newsletter.png';
// import'./newsletter.css';

const Newsletter = () => {
  return (
    <div className="relative h-auto md:h-[400px] flex flex-col md:flex-row justify-center items-center py-8 px-5 overflow-hidden">
     <div className="absolute top-0 left-0 w-full h-full z-[-1]"><img src={newsletter} alt="" className="w-full h-full object-cover"/></div>
            <div className="flex flex-col items-center">
                <p className="text-white text-lg font-semibold mb-5">NEWSLETTER</p>
                <p className="text-white text-base text-center mb-6 w-[346px] h-[42px] overflow-hidden text-ellipsis md:mb-10 md:w-auto md:h-auto">Stay in the Loop: Get the Latest Buzz on Campus News and Upcoming Events!</p>

                <div className="relative flex justify-center items-center bg-white rounded-[10px] mb-12  overflow-hidden w-[350px]">
                    <div className="text-white text-base bg-[#57CC02] h-[40px] flex items-center px-3">Subscribe</div>
                    <input type="text" placeholder="Find your e-mail" className="w-[300px] h-[40px] border-0" />
                </div>
            </div>
    </div>
  );
};

export default Newsletter;
