import React from 'react'
import college_logo from '../../../assets/college_logo.png'
import college_gal1 from '../../../assets/college_gal1.png'
import college_gal2 from '../../../assets/college_gal2.png'
import college_gal3 from '../../../assets/college_gal3.png'
import college_gal4 from '../../../assets/college_gal4.png'
import college_gal5 from '../../../assets/college_gal5.png'
import college_gal6 from '../../../assets/college_gal6.png'
import college_gal7 from '../../../assets/college_gal7.png'
import college_gal8 from '../../../assets/college_gal8.png'
import college_gal9 from '../../../assets/college_gal9.png'
import college_gal10 from '../../../assets/college_gal10.png'
import college_gal11 from '../../../assets/college_gal11.png'
import college_gal12 from '../../../assets/college_gal12.png'
import college_gal13 from '../../../assets/college_gal13.png'

const Gallery = () => {
    const imageSources = [
        college_gal1, college_gal2, college_gal3, college_gal4, college_gal5,
        college_gal6, college_gal7, college_gal8, college_gal9, college_gal10,
        college_gal11, college_gal12, college_gal13
    ];

    return (
        <div className="py-8 px-5 bg-[#DCFFC4]">
            <div className="text-black text-base font-medium flex flex-col gap-5">
                <div className="flex items-center justify-center gap-3 py-2">
                    <div className="md:h-[80px] md:w-[80px] h-[50px] w-[50px]">
                        <img src={college_logo} alt="college-logo" className="h-full w-full object-cover" />
                    </div>
                    <p className="md:text-3xl text-black md:font-semibold text-[20px] font-[700]">Lovely Professional University</p>
                </div>

                <p className="text-center text-lg text-black font-[500] mb-5">Gallery</p>
{/* 
                <p className="mb-8">
                    Lorem ipsum odor amet, consectetuer adipiscing elit. Laoreet volutpat ullamcorper donec duis quis, imperdiet tempus volutpat 
                    justo. Odio morbi ligula integer dignissim ultrices molestie maecenas. Posuere congue efficitur nostra nisi a cubilia auctor.
                    Molestie aliquam habitant vivamus tempor vehicula taciti in urna hac. Nascetur class platea primis lobortis odio mauris dolor.
                    Etiam molestie tempus lacus faucibus, adipiscing velit rutrum. Nec sed ante vulputate eleifend arcu nostra habitasse ipsum 
                    integer. Fermentum integer aenean integer porttitor mi phasellus. Platea platea ultricies per nam adipiscing, tellus posuere.
                </p> */}
            </div>

            {/* Pictures section */}
            <div className="flex flex-wrap gap-5 justify-center px-5 py-10">
                {imageSources.map((src, index) => (
                    <div className="h-[200px]" key={index}>
                        <img src={src} alt={`college-image-${index + 1}`} className="w-full h-full object-cover" />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Gallery;
