import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

const EditAdmissions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const admission = location.state; // Get the admission data passed from AllAdmissions

  // State for the form data
  const [formData, setFormData] = useState({
    university_name: admission?.university_name || "", // The name is prefilled and displayed
    admission_details: admission?.admission_details || "",
  });

  // Handle form submission and make API call to update the admission details
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedAdmission = {
        admission_details: formData.admission_details,
      };

      // Make PUT request to update admission
      await axios.put(`http://213.210.37.39:3000/api/update/admissions/${admission.admission_id}`, updatedAdmission);

      toast.success("Admission updated successfully!");
      navigate("/admin/admission/all"); // Navigate back to the admissions list after success
    } catch (error) {
      console.error("Failed to update admission:", error.response?.data || error.message);
      toast.error("Failed to update admission. Please try again.");
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle="Admissions" subTitle="Edit Admission" />

      <div className="bg-white p-4 rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* University Name */}
            <div className="flex flex-col">
              <label htmlFor="university_name" className="mb-2 font-medium text-sm">
                University Name
              </label>
              <input
                type="text"
                id="university_name"
                name="university_name"
                value={formData.university_name}
                className="border border-gray-300 p-2 rounded bg-gray-200"
                disabled
              />
            </div>

            {/* Admission Details (CKEditor) */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="admission_details" className="mb-2 font-medium text-sm">
                Admission Details
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.admission_details}
                onChange={(event, editor) => {
                  setFormData({ ...formData, admission_details: editor.getData() });
                }}
              />
            </div>
          </div>

          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md"
          >
            Submit
          </button>
        </form>
      </div>

      {/* Toast for notifications */}
      <ToastContainer />
    </div>
  );
};

export default EditAdmissions;
