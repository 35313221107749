// import React, { useRef, useEffect, useState, useCallback } from 'react';
// import Notes from '../Tabs/Notes';
// import Alerts from '../Tabs/Alerts';
// import Chats from '../Tabs/Chats';

// const SlidingTab = ({ isOpen, onClose }) => {
//   const [activeTab, setActiveTab] = useState('CHATS');
//   const tabRef = useRef(null);

//   const handleClickOutside = useCallback((event) => {
//     if (tabRef.current && !tabRef.current.contains(event.target)) {
//       onClose();
//     }
//   }, [onClose]);

//   useEffect(() => {
//     if (isOpen) {
//       document.addEventListener('mousedown', handleClickOutside);
//     } else {
//       document.removeEventListener('mousedown', handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [isOpen, handleClickOutside]);

//   const renderContent = () => {
//     switch (activeTab) {
//       case 'NOTES':
//         return <Notes />;
//       case 'ALERTS':
//         return <Alerts />;
//       case 'CHATS':
//         return <Chats />;
//       default:
//         return <Chats />;
//     }
//   };

//   return (
//     <div
//       ref={tabRef}
//       className={`fixed top-0 right-0 w-72 h-screen bg-white z-40 transform ${
//         isOpen ? 'translate-x-0' : 'translate-x-full'
//       } transition-transform duration-500 ease-in-out shadow-lg`}
//     >
//       <div className="p-4 bg-gray-100">
//         <div className="flex items-center">
//           {['NOTES', 'ALERTS', 'CHATS'].map((tab) => (
//             <button
//               key={tab}
//               onClick={() => setActiveTab(tab)}
//               className={`flex-1 text-xs font-semibold py-2 relative ${
//                 activeTab === tab
//                   ? 'text-blue-700'
//                   : 'text-blue-500 hover:text-blue-700'
//               }`}
//               style={{ fontFamily: 'Poppins, sans-serif' }}
//             >
//               {tab}
//               <span
//                 className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 ${
//                   activeTab === tab
//                     ? 'bg-blue-700 h-1 w-16'  // Active tab underline
//                     : 'bg-blue-300 h-1 w-12 opacity-0 hover:opacity-100' // Inactive tab underline
//                 }`}
//               />
//             </button>
//           ))}
//         </div>
//       </div>
//       <div className="mt-4 p-4">
//         {renderContent()}
//       </div>
//     </div>
//   );
// };

// export default SlidingTab;
import React, { useEffect, useRef, useState } from 'react';
import UserListSection from './UserListSection';
import ChatWindow from './ChatWindow';

const SlidingTab = ({ isOpen, onClose }) => {
  const tabRef = useRef();
  const [activeTab, setActiveTab] = useState("Chats");
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tabRef.current && !tabRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "";
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "";
    };
  }, [isOpen, onClose]);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setActiveTab("Chats"); // Switch to Chats tab when a user is selected
  };

  const handleBack = () => {
    setSelectedUser(null);
  };

  const renderContent = () => {
    if (selectedUser) {
      return <ChatWindow user={selectedUser} onBack={handleBack} />;
    }
    
    switch (activeTab) {
      case "Notes":
        return <div>Notes Component</div>;
      case "Alerts":
        return <div>Alerts Component</div>;
      case "Chats":
      default:
        return <UserListSection onUserSelect={handleUserSelect} />;
    }
  };

  return (
    <div
      ref={tabRef}
      className={`fixed top-0 right-0 h-screen bg-white shadow-lg transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out w-[250px] md:w-[300px] lg:w-[350px]`}
    >
      <div className="bg-gray-200 p-4">
        <div className="flex justify-around text-[#6173fa] text-sm font-semibold" style={{ fontFamily: "Poppins, sans-serif" }}>
          {["Notes", "Alerts", "Chats"].map((tab) => (
            <div
              key={tab}
              className={`relative cursor-pointer pb-2 ${
                activeTab === tab
                  ? "text-[#3945f8] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[2px] after:bg-[#3945f8] after:transition-all after:duration-300 after:ease-in-out"
                  : "hover:text-[#3945f8] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[2px] after:bg-[#3945f8] after:scale-x-0 after:transition-all after:duration-300 after:ease-in-out hover:after:scale-x-100"
              }`}
              style={{
                transition: "color 0.3s ease-in-out",
              }}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      <div className="h-full">
        {renderContent()}
      </div>
    </div>
  );
};

export default SlidingTab;


