import React, { useState } from 'react';
import { FaSearch, FaRegCommentDots, FaBell, FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import MenuIcon from './MenuIcon';
import SlidingTab from '../chat/SlidingTab';
import NotificationCard from './NotificationCard';

const Navbar = ({ toggleSidebar, isSidebarOpen }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const toggleChatTab = () => {
    setIsChatOpen(!isChatOpen);
  };
  
  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const closeProfileDropdown = () => {
    setIsProfileOpen(false);
  };

  return (
    <div className={`h-16 bg-white text-gray-700 flex items-center px-4 border-b border-gray-300 fixed top-0 left-0 right-0 z-10 ${isSidebarOpen ? 'ml-64' : 'ml-20'}`}>
      <div className="flex-grow flex items-center justify-between">
        {/* Sidebar Toggle Button */}
        <button onClick={toggleSidebar} className="flex items-center">
          <MenuIcon isCollapsed={!isSidebarOpen} />
        </button>

        {/* Search Bar */}
        <div className="hidden md:flex items-center flex-grow">
          <div className="relative w-full max-w-xs ml-6">
            <div className="absolute inset-y-0 left-0 flex items-center pl-5">
              <FaSearch className="text-gray-500" />
            </div>
            <input
              type="text"
              placeholder="Search"
              className="pl-12 pr-5 bg-searchbg py-5 w-full rounded-full border-none focus:ring-0 outline-none text-sm font-normal"
              style={{ fontFamily: 'Poppins, sans-serif', height: '50px' }}
            />
          </div>
        </div>
      </div>

      {/* Right Section (Icons) */}
      <div className="flex items-center space-x-4 ml-5">
        <button className="hidden md:flex items-center">
          <FaRegCommentDots className="w-5 h-5 text-[#6a73fa]" onClick={toggleChatTab} />
        </button>
        <button className="relative hidden md:flex items-center" onClick={toggleNotifications}>
          <FaBell className="w-5 h-5 text-gray-500" />
          <div className="absolute top-0 right-0 w-5 h-5 bg-blue-500 rounded-full animate-pulse"></div>
          <div className="absolute top-0 right-0 w-5 h-5 bg-blue-100 rounded-full animate-pulse"></div>
          <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-blue-500 rounded-full"></div>
        </button>
        <button onClick={toggleProfileDropdown} className="hidden md:flex items-center">
          <FaUserCircle size={24} color="#737b8b" />
        </button>
      </div>

      {/* Profile Dropdown */}
      {isProfileOpen && (
        <div className="absolute top-16 right-4 w-48 bg-white shadow-lg rounded-lg border border-gray-200 z-20">
          <ul className="py-2">
            {/* Profile */}
            <li className="flex items-center px-4 py-2 cursor-pointer hover:bg-[#6a73fa1a]" onClick={closeProfileDropdown}>
              <FaUserCircle className="mr-2 text-[#737b8b] hover:text-[#6a73fa]" size={16} />
              <span className="text-[#737b8b] hover:text-[#6a73fa]" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '13.25px', fontWeight: 400, lineHeight: '19.6px' }}>Profile</span>
            </li>
            {/* Inbox */}
            <li className="flex items-center px-4 py-2 cursor-pointer hover:bg-[#6a73fa1a]" onClick={closeProfileDropdown}>
              <FaRegCommentDots className="mr-2 text-[#737b8b] hover:text-[#6a73fa]" size={16} />
              <span className="text-[#737b8b] hover:text-[#6a73fa]" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '13.25px', fontWeight: 400, lineHeight: '19.6px' }}>Inbox</span>
            </li>
            {/* Logout */}
            <li className="flex items-center px-4 py-2 cursor-pointer hover:bg-[#6a73fa1a]" onClick={closeProfileDropdown}>
              <FaSignOutAlt className="mr-2 text-[#ff1616] hover:text-[#6a73fa]" size={16} />
              <span className="text-[#737b8b] hover:text-black" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '13.25px', fontWeight: 400, lineHeight: '19.6px' }}>Logout</span>
            </li>
          </ul>
        </div>
      )}

      {isNotificationsOpen && <NotificationCard onClose={() => setIsNotificationsOpen(false)} />}
      <SlidingTab isOpen={isChatOpen} onClose={toggleChatTab} />
    </div>
  );
};

export default Navbar;
