// import React from 'react'
// import Header from '../components/Header/Header'
// import Footer from '../components/Footer/Footer'
// import home_top from '../assets/landing/home_top.png'
// import Hat from '../assets/landing/Hat.png'
// import Process from '../components/Landing/Process/Process'
// import TopColleges from '../components/Landing/TopColleges/TopColleges'
// import CollegeBy from '../components/Landing/CollegeBy/CollegeBy'
// import LandingVideo from '../components/Landing/LandingVideo/LandingVideo'
// import Reviews from '../components/Landing/Reviews/Reviews'
// import LandingNewsEvents from '../components/Landing/LandingNewsEvents/LandingNewsEvents'
// import Newsletter from '../components/Landing/Newsletter/Newsletter'
// import Colleges from '../components/Landing/Colleges/Colleges'



// const Landing = () =>{
//     return(
//         <div>
//             <Header />
// {/* 
//             Top Section
//             <div className="relative px-5 pb-10 pt-[150px]">
//                 <div className="absolute top-0 left-0 right-0 bottom-0 z-[-1]"><img src={home_top} alt="cover-image" className="h-full w-full object-cover" /></div>

//                 <div className="flex flex-col items-center">
//                     <p className="text-4xl text-white font-semibold mb-20">Your Journey to the Perfect College Starts Here – Explore, Compare, Succeed!</p>
//                     <div className="relative flex justify-center items-center px-5 bg-white rounded-3xl mb-12">
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
//                             <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
//                         </svg>
//                         <input type="text" placeholder="Find your College" className="w-[300px] h-[40px] border-0" />
//                         <img src={Hat} alt="" className="w-20 h-20 absolute -right-[20px] -top-[43px] z-5" />
//                     </div>
//                     <p className="text-white text-lg font-medium mb-10">Navigate Your Path to Success: Discover Top Colleges and Make Informed Choices!</p>
//                     <div className="flex gap-10">
//                         <button className="py-[6px] px-12 text-white font-semibold text-base border-2 border-white rounded-xl bg-[#3ACA2E]">SIGN UP</button>
//                         <button className="py-[6px] px-12 text-base font-semibold border-2 border-[#3ACA2E] rounded-xl bg-white">LOGIN</button>
//                     </div>
//                 </div>
//             </div> */}
            
           
//             {/* <Process />

            
//             <TopColleges />
            
           
//             <CollegeBy />

//             <LandingVideo />

           
//             <Reviews />

//             <Colleges />
            
          
//             <LandingNewsEvents />
            
          
//             <Newsletter />

//             <Footer /> */}
//         </div>
//     )
// }

// export default Landing;import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import home_top from '../assets/landing/home_top.png';
import Hat from '../assets/landing/Hat.png';
import Process from '../components/Landing/Process/Process';
import TopColleges from '../components/Landing/TopColleges/TopColleges';
import CollegeBy from '../components/Landing/CollegeBy/CollegeBy';
import LandingVideo from '../components/Landing/LandingVideo/LandingVideo';
import Reviews from '../components/Landing/Reviews/Reviews';
import LandingNewsEvents from '../components/Landing/LandingNewsEvents/LandingNewsEvents';
import Newsletter from '../components/Landing/Newsletter/Newsletter';
import Colleges from '../components/Landing/Colleges/Colleges';

const Landing = () => {
    return (
        <div>
            <Header />

            {/* Top Section */}
            <div className="relative px-4 pb-10 pt-[150px] md:px-8 md:pt-[200px]">
  <div className="absolute inset-0 z-[-1]">
    <img src={home_top} alt="cover-image" className="h-full w-full object-cover" />
  </div>

  <div className="relative flex flex-col items-center text-center">
    <p className="text-2xl md:text-4xl text-white font-semibold mb-8 md:mb-12">
      Your Journey to the Perfect College Starts Here – Explore, Compare, Succeed!
    </p>
    <div className="relative flex justify-center items-center bg-white rounded-3xl px-4 py-2 md:px-6 md:py-3 mb-8 md:mb-12">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 absolute left-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
      </svg>
      <input
        type="text"
        placeholder="Find your College"
        className="search-input w-[240px] md:w-[300px] h-[40px] border-0 pl-12 pr-4 rounded-full"
        style={{
          border: '1px solid #fff',
          outline: 'none',
        }}
      />
      <img src={Hat} alt="hat" className="w-16 h-16 absolute -right-4 -top-4 md:w-20 md:h-20 md:-right-6 md:-top-6 z-10" />
    </div>
    <p className="text-white text-lg md:text-xl font-medium mb-8 md:mb-10">
      Navigate Your Path to Success: Discover Top Colleges and Make Informed Choices!
    </p>
    <div className="flex gap-6 flex-wrap">
      <button className="py-2 px-6 text-white font-semibold text-base border-2 border-white rounded-[20px] bg-[#3ACA2E]">
        SIGN UP
      </button>
      <button className="py-2 px-6 text-base font-semibold border-2 border-[#3ACA2E] rounded-[20px] bg-white">
        LOGIN
      </button>
    </div>
  </div>
</div>

<style jsx>{`
  .search-input:focus {
    border-color: #fff; /* Maintain the same border color on focus */
    box-shadow: none; /* Remove default focus shadow */
  }
`}</style>


            {/* Other sections */}
           <Process />
           <TopColleges />
            <CollegeBy />
            <LandingVideo />
            <Reviews />
            <Colleges />
            <LandingNewsEvents />
            <Newsletter />
            <Footer /> 
        </div>
    );
};

export default Landing;

