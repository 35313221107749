// EditNews.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const EditNews= () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    university_id: 1,
    title: '',
    content: '',
    announcement_date: '',
    
  });
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [imageName, setImageName] = useState('No file chosen'); 

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const response = await axios.get(`${Base_URL}/news-announcements/${id}`);
        const event = response.data
        const formattedDate = new Date(event.announcement_date).toISOString().split('T')[0];
        
        setFormData({
          title: event.title,
          content:event.content,
          announcement_date: formattedDate,
          university_id:1 // Ensure the date is in the correct format
        });
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    };
    fetchNewsData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      if (image) {
        data.append('picture', image);
      }

      // Retrieve token from local storage or other storage method
      //const token = localStorage.getItem('authToken');

      const response = await axios.put(`${Base_URL}/news-announcements/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          //'Authorization': `Bearer ${token}`, // Add Authorization header
        },
      });

      console.log('Updated news data response:', response.data);
      toast.success('News data updated successfully!');
      navigate('/dashboard/news/all'); // Navigate to All Placements after update
    } catch (error) {
      console.error('Failed to update news data:', error.response?.data || error.message);
      toast.error('Failed to update news data. Please try again.');
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
    <HeaderSection
      title="Add News"
      breadcrumb={['News', 'Add News']}
    />
    <div className="border border-gray-300 bg-white p-4 rounded-md">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="title" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 mt-2 w-full"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="content" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Content</label>
            <textarea
              id="content"
              name="content"
              value={formData.content}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 mt-2 w-full"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="announcement_date" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Announcement Date</label>
            <input
              type="date"
              id="announcement_date"
              name="announcement_date"
              value={formData.announcement_date}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 mt-2 w-full"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="picture" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Upload Picture</label>
            <div className="flex border border-gray-300 rounded-md mt-2 overflow-hidden">
              <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer text-[12.25px]">
                <span>Choose File</span>
                <input
                  type="file"
                  id="picture"
                  name="picture"
                  onChange={handleImageChange}
                  className="hidden"
                />
              </label>
              <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center text-[12.25px]">
                {imageName || 'No file chosen'}
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="mt-4 px-4 py-2 bg-customblue text-white rounded-md hover:bg-hoverblue"
        >
          Submit
        </button>
        {error && <div className="text-red-600 mt-2">{error}</div>}
      </form>
      <ToastContainer />
    </div>
  </div>
  );
};

export default EditNews;
