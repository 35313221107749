import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';
import { Base_URL } from '../../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const EditProfessor = () => {
  const { id } = useParams(); // Get the professor ID from the URL
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    qualification: '',
    years_of_experience: '',
    email: '',
    department: '',
    status: 1, // Default to active (1)
  });
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');
  const [professor, setProfessor] = useState(null);
  const [password, setPassword] = useState('');
 
  useEffect(() => {
    if (id) {
      const fetchProfessorDetails = async () => {
        try {
          const response = await axios.get(`${Base_URL}/faculty/${id}`);
          setFormData({
            name: response.data.name,
            qualification: response.data.qualification,
            years_of_experience: response.data.years_of_experience,
            email: response.data.email,
            department: response.data.department,
            status: response.data.status === 1 ? 1 : 0, // Map the status to 1 or 0
          });
          setImage(response.data.profile_picture || '/path/to/default/image.jpg'); // Handle image fallback
          setPassword(response.data.password || ''); // Set the fetched password
        } catch (error) {
          console.error('Error fetching professor details:', error);
          setError('Failed to fetch professor details.');
        }
      };

      fetchProfessorDetails();
    }
  }, [id]);

 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === 'status' ? (value === 'Active' ? 1 : 0) : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append('name', formData.name);
      formDataToSubmit.append('qualification', formData.qualification);
      formDataToSubmit.append('years_of_experience', formData.years_of_experience);
      formDataToSubmit.append('email', formData.email);
      formDataToSubmit.append('department', formData.department);
      formDataToSubmit.append('status', formData.status);
      formDataToSubmit.append('university_id', 1);
      if (password) {
        formDataToSubmit.append('password', password); // Include the password
      }
      if (image) {
        formDataToSubmit.append('profile_picture', image);
      }

      const url = `${Base_URL}/faculty/${id}`; // Ensure id is properly defined

      await axios.put(url, formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      navigate('/dashboard/professors/all');
    } catch (error) {
      console.error('Error updating professor:', error.response ? error.response.data : error.message);
      toast.error('Failed to update professor details.');
    }
  };



return (
  <div className="bg-gray-100 p-6 rounded-lg shadow-md h-screen">
    <HeaderSection
      title="Edit Professor"
      breadcrumb={['Professor', 'Edit Professor']}
    />
    <div className="border border-gray-300 bg-white p-4 rounded-md">
      <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
        <h2 className="text-[#312a2a] text-[17.25px] font-[500] font-poppins">Basic Info</h2>
      </div>

      {error && <div className="text-red-600 mb-4">{error}</div>}

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label
              htmlFor="name"
              className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
             
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="qualification"
              className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
              Qualification
            </label>
            <input
              type="text"
              id="qualification"
              name="qualification"
              value={formData.qualification}
              onChange={handleInputChange}
              className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
          
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="years_of_experience"
              className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
              Years of Experience
            </label>
            <input
              type="number"
              id="years_of_experience"
              name="years_of_experience"
              value={formData.years_of_experience}
              onChange={handleInputChange}
              className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
            
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="email"
              className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
              
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="department"
              className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
              Department
            </label>
            <input
              type="text"
              id="department"
              name="department"
              value={formData.department}
              onChange={handleInputChange}
              className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
             
            />
          </div>
          
          <div className="flex flex-col">
            <label
              htmlFor="status"
              className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
              Status
            </label>
            <select
              id="status"
              name="status"
              value={formData.status === 1 ? 'Active' : 'Inactive'}
              onChange={handleInputChange}
              className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
             
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="profile_picture"
              className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[6px]">
              Upload Profile Picture
            </label>
            <div className="flex border border-gray-300 rounded-lg overflow-hidden">
              <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer font-poppins">
                <span className='text-[12.25px]'>Choose File</span>
                <input
                  type="file"
                  id="profile_picture"
                  name="profile_picture"
                  onChange={handleImageChange}
                  className="hidden"
                />
              </label>
              <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center font-poppins text-[12.25px]">
                {imageName || 'No file chosen'}
              </div>
            </div>
          </div>
          </div>

        <div className="mt-6">
          <button
            type="submit"
            className=" px-4 py-2 rounded  bg-customblue text-white text-sm hover:bg-[rgb(82,89,199)]">
            Update
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  </div>
);
};

export default EditProfessor;




//   useEffect(() => {
//     // Fetch professor details by ID
//     const fetchProfessorDetails = async () => {
//       try {
//         const response = await axios.get(`${Base_URL}/professors/${id}`);
//         setFormData({
//           name: response.data.name,
//           qualification: response.data.qualification,
//           years_of_experience: response.data.years_of_experience,
//           email: response.data.email,
//           department: response.data.department,
//           status: response.data.status === 1 ? 1 : 0, // Map the status to 1 or 0
//         });
//         setImage(response.data.picture);
//       } catch (error) {
//         console.error('Error fetching professor details:', error);
//         setError('Failed to fetch professor details.');
//       }
//     };

//     fetchProfessorDetails();
//   }, [id]);

//   const handleInputChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleImageChange = (e) => {
//     setImage(e.target.files[0]);
//     setImageName(e.target.files[0].name);
//   };

//   const handleStatusChange = (e) => {
//     setFormData({ ...formData, status: e.target.value === 'Active' ? 1 : 0 });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const token = localStorage.getItem('token'); // Get the authorization token

//       const formDataToSubmit = new FormData();
//       formDataToSubmit.append('name', formData.name);
//       formDataToSubmit.append('qualification', formData.qualification);
//       formDataToSubmit.append('years_of_experience', formData.years_of_experience);
//       formDataToSubmit.append('email', formData.email);
//       formDataToSubmit.append('department', formData.department);
//       formDataToSubmit.append('status', formData.status);
//       if (image) {
//         formDataToSubmit.append('picture', image);
//       }

//       await axios.put(`${Base_URL}/professors/${id}`, formDataToSubmit, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${token}`, // Add the authorization token
//         },
//       });

//       navigate('/dashboard/professors/all');
//     } catch (error) {
//       console.error('Error updating professor:', error);
//       setError('Failed to update professor details.');
//     }
//   };

  

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate, useParams } from 'react-router-dom';
// import HeaderSection from '../headerSection/HeaderSection';
// import { Base_URL } from '../../../apiConfig';

// const EditProfessor = () => {
//   const { id } = useParams(); // Get the professor ID from the URL
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     name: '',
//     qualification: '',
//     years_of_experience: '',
//     email: '',
//     department: '',
//     status: 1, // Default to active (1)
//   });
//   const [image, setImage] = useState(null);
//   const [error, setError] = useState(null);
//   const [imageName, setImageName] = useState('No file chosen');

//   useEffect(() => {
//     if (!id) {
//       // Handle the case where ID is not provided
//       console.error('No professor ID provided.');
//       setError('Professor ID is missing.');
//       return;
//     }

//     const fetchProfessorDetails = async () => {
//       try {
//         const response = await axios.get(`${Base_URL}/faculty/${id}`);
//         setFormData({
//           name: response.data.name,
//           qualification: response.data.qualification,
//           years_of_experience: response.data.years_of_experience,
//           email: response.data.email,
//           department: response.data.department,
//           status: response.data.status === 1 ? 1 : 0, // Map the status to 1 or 0
//         });
//         setImage(response.data.profile_picture || '/path/to/default/image.jpg'); // Handle image fallback
//       } catch (error) {
//         console.error('Error fetching professor details:', error);
//         setError('Failed to fetch professor details.');
//       }
//     };

//     fetchProfessorDetails();
//   }, [id]);

//   const handleInputChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleImageChange = (e) => {
//     setImage(e.target.files[0]);
//     setImageName(e.target.files[0].name);
//   };

//   const handleStatusChange = (e) => {
//     setFormData({ ...formData, status: e.target.value === 'Active' ? 1 : 0 });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const formDataToSubmit = new FormData();
//       formDataToSubmit.append('name', formData.name);
//       formDataToSubmit.append('qualification', formData.qualification);
//       formDataToSubmit.append('years_of_experience', formData.years_of_experience);
//       formDataToSubmit.append('email', formData.email);
//       formDataToSubmit.append('department', formData.department);
//       formDataToSubmit.append('status', formData.status);
//       if (image) {
//         formDataToSubmit.append('profile_picture', image);
//       }

//       await axios.put(`${Base_URL}/faculty/${id}`, formDataToSubmit, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       navigate('/dashboard/professors/all');
//     } catch (error) {
//       console.error('Error updating professor:', error);
//       setError('Failed to update professor details.');
//     }
//   };

//   return (
//     <div className="p-6 bg-gray-100 min-h-screen">
//       <HeaderSection title="Edit Professor" breadcrumb={['Professors', 'Edit Professor']} />

//       <div className="bg-white p-6 rounded-lg shadow">
//         <form onSubmit={handleSubmit}>
//           {/* Form Fields */}
//           <div className="mb-4">
//             <label className="block text-sm font-medium text-gray-700">Name</label>
//             <input
//               type="text"
//               name="name"
//               value={formData.name}
//               onChange={handleInputChange}
//               required
//               className="mt-1 p-2 w-full border rounded"
//             />
//           </div>
//           {/* Add other fields similarly */}
          
//           <div className="mb-4">
//             <label className="block text-sm font-medium text-gray-700">Status</label>
//             <select
//               name="status"
//               value={formData.status === 1 ? 'Active' : 'Inactive'}
//               onChange={handleStatusChange}
//               required
//               className="mt-1 p-2 w-full border rounded"
//             >
//               <option value="Active">Active</option>
//               <option value="Inactive">Inactive</option>
//             </select>
//           </div>

//           <div className="mb-4">
//             <label className="block text-sm font-medium text-gray-700">Picture</label>
//             <input
//               type="file"
//               name="picture"
//               onChange={handleImageChange}
//               className="mt-1 p-2 w-full border rounded"
//             />
//             <p className="text-sm text-gray-500 mt-2">{imageName}</p>
//             {image && (
//               <div className="mt-4">
//                 <img src={URL.createObjectURL(image)} alt="Selected" className="h-32 rounded-md" />
//               </div>
//             )}
//           </div>

//           {error && <p className="text-red-500">{error}</p>}

//           <div className="flex justify-end mt-6">
//             <button
//               type="submit"
//               className="px-4 py-2 bg-customblue text-white rounded hover:bg-blue-800"
//             >
//               Update
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default EditProfessor;


