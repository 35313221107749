import React,{useState, useRef} from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import '../styles/college.css'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import ReviewCard from '../components/College/ReviewCard/ReviewCard'
import Overview from '../components/College/Overview/Overview'
import Courses from '../components/College/Courses/Courses'
import Admission from '../components/College/Admission/Admission'
import Scholarship from '../components/College/Scholarship/Scholarship';
import Placements from '../components/College/Placements/Placements';
import QA from '../components/College/QA/QA';
import Gallery from '../components/College/Gallery/Gallery'
import NewsEvents from '../components/College/NewsEvents/NewsEvents'
import Chat from '../components/College/Chat/Chat'
import college_logo from '../assets/college_logo.png';
import college_grad from '../assets/college_grad.png';
import user1 from '../assets/user1.png'
import user2 from '../assets/user2.png'
import user3 from '../assets/user3.png'
import college_img1 from '../assets/college_img1.png'
import college_img2 from '../assets/college_img2.png'
import college_img3 from '../assets/college_img3.png'
import college_img4 from '../assets/college_img4.png'
import college_vid from '../assets/college_vid.mp4'
import Reviews from '../components/Landing/Reviews/Reviews';


const College = () => {
    const [preview, setPreview] = useState(0);
    
    const mainSectionRef = useRef(null);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const handlePreview = (view) => {
        setPreview(view);
        mainSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
   

const handleLeftClick = () => {
  setPreview((prev) => (prev > 0 ? prev - 1 : 8)); // Move to the previous option or loop back to the last one
};

const handleRightClick = () => {
  setPreview((prev) => (prev < 8 ? prev + 1 : 0)); // Move to the next option or loop back to the first one
};

// const handlePreviewmobile = (index) => {
//   setPreview(index); // Set the clicked option to active
// };


    return (
        <div className='college_main'>
       <Header />

{/* Top Section */}
<div className="py-5 ">
    {/* College Title */}
    <div className="flex flex-col items-center justify-center gap-3 p-5 md:flex-row md:gap-5">
        {/* College Logo */}
        <div   className="h-[50px] w-[50px] md:h-[80px] md:w-[80px] mobile-mt-133 ">
            <img src={college_logo} alt="college-logo" className="h-full w-full object-cover" />
        </div>
        
        {/* College Text */}
        <p className="text-center md:text-left md:text-3xl text-black  college_text">
            LPU : Lovely Professional University
        </p>
    </div>

    {/* Slider Section */}
    <div className="flex justify-center p-5 ">
        <div className="college_slider  max-w-[357px] md:max-w-[1000px]">
            <Slider {...settings}>
                {/* Video Slide */}
                <div className="flex justify-center items-center">
                    <div className="w-full h-[336px] md:h-[450px] flex justify-center items-center">
                        <video src={college_vid} alt="college-video" controls className="w-full h-full object-cover rounded-2xl" />
                    </div>
                </div>

                {/* Image Slides */}
                <div className="flex justify-center items-center">
                    <div className="w-full h-[336px] md:h-[450px] flex justify-center items-center">
                        <img src={college_img1} alt="college-image" className="w-full h-full object-cover rounded-2xl" />
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <div className="w-full h-[336px] md:h-[450px]">
                        <img src={college_img2} alt="college-image" className="w-full h-full object-cover rounded-2xl" />
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <div className="w-full h-[336px] md:h-[450px]">
                        <img src={college_img3} alt="college-image" className="w-full h-full object-cover rounded-2xl" />
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <div className="w-full h-[336px] md:h-[450px]">
                        <img src={college_img4} alt="college-image" className="w-full h-full object-cover rounded-2xl" />
                    </div>
                </div>
            </Slider>
        </div>
    </div>


                {/* College tags */}
                <div className="tags-container flex justify-center gap-2 p-3">
                    <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>Highest Package 3 Crore</p></div>
                    <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>Private University</p></div>
                    <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>NIRF Rank #46</p></div>
                    <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>Year of Establishment 2005</p></div>
                    <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>4.6  View Reviews</p></div>
                    <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>Location Punjab</p></div>
                </div>
                {/* Apply buttons */}
                <div className="flex buttons-container justify-center gap-10 p-5">
                    <button className="text-white rounded-[10px] apply_buttons bg-[#3ACA2E] px-10 py-2 md:rounded-xl text-base">Apply Now</button>
                    <button className="px-10 py-2  counselling_buttons rounded-[10px] md:w-[250px] md:h-[42px]  md:rounded-xl text-base text-black shadow-lg" style={{ border: '1px solid #3ACA2E' }}>Get Free Counselling</button>
                </div>
            </div>

            {/* Options - sub header */}
           <div style={{ zIndex: 999 }} className="subheader md:top-[88px] top-[-3px] flex gap-5 p-5 text-base font-medium text-black college_sub_header bg-white shadow-md md:justify-center">
  <p className={`college_sub_header_option ${preview === 0 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(0)}>Overview</p>
  <p className={`college_sub_header_option ${preview === 1 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(1)}>Courses</p>
  <p className={`college_sub_header_option ${preview === 2 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(2)}>Admission</p>
  <p className={`college_sub_header_option ${preview === 3 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(3)}>Scholarship</p>
  <p className={`college_sub_header_option ${preview === 4 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(4)}>Placements</p>
  <p className={`college_sub_header_option ${preview === 5 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(5)}>Gallery</p>
  <p className={`college_sub_header_option ${preview === 6 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(6)}>News & Events</p>
  <p className={`college_sub_header_option ${preview === 7 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(7)}>Q/A</p>
  <p className={`college_sub_header_option ${preview === 8 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(8)}>Chat</p>
 
</div>





            {/* Main Section */}
            <div className="" ref={mainSectionRef}>
                {preview === 0 && (<Overview />)}
                {preview === 1 && (<Courses />)}
                {preview === 2 && (<Admission />)}
                {preview === 3 && (<Scholarship />)}
                {preview === 4 && (<Placements />)}
                {preview === 5 && (<Gallery />)}
                {preview === 6 && (<NewsEvents />)}
                {preview === 7 && (<QA />)}
                {preview === 8 && (<Chat />)}
            </div>

            {/* Details Form Section*/}
            <div className="flex flex-wrap  justify-center gap-20 items-center px-5 py-20 mobile-form-shadow ">
  <div className="flex flex-col items-center  gap-5 max-w-[700px] mx-auto px-4 md:px-0 md:text-center">
    <p className="text-2xl text-black font-medium text-center md:text-center">
      Unlock Your Future with Quality Education - Join Us for a Transformative College Experience!
    </p>
    <div className="w-full max-w-[400px]">
      <img src={college_grad} className="w-full rounded-xl" alt="College Graduation" />
    </div>
  </div>

  <div className="flex flex-col gap-3 p-8 mx-auto max-w-full md:max-w-[400px] border border-gray-300 rounded-lg shadow-md ">
    <p className="text-black text-base font-medium md:text-center text-left">Get Started</p>
    <p className="text-black text-base font-medium md:text-center text-left">Fill your details and get your Information</p>
    <div className="flex flex-col gap-3 w-full">
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='name'>Name</label>
        <input type="text" placeholder="Enter your Full Name" className="focus:outline-none rounded border border-gray-300 p-2" />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='email'>Email</label>
        <input type="email" placeholder="Enter your Email" className="focus:outline-none rounded border border-gray-300 p-2" />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='contact'>Contact Number</label>
        <input type="tel" placeholder="Enter your Phone Number" className="rounded border border-gray-300 p-2 focus:outline-none" />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='qualification'>Qualification</label>
        <input type="text" placeholder="Enter your highest Qualification" className="rounded border border-gray-300 p-2 focus:outline-none" />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='discipline'>Discipline Interested in</label>
        <input type="text" placeholder="Enter the Discipline of Interest" className="rounded border border-gray-300 p-2 focus:outline-none" />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='program'>Program</label>
        <input type="text" placeholder="Enter the Program" className="rounded border border-gray-300 p-2 focus:outline-none" />
      </div>
      <div className="flex justify-center py-3">
        <button type="submit" className="bg-[#3ACA2E] rounded-3xl px-10 py-2 text-medium text-white text-lg">Submit</button>
      </div>
    </div>
  </div>
</div>


            {/* Student Reviews */}
           
  <Reviews />


<Footer />

          
        </div>
    )
}

export default College;
