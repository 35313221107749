import React from 'react';


const Analytics = () => {
 
//   useEffect(() => {
//     // Fetch data from API
//     axios.get('/api/dashboard/analytics')
//       .then(response => setData(response.data))
//       .catch(error => console.error('Error fetching analytics data', error));
//   }, []);

  return (
    <div className="p-4 bg-[#ffffff]">
     
    </div>
  );
};

export default Analytics;
