import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
//import dummyEvent from './dummyEvent'; // Replace this with API call later

const AllEvents = () => {
    const [events, setEvents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [entriesPerPage, setEntriesPerPage] = useState(5);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = events.slice(indexOfFirstEntry, indexOfLastEntry);
    const totalPages = Math.ceil(events.length / entriesPerPage);

//     // useEffect(() => {
//     //     const fetchEvents = async () => {
//     //         try {
//     //             const token = localStorage.getItem('authToken'); // Fetch the auth token from storage
//     //             const response = await axios.get(`${Base_URL}/event/organizer/${university_id}`, {
//     //                 headers: {
//     //                     Authorization: `Bearer ${token}`, // Include the token in the request header
//     //                 },
//     //             });
//     //             setEvents(response.data); // Set the fetched events data
//     //         } catch (error) {
//     //             console.error('Error fetching events:', error);
//     //             if (error.response && error.response.status === 401) {
//     //                 navigate('/login');
//     //             }
//     //         }
//     //     };

//     //     fetchEvents();
//     // }, [university_id, navigate]);
useEffect(() => {
  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${Base_URL}/event/organizer/1`); // Hardcoded university_id = 1
      console.log(response.data);
      setEvents(response.data); // Set the fetched events data
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  fetchEvents();
}, []);
//    // Dummy data setup for testing
//     useEffect(() => {
//         // Replace with actual API call
//         setEvents(dummyEvent);
//     }, []);
const handleEditClick = (id) => {
  navigate(`/dashboard/events/edit/${id}`);
};

const handleDeleteClick = async (id) => {
  console.log('Deleting event with ID:', id);
  const universityId = 1; // Ensure this is the correct university ID
  {
    try {
      const url = `${Base_URL}/event/${id}`;
      console.log('DELETE request URL:', url);
      await axios.delete(url);
      setEvents(prevEvents => prevEvents.filter(events => events.event_id !== id));
      toast.success('Events deleted successfully.');
    } catch (error) {
      console.error('Error deleting Events:', error.response ? error.response.data : error.message);
      toast.error('Failed to delete Events.');
    }
  }
};

   

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

//     const handleEditClick = (id) => {
//         navigate('/dashboard/events/edit');
//         //navigate(`/dashboard/events/edit/${id}`);
//     };

//     const handleDeleteClick = async (id) => {
//         if (window.confirm('Are you sure you want to delete this event?')) {
//             try {
//                 const token = localStorage.getItem('authToken');
//                 await axios.delete(`${Base_URL}/event/${id}`, {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                     },
//                 });
//                 setEvents(events.filter((event) => event.id !== id));
//             } catch (error) {
//                 console.error('Error deleting event:', error);
//                 if (error.response && error.response.status === 401) {
//                     navigate('/login');
//                 }
//             }
//         }
//     };


  const filteredEntries = currentEntries.filter((event) =>
    event.event_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection
        title="All Events"
        breadcrumb={['Events', 'All Events']}
      />

      <div className="bg-white p-6 rounded-lg shadow mb-4">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div
            className="text-gray-800 font-poppins"
            style={{
              fontSize: '18.5px',
              fontWeight: 600,
              lineHeight: '26.25px',
            }}
          >
            All Events
          </div>
          <button
            className="px-4 py-2 bg-customblue text-white rounded text-sm hover:bg-[rgb(82,89,199)]"
            onClick={() => navigate('/dashboard/events/add')}
          >
            + Add New
          </button>
        </div>

        {/* Flex container for "Show Entries" dropdown and search bar */}
        <div className="flex items-center justify-between mb-4 pb-2">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span
              className="text-sm text-gray-600"
              style={{
                color: 'rgb(130, 130, 130)',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '14px',
              }}
            >
              Search:
            </span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr className="bg-white">
                                {['Name', 'Date', 'Time', 'Location', 'Event Details',  'Actions'].map((heading) => (
                                    <th key={heading} className="text-left border-b" style={{ color: 'rgb(49, 42, 42)', fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: 600, lineHeight: '21px', padding: '14px 13px', borderBottomColor: 'rgb(230, 230, 230)' }}>
                                        {heading}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEntries.length > 0 ? (
                                filteredEntries.map((event) => (
                                    <tr key={event.event_id} className="hover:bg-white hover:shadow-md border-b" style={{ verticalAlign: 'top' }}>
                                        <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                                            {event.event_name}
                                        </td>
                                        <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                                            {event.event_date}
                                        </td>
                                        <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                                            {event.event_time}
                                        </td>
                                        <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                                            {event.event_location}
                                        </td>
                                        <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px',}}>
                                            {event.event_details}
                                        </td>
                                        {/* <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                                            {event.event_organizer}
                                        </td> */}
                                        <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px', }}>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditClick(event.event_id)}
                          className="p-2 rounded bg-blue-500 text-white hover:bg-blue-700"
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(event.event_id)}
                          className="p-2 rounded bg-red-500 text-white hover:bg-red-700"
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7} className="text-center py-4">No events found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-customblue text-white rounded text-sm hover:bg-[rgb(82,89,199)]"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-customblue text-white rounded text-sm hover:bg-[rgb(82,89,199)]"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllEvents;
